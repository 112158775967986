import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
// import axios from "axios"
import useJwt from "@src/auth/jwt/useJwt"
import * as Icon from "react-feather"
import MatIcon from "@mui/material/Icon"
import { lazy } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { getBookmarks } from "@store/navbar"
import { library } from "@fortawesome/fontawesome-svg-core"

const axios = useJwt.ssoAxiosInterceptor

function capitalize(str) {
  if (str.length === 0) return str
  return str[0].toUpperCase() + str.substr(1)
}

function titleCase(str) {
  return str.split(" ").map(capitalize).join(" ")
}

export const renderIcon = (
  iconStr,
  classz = "me-1",
  size = null,
  stylex = {}
) => {
  let styleFix = { ...stylex }
  if (size && !styleFix?.fontSize) {
    let newSize = parseInt(size ?? 24) + 1
    styleFix["fontSize"] = `${newSize ?? 24}px`
  }
  let iconStrType = "feather"
  let iconStrFix = iconStr
  let isSolid = false
  if (iconStr?.includes(":")) {
    iconStrType = iconStr.split(":")[0]
    isSolid = iconStr.split(":")?.[2]
    if (
      iconStrType.includes("material") ||
      iconStrType.includes("fontAwesome") ||
      iconStrType.includes("feather")
    ) {
      iconStrFix = iconStr.split(":")[1]
    } else {
      iconStrFix = titleCase(iconStr.split(":")[1].replace("-", " ")).replace(
        " ",
        ""
      )
    }
  }
  if (iconStrType.includes("material")) {
    // const IconMap =
    //   typeof Icon[iconStrFix] === "object" ? Icon[iconStrFix] : Icon["Circle"]
    return (
      <MatIcon
        style={styleFix}
        color={styleFix?.color}
        baseClassName={`${
          isSolid ? "material-icons" : "material-icons-outlined"
        } ${classz} align-middle`}
      >
        {iconStrFix}
      </MatIcon>
    )
  } else if (
    iconStrType.includes("fontAwesome") &&
    library.definitions["fas"][iconStrFix]
  ) {
    return (
      <FontAwesomeIcon
        style={styleFix}
        className={`align-middle ${classz}`}
        icon={iconStrFix}
      />
    )
  } else {
    const IconMap =
      typeof Icon[iconStrFix] === "object" ? Icon[iconStrFix] : Icon["Circle"]
    return (
      <IconMap
        size={size ?? 18}
        className={`align-middle ${classz}`}
        style={styleFix}
      />
    )
  }
}

export const generateRouter = (datas) => {
  ////GENERATE ROUTER
  // console.log("generateRouter")
  let genRouter = []
  for (let i = 0; i < datas.length; i++) {
    // console.log(datas[i])
    if (
      datas[i].routePath !== null &&
      !datas[i].routePath.includes("/dyn_pages/")
    ) {
      //////
      const NewComp = lazy(() => import(`@src/views${datas[i].routePath}`))
      const newRoute = {
        // exact: true,
        path: datas[i].routePath,
        element: <NewComp />,
        meta: {
          action: "read",
          resource: datas[i].permission_subject
        }
      }
      genRouter.push(newRoute)

      //////
      // const newRouteDetLink = {
      //   path: `${datas[i].routePath}/view`,
      //   element: <Navigate to={`${datas[i].routePath}/view/1`} />
      // }
      // genRouter.push(newRouteDetLink)

      //////
      const NewCompDet = lazy(() =>
        import(`@src/views${datas[i].routePath}/detail`)
      )
      const newRouteDet1 = {
        path: `${datas[i].routePath}/:pageType`,
        element: <NewCompDet />,
        meta: {
          action: "read",
          resource: datas[i].permission_subject
        }
      }
      const newRouteDet2 = {
        path: `${datas[i].routePath}/:pageType/:id`,
        element: <NewCompDet />,
        meta: {
          action: "read",
          resource: datas[i].permission_subject
        }
      }
      genRouter.push(newRouteDet1)
      genRouter.push(newRouteDet2)
    }
  }
  // console.log(genRouter)
  return genRouter
}

const genMenuData = (menuNested) => {
  const IconElement = renderIcon(menuNested.icon)
  if (
    menuNested?.children?.length > 0 &&
    !menuNested.is_separator &&
    menuNested.is_header === false
  ) {
    menuNested.is_header = true
  }

  // console.log(menuNested.title, menuNested.is_header, menuNested.children)
  // if (!menuNested?.is_hidden || menuNested?.is_hidden !== true) {
  if (!menuNested.is_separator && menuNested.is_header) {
    return {
      id: `menu_${menuNested.id}`,
      parent_id: `menu_${menuNested.parent_id}`,
      title: menuNested.title,
      icon: IconElement,
      badge: "light-warning",
      resource: menuNested.permission_subject,
      action: "read",
      children: menuNested.children.map((el) => {
        // if (!el?.is_hidden || el?.is_hidden !== true) {
        return {
          id: `menu_${el.id}`,
          parent_id: `menu_${el.parent_id}`,
          title: el.title,
          icon: renderIcon(el.icon),
          ...(el.children.length === 0
            ? {
                navLink: el.routePath
              }
            : {}),
          // navLink: el.routePath,
          resource: el.permission_subject,
          action: "read",
          ...(el.children.length > 0
            ? {
                children: el.children.map((el3) => {
                  // if (!el3?.is_hidden || el3?.is_hidden !== true) {
                  return {
                    id: `menu_${el3.id}`,
                    parent_id: `menu_${el3.parent_id}`,
                    title: el3.title,
                    icon: renderIcon(el3.icon),
                    navLink: el3.routePath,
                    resource: el3.permission_subject,
                    action: "read"
                  }
                  // }
                })
              }
            : {})
        }
        // }
      })
    }
  } else {
    return {
      id: `menu_${menuNested.id}`,
      parent_id: `menu_${menuNested.parent_id}`,
      title: menuNested.title,
      icon: IconElement,
      badge: "light-warning",
      navLink: menuNested.routePath,
      resource: menuNested.permission_subject,
      action: "read"
    }
  }
  // }
}

export const generateMenu = (datas) => {
  //// GENERATE MENU
  // console.log("generateMenu")
  // let datas = JSON.parse(JSON.stringify(data))
  // console.log(datas)
  // const sorter1 = (a, b) =>
  //   `${a.id_app}${a.index}` > `${b.id_app}${b.index}` ? 1 : -1
  // let datas2 = JSON.parse(JSON.stringify(data))
  // datas2.sort(sorter1)
  // console.log(datas2)

  let genMenu = []
  let nest = (items, id = null, link = "parent_id") =>
    items
      .filter(
        (item) =>
          item[link] === id && (!item?.is_hidden || item?.is_hidden !== true)
      )
      .map((item) => ({ ...item, children: nest(items, item.id) }))
  let menuNested = nest(datas)
  // let menuNested = datas
  for (let i = 0; i < menuNested.length; i++) {
    // const IconElement = renderIcon(menuNested[i].icon)
    // console.log(
    //   menuNested[i].title,
    //   menuNested[i].is_header,
    //   menuNested[i].children
    // )
    // console.log(genMenuData(menuNested[i]))
    // const hasChild = datas.some((el) => el.parent_id === menuNested[i].id)
    // console.log(hasChild)
    if (!menuNested[i]?.is_hidden || menuNested[i]?.is_hidden !== true) {
      if (menuNested[i].is_separator && !menuNested[i].is_header) {
        genMenu.push({
          header: menuNested[i].title,
          action: "read",
          resource: menuNested[i].permission_subject,
          title: menuNested[i].title,
          id: `menu_${menuNested[i].id}`,
          parent_id: `menu_${menuNested[i].parent_id}`
        })
        if (menuNested[i].children.length) {
          menuNested[i].children.map((el) => {
            // console.log(el)
            if (!el?.is_hidden || el?.is_hidden !== true) {
              genMenu.push(genMenuData(el))
            }
          })
        }
      } else {
        genMenu.push(genMenuData(menuNested[i]))
      }
    }

    // if (menuNested[i].is_separator) {
    //   genMenu.push({
    //     header: menuNested[i].title,
    //     action: "read",
    //     resource: menuNested[i].permission_subject
    //   })
    // } else if (menuNested[i].is_header || menuNested[i].children.length > 0) {
    //   genMenu.push({
    //     id: `menu_${menuNested[i].id}`,
    //     title: menuNested[i].title,
    //     icon: IconElement,
    //     badge: "light-warning",
    //     resource: menuNested[i].permission_subject,
    //     action: "read",
    //     children: menuNested[i].children.map((el) => ({
    //       id: `menu_${el.id}`,
    //       title: el.title,
    //       icon: renderIcon(el.icon),
    //       ...(el.children.length === 0
    //         ? {
    //             navLink: el.routePath
    //           }
    //         : {}),
    //       // navLink: el.routePath,
    //       resource: el.permission_subject,
    //       action: "read",
    //       ...(el.children.length > 0
    //         ? {
    //             children: el.children.map((el3) => ({
    //               id: `menu_${el3.id}`,
    //               title: el3.title,
    //               icon: renderIcon(el3.icon),
    //               navLink: el3.routePath,
    //               resource: el3.permission_subject,
    //               action: "read"
    //             }))
    //           }
    //         : {})
    //     }))
    //   })
    // } else {
    //   genMenu.push({
    //     id: `menu_${menuNested[i].id}`,
    //     title: menuNested[i].title,
    //     icon: IconElement,
    //     badge: "light-warning",
    //     navLink: menuNested[i].routePath,
    //     resource: menuNested[i].permission_subject,
    //     action: "read"
    //   })
    // }
  }
  // console.log(genMenu)
  return genMenu
}

export const getAppMenu = createAsyncThunk(
  "getAppMenu",
  async (_, { dispatch, rejectWithValue }) => {
    const userData = JSON.parse(localStorage.getItem("userData"))

    if (userData && userData.menu?.length) {
      const userMenu = userData.menu
      console.log("GET INIT ROUTE & MENU SUCCESS")
      await dispatch(getBookmarks())
      return {
        data: userMenu,
        genRouter: generateRouter(userMenu),
        genMenu: generateMenu(userMenu)
      }
    } else {
      try {
        const response = await axios.get(`/api/my_info/menu`)
        console.log("GET INIT ROUTE & MENU SUCCESS")
        await dispatch(getBookmarks())
        return {
          data: response.data.data,
          genRouter: generateRouter(response.data.data),
          genMenu: generateMenu(response.data.data)
        }
      } catch (error) {
        console.error(error)
        return rejectWithValue(error.response.data)
      }
    }
  }
)

// export const getAppMenu = createAsyncThunk(
//   "getAppMenu",
//   async ({ rejectWithValue, getState }) => {
//     // console.log("GET INIT ROUTE & MENU START...")
//     const userData = JSON.parse(localStorage.getItem("userData"))

//     if (userData && userData.menu) {
//       const userMenu = userData.menu
//       console.log("GET INIT ROUTE & MENU SUCCESS")
//       return {
//         data: userMenu,
//         genRouter: generateRouter(userMenu),
//         genMenu: generateMenu(userMenu)
//       }
//     } else {
//       try {
//         // const userData = await JSON.parse(localStorage.getItem("userData"))
//         // const response = userData.menu
//         const response = await axios.get(`/api/my_info/menu`)
//         // console.log("GET INIT ROUTE & MENU SUCCESS")
//         return {
//           data: response.data.data,
//           genRouter: generateRouter(response.data.data),
//           genMenu:
//             response.data.data.length > 0
//               ? generateMenu(response.data.data)
//               : []
//         }
//       } catch (error) {
//         console.error(error)
//         // console.error("GET INIT ROUTE & MENU FAILED!!!")
//         return rejectWithValue(error.response.data)
//       }
//     }
//   }
// )

function findLastPageNumber(arrayOfObjects, keyword) {
  let filteredPages = arrayOfObjects.filter((obj) =>
    obj.title.includes(keyword)
  )
  if (filteredPages?.length > 0) {
    filteredPages.sort((a, b) => {
      const getPageNumber = (str) => parseInt(str.match(/\d+/)?.[0] ?? "0")
      return getPageNumber(b.title) - getPageNumber(a.title)
    })

    return parseInt(filteredPages?.[0].title.match(/\d+/)?.[0] ?? "0") + 1
  } else {
    return 1
  }
}

const updateStorage = (newMenuData) => {
  let userDataStorage = localStorage.getItem("userData")
  let userDataObject = JSON.parse(userDataStorage)
  // console.log(userDataObject["menu"])
  // console.log(newMenuData)
  userDataObject["menu"] = newMenuData
  let userDataStorage_new = JSON.stringify(userDataObject)
  // console.log(userDataStorage_new)

  setTimeout(() => {
    localStorage.setItem("userData", userDataStorage_new)
  }, 200)
}

////DEV MODE
export const createNewMenu = createAsyncThunk(
  "createNewMenu",
  async ({ item, isSubMenu }, { getState, rejectWithValue }) => {
    let storeName = ""

    try {
      let result = {}
      let targetIndex = -1
      const dataMyMenu = [...getState()["my_menu"].dataMyMenu]
      // console.log(dataMyMenu)
      let menuCloned = null
      const dataMenu = [...getState()["my_menu"].dataMenu]
      if (item.id !== "0000") {
        const before_id = parseInt(item.id.replace("menu_", ""))
        targetIndex = dataMyMenu.findIndex((obj) => obj.id === before_id)
        menuCloned = dataMyMenu.find((obj) => obj.id === before_id)
        // console.log(targetIndex)
      }

      if (targetIndex === -1 && item.id === "0000") {
        // console.log(dataMyMenu)
        const dataMenuFilter = [
          ...dataMyMenu.filter(
            (obj) =>
              obj.parent_id === null &&
              obj?.id !== 1 &&
              !obj.routePath?.includes("/dyn_pages/_admin_") &&
              !obj.routePath?.includes("/apps/menu")
          )
        ]

        let myMenuLength = [
          ...dataMyMenu.filter(
            (obj) =>
              obj?.id !== 1 &&
              !obj.routePath?.includes("/dyn_pages/_admin_") &&
              !obj.routePath?.includes("/apps/menu")
          )
        ].length
        // targetIndex = myMenuLength === 0 ? 0 : myMenuLength - 1
        targetIndex = myMenuLength
        if (dataMenuFilter?.length > 0) {
          menuCloned = dataMenuFilter?.[dataMenuFilter?.length - 1]
        } else {
          menuCloned = {
            parent_id: null,
            index: 0,
            permission_subject: "developer_subject"
          }
        }

        // console.log(dataMyMenu, dataMenuFilter, menuCloned, targetIndex)
      }

      // console.log(item, dataMyMenu, dataMenu)
      // console.log(menuCloned, targetIndex, dataMyMenu)
      let pageTitle = !isSubMenu ? "Page 1" : "Sub Page 1"
      // if (targetIndex !== -1) {
      // let maxID = dataMyMenu.reduce(
      //   (max, obj) => (obj.id > max ? obj.id : max),
      //   -Infinity
      // )
      // console.log(maxID)
      const currentDateTime = new Date().toISOString().replace(/[-:T.]/g, "")
      const uniqueID = currentDateTime + Math.random().toString(36).substr(2)
      const lastPage = findLastPageNumber(
        [...dataMyMenu],
        !isSubMenu ? "Page " : "Sub Page "
      )
      // console.log(dataMyMenu)
      pageTitle = !isSubMenu ? `Page ${lastPage}` : `Sub Page ${lastPage}`
      storeName = `page_${uniqueID}`
      const newRouteId = `/dyn_pages/${uniqueID}/${storeName}`

      // /////POST TO API
      const payloadToApi = {
        title: pageTitle,
        routePath: newRouteId,
        parent_id: menuCloned?.parent_id,
        index:
          item.id === "0000"
            ? (menuCloned?.index ?? 0) + 1
            : menuCloned?.index === 0
            ? 0
            : menuCloned?.index - 1,
        icon: !isSubMenu ? "material:circle" : "feather:Circle",
        pageAttr: {
          pageType: "crud",
          pageTitle: pageTitle,
          crudOpt: {}
        }
      }
      const responseApi = await axios({
        method: "post",
        url: `/api/menu`,
        data: payloadToApi
      })

      // console.log(responseApi?.data)

      const newObject = {
        action: "read",
        description: "",
        fetch: 1,
        icon: payloadToApi.icon,
        id: responseApi?.data?.data?.id,
        index: payloadToApi.index,
        isBookmarked: false,
        is_header: false,
        is_separator: false,
        parent_id: payloadToApi.parent_id,
        permission_subject: menuCloned?.permission_subject,
        routePath: payloadToApi.routePath,
        title: payloadToApi.title
      }

      if (item.id !== "0000") {
        dataMyMenu.splice(targetIndex, 0, newObject)
      } else {
        const appSettingMenuIndex = dataMyMenu?.findIndex(
          (item) => item?.title === "App Setting" && item.id === 1
        )

        // console.log(targetIndex + 1, appSettingMenuIndex, dataMyMenu, newObject)
        if (appSettingMenuIndex !== -1) {
          dataMyMenu.splice(targetIndex + 1, 0, newObject)
        } else {
          dataMyMenu.push(newObject)
        }
      }

      // console.log(dataMyMenu)
      updateStorage(dataMyMenu)

      result = {
        data: dataMyMenu,
        genRouter: generateRouter(dataMyMenu),
        genMenu: generateMenu(dataMyMenu),
        storeName,
        pageTitle,
        routePath: payloadToApi.routePath
      }
      return result
      // } else {

      // }
    } catch (error) {
      console.error(error)
      return rejectWithValue({
        storeName: storeName,
        error: error.response.data
      })
    }
  }
)

export const createNewSubMenu = createAsyncThunk(
  "createNewSubMenu",
  async (item, { getState, rejectWithValue }) => {
    let storeName = ""
    try {
      // console.log(item)
      let result = {}
      const before_id = parseInt(item.id.replace("menu_", ""))
      let dataMyMenu = JSON.parse(
        JSON.stringify(getState()["my_menu"].dataMyMenu)
      )
      // console.log(dataMyMenu)
      let targetIndex = dataMyMenu.findIndex((obj) => obj.id === before_id)
      // console.log(targetIndex)

      let pageTitle = "Sub Page 1"
      if (targetIndex !== -1) {
        const currentDateTime = new Date().toISOString().replace(/[-:T.]/g, "")
        const uniqueID = currentDateTime + Math.random().toString(36).substr(2)
        const lastPage = findLastPageNumber([...dataMyMenu], "Sub Page ")
        // console.log(dataMyMenu)
        pageTitle = `Sub Page ${lastPage}`
        storeName = `page_${uniqueID}`
        const newRouteId = `/dyn_pages/${uniqueID}/${storeName}`

        // GET MAX SIBLING ID
        let siblings = dataMyMenu.filter((obj) => obj.parent_id === before_id)
        let childIndex = 1
        if (siblings) {
          childIndex = siblings.reduce(
            (max, obj) => (obj.index ?? 1 > max ? obj.index : max),
            1
          )
        }
        // console.log(childIndex)
        // /////POST TO API
        const payloadToApi = {
          title: pageTitle,
          routePath: newRouteId,
          parent_id: dataMyMenu[targetIndex].id,
          index: childIndex,
          icon: "feather:Circle",
          pageAttr: {
            pageType: "crud",
            pageTitle: pageTitle,
            crudOpt: {}
          }
        }

        const responseApi = await axios({
          method: "post",
          url: `/api/menu`,
          data: payloadToApi
        })

        // console.log(responseApi?.data)

        dataMyMenu[targetIndex]["is_header"] = true
        const newObject = {
          action: "read",
          description: "",
          fetch: 1,
          icon: payloadToApi.icon,
          id: responseApi?.data?.data?.id,
          // id: `menu_3000`,
          index: payloadToApi.index,
          isBookmarked: false,
          is_header: false,
          is_separator: false,
          parent_id: dataMyMenu[targetIndex].id,
          permission_subject: dataMyMenu[targetIndex].permission_subject,
          routePath: payloadToApi.routePath,
          title: payloadToApi.title
        }
        dataMyMenu.splice(targetIndex, 0, newObject)
        // console.log(dataMyMenu)
        updateStorage(dataMyMenu)

        result = {
          data: dataMyMenu,
          genRouter: generateRouter(dataMyMenu),
          genMenu: generateMenu(dataMyMenu),
          storeName,
          pageTitle,
          routePath: payloadToApi.routePath
        }
        return result
      }
    } catch (error) {
      console.error(error)
      return rejectWithValue({
        storeName: storeName,
        error: error.response.data
      })
    }
  }
)

export const updateMenuToNeedFetch = createAsyncThunk(
  "updateMenuToNeedFetch",
  async (menuId, { getState, rejectWithValue }) => {
    try {
      let result = {}
      let dataMyMenu = JSON.parse(
        JSON.stringify(getState()["my_menu"].dataMyMenu)
      )
      let targetIndex = dataMyMenu.findIndex((obj) => obj.id === menuId)
      if (targetIndex !== -1) {
        // console.log(dataMyMenu[targetIndex])
        dataMyMenu[targetIndex]["fetch"] = 1
        updateStorage(dataMyMenu)
      }
      result = {
        data: dataMyMenu
      }
      return result
    } catch (error) {
      console.error(error)
      return rejectWithValue({
        storeName: storeName,
        error: error.response.data
      })
    }
  }
)

export const updateMenu = createAsyncThunk("updateMenu", (newOrder) => {
  const result = {
    genMenu: newOrder
  }

  return result
})

export const updateMenuSub = createAsyncThunk(
  "updateMenuSub",
  async (newOrder) => {
    const result = {
      genMenu: newOrder
    }

    return result
  }
)

function rearrangeIndexes(dataMyMenu, updatedIndex, newIndex) {
  const updatedItem = dataMyMenu.find((item) => item.index === updatedIndex)
  const newItem = dataMyMenu.find((item) => item.index === newIndex)

  if (updatedItem && newItem) {
    // Hapus item dari array sementara
    dataMyMenu = dataMyMenu.filter((item) => item !== updatedItem)

    // Sisipkan item pada posisi yang sesuai dengan newIndex
    const insertionIndex =
      dataMyMenu.indexOf(newItem) + (newIndex > updatedIndex ? 1 : 0)
    dataMyMenu.splice(insertionIndex, 0, updatedItem)

    // Reset ulang index
    dataMyMenu.forEach((item, index) => {
      item.index = index
    })

    return dataMyMenu
  }

  return dataMyMenu
}

export const updateMenuSortToApi = createAsyncThunk(
  "updateMenuToApi",
  async (newData, { getState, rejectWithValue }) => {
    let storeName = ""
    try {
      let dataMyMenu = JSON.parse(
        JSON.stringify(getState()["my_menu"].dataMyMenu)
      )
      let targetIndex = dataMyMenu.findIndex((obj) => obj.id === newData.id)
      if (targetIndex !== -1) {
        const updatedIndex = dataMyMenu[targetIndex]["index"]
        const newIndex = newData.index
        // console.log(updatedIndex, newIndex)

        const rearrangedData = rearrangeIndexes(
          dataMyMenu,
          updatedIndex,
          newIndex
        )

        // console.log(rearrangedData)
        updateStorage(rearrangedData)
      }

      let dataPar = {
        index: newData.index
      }
      if (newData.parent_id) {
        dataPar["parent_id"] = newData.parent_id
      }
      const responseApi = await axios({
        method: "put",
        url: `/api/menu/${newData.id}`,
        data: dataPar
      })

      const result = responseApi?.data?.data
      return result
    } catch (error) {
      console.error(error)
      return rejectWithValue({
        storeName: storeName,
        error: error.response.data ?? error
      })
    }
  }
)

export const renameMenu = createAsyncThunk(
  "renameMenu",
  async (newData, { getState, rejectWithValue }) => {
    let storeName = ""
    try {
      const id = newData.id
      const dataMyMenu = [...getState()["my_menu"].dataMyMenu]
      let targetIndex = dataMyMenu.findIndex((obj) => obj.id === id)
      // console.log(id)
      if (targetIndex !== -1) {
        dataMyMenu[targetIndex] = {
          ...dataMyMenu[targetIndex],
          ...newData
        }

        const responseApi = await axios({
          method: "put",
          url: `/api/menu/${id}`,
          data: {
            title: newData.title,
            routePath: newData.routePath,
            icon: newData.icon
          }
        })
        updateStorage(dataMyMenu)
        // console.log(responseApi)
        const result = {
          data: dataMyMenu,
          genRouter: generateRouter(dataMyMenu),
          genMenu: generateMenu(dataMyMenu)
        }

        return result
      } else {
        return {}
      }
    } catch (error) {
      return rejectWithValue({
        storeName: storeName,
        error: error.response.data
      })
    }
  }
)

export const deleteMenu = createAsyncThunk(
  "deleteMenu",
  async (newData, { getState, rejectWithValue }) => {
    try {
      // console.log(newData, getState()["my_menu"].dataMyMenu)
      const deleteId = parseInt(newData.id.replace("menu_", ""))
      // console.log(deleteId)
      const dataMyMenu = getState()
        ["my_menu"].dataMyMenu.filter((obj) => obj.id !== deleteId)
        .map((a) => {
          return { ...a }
        })
      ////IF DELETED IS LAST ITEM, THEN UPDATE PARENT HEADER STATUS
      const { parent_id } = newData

      if (parent_id) {
        const parsedParentId = parseInt(parent_id.replace("menu_", ""), 10)

        if (parsedParentId) {
          const isLastItem = dataMyMenu.some(
            (item) => item.parent_id === parsedParentId
          )

          if (!isLastItem) {
            const parentIndex = dataMyMenu.findIndex(
              (obj) => obj.id === parsedParentId
            )

            if (parentIndex !== -1 && dataMyMenu[parentIndex].is_header) {
              dataMyMenu[parentIndex].is_header = false
            }
          }
        }
      }

      const responseApi = await axios({
        method: "delete",
        url: `/api/menu/${deleteId}`
      })

      // console.log(dataMyMenu)
      updateStorage(dataMyMenu)

      const result = {
        data: dataMyMenu,
        genRouter: generateRouter(dataMyMenu),
        genMenu: generateMenu(dataMyMenu)
      }
      return result
    } catch (error) {
      console.error(error)
      return rejectWithValue({
        error: error.response.data
      })
    }
  }
)

const fetchStatus = "idle" | "loading" | "succeeded" | "failed"

export const StoreMyMenuSlice = createSlice({
  name: "my_menu",
  initialState: {
    // ALL
    status: fetchStatus,
    isLoading: false,
    error: "" | null,
    dataMyMenu: [],
    dataMenu: [],
    dataRouter: [],
    total: 1,
    isLoadingPostPut: false
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAppMenu.pending, (state) => {
        state.status = "loading"
        state.isLoading = true
      })
      .addCase(getAppMenu.fulfilled, (state, action) => {
        state.status = "succeeded"
        state.isLoading = false
        state.dataMyMenu = action.payload.data
        state.dataRouter = action.payload.genRouter
        state.dataMenu = action.payload.genMenu
      })
      .addCase(getAppMenu.rejected, (state, action) => {
        state.status = "failed"
        state.isLoading = false
        state.error = action.error.message
      })

      .addCase(createNewMenu.pending, (state) => {
        state.isLoadingPostPut = true
      })
      .addCase(createNewMenu.fulfilled, (state, action) => {
        // console.log(action.payload)
        if (action.payload) {
          state.dataMyMenu = action.payload.data
          state.dataRouter = action.payload.genRouter
          state.dataMenu = action.payload.genMenu
        }
        state.isLoadingPostPut = false
      })
      .addCase(createNewMenu.rejected, (state) => {
        state.isLoadingPostPut = false
      })

      .addCase(createNewSubMenu.pending, (state) => {
        state.isLoadingPostPut = true
      })
      .addCase(createNewSubMenu.fulfilled, (state, action) => {
        // console.log(action.payload)
        if (action.payload) {
          state.dataMyMenu = action.payload.data
          state.dataRouter = action.payload.genRouter
          state.dataMenu = action.payload.genMenu
        }
        state.isLoadingPostPut = false
      })
      .addCase(createNewSubMenu.rejected, (state) => {
        state.isLoadingPostPut = false
      })

      .addCase(updateMenuToNeedFetch.fulfilled, (state, action) => {
        // console.log(action.payload)
        if (action.payload) {
          state.dataMyMenu = action.payload.data
        }
        state.isLoadingPostPut = false
      })

      .addCase(updateMenu.pending, (state) => {
        state.isLoadingPostPut = true
      })
      .addCase(updateMenu.fulfilled, (state, action) => {
        // console.log(action.payload)
        // let dataMenuCopy = [...state.dataMenu]
        if (action.payload) {
          state.dataMenu = action.payload.genMenu.filter(
            (obj) => obj.title !== "Home"
          )
        }
        state.isLoadingPostPut = false
      })
      .addCase(updateMenu.rejected, (state) => {
        state.isLoadingPostPut = false
      })

      .addCase(updateMenuSub.fulfilled, (state, action) => {
        console.log(action.payload)
        if (action.payload) {
          const updatedArray = state.dataMenu.map((item) => {
            if (item.id === action.payload.genMenu?.id) {
              return { ...item, ...action.payload.genMenu }
            }
            return item
          })
          state.dataMenu = updatedArray
        }
        state.isLoadingPostPut = false
      })

      .addCase(renameMenu.pending, (state) => {
        state.isLoadingPostPut = true
      })
      .addCase(renameMenu.fulfilled, (state, action) => {
        // console.log(action.payload)
        if (action.payload) {
          state.dataMyMenu = action.payload.data
          state.dataRouter = action.payload.genRouter
          state.dataMenu = action.payload.genMenu
        }
        state.isLoadingPostPut = false
      })
      .addCase(renameMenu.rejected, (state) => {
        state.isLoadingPostPut = false
      })

      .addCase(deleteMenu.pending, (state) => {
        state.isLoadingPostPut = true
      })
      .addCase(deleteMenu.fulfilled, (state, action) => {
        // console.log(action.payload)
        if (action.payload) {
          state.dataMyMenu = action.payload.data
          state.dataRouter = action.payload.genRouter
          state.dataMenu = action.payload.genMenu
        }
        state.isLoadingPostPut = false
      })
      .addCase(deleteMenu.rejected, (state) => {
        state.isLoadingPostPut = false
      })
  }
})

export default StoreMyMenuSlice.reducer
