// ** Vertical Menu Components
import VerticalNavMenuLink from "./VerticalNavMenuLink"
import VerticalNavMenuGroup from "./VerticalNavMenuGroup"
import VerticalNavMenuSectionHeader from "./VerticalNavMenuSectionHeader"

// ** Utils
import {
  canViewMenuItem,
  canViewMenuGroup,
  resolveVerticalNavMenuItemComponent as resolveNavItemComponent
} from "@layouts/utils"

const VerticalMenuNavItems = (props) => {
  // console.log(props?.items)
  // ** Components Object
  const Components = {
    VerticalNavMenuLink,
    VerticalNavMenuGroup,
    VerticalNavMenuSectionHeader
  }

  // ** Render Nav Menu Items
  const RenderNavItems = props?.items?.map((item, index) => {
    // if (props.items.length > 1) {
    const TagName = Components[resolveNavItemComponent(item)]

    if (item.children) {
      return (
        canViewMenuGroup(item) && (
          <TagName item={item} index={index} key={item.id} {...props} />
        )
      )
    }
    // console.log(item)

    return (
      canViewMenuItem(item) && (
        <TagName key={item.id || item.header} item={item} {...props} />
      )
    )
    // }
  })

  const keyExists = RenderNavItems ?? [].find((item) => item.key === "homepage")
  if (keyExists) {
    // set blank menu for dev mode on end menu
    const blankItem = {
      action: "read",
      parent_id: "menu_null",
      id: "0000",
      navLink: "/0000",
      resource: "0000",
      disabled: true,
      title: "-----"
    }
    const TagNameBlank = Components[resolveNavItemComponent(blankItem)]
    const TagNameBlankComp = (
      <TagNameBlank
        key={blankItem.id || blankItem.header}
        item={blankItem}
        {...props}
      />
    )

    const appSettingMenuIndex = props?.items?.findIndex(
      (item) => item?.header === "App Setting"
    )
    // console.log(appSettingMenuIndex)
    if (appSettingMenuIndex !== -1) {
      RenderNavItems.splice(appSettingMenuIndex, 0, TagNameBlankComp)
    } else {
      // if (canViewMenuItem(blankItem)) {
      RenderNavItems.push(TagNameBlankComp)
      // }
    }

    // console.log(RenderNavItems)
  }

  return RenderNavItems
}

export default VerticalMenuNavItems
