import { AbilityContext } from "@src/utility/context/Can"
import {
  Fragment,
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useState,
  useRef
} from "react"
import UILoader from "@components/ui-loader"
import { Loader } from "@src/shared/CommonComponents"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector, Provider } from "react-redux"
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams
} from "react-router-dom"
import { getDataList, setMapOptions } from "../store"

import { ThemeColors } from "@src/utility/context/ThemeColors"
import DynamicRow from "@src/utility/generateForm/dynamicRow"
import { renderIcon } from "@src/views/apps/menu/store/my_menu"
import "@styles/react/libs/react-select/_react-select.scss"
import "@styles/react/libs/tables/react-dataTable-component.scss"
import {
  Button,
  Card,
  Col,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from "reactstrap"
import L from "leaflet"
import "leaflet/dist/leaflet.css"
// import Prism from "prismjs"
import {
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  useMap,
  Polygon,
  useMapEvents
} from "react-leaflet"
import "leaflet-draw/dist/leaflet.draw.css"
import { FullscreenControl } from "react-leaflet-fullscreen"
import "react-leaflet-fullscreen/styles.css"
import "leaflet-geosearch/dist/geosearch.css"

import { GeoSearchControl, OpenStreetMapProvider } from "leaflet-geosearch"
import Table from "../Table"
import Swal from "sweetalert2"
import withReactContent from "sweetalert2-react-content"
import { useSkin } from "@hooks/useSkin"
import { store as storeMain } from "../../../redux/store"
const MySwal = withReactContent(Swal)
const BootstrapCheckbox = forwardRef((props, ref) => (
  <div className="form-check">
    <Input type="checkbox" ref={ref} {...props} />
  </div>
))

const default_marker_path_color = "blue"
const default_marker_path_color_selected = "red"
const default_marker_icon =
  "https://unpkg.com/leaflet@1.6/dist/images/marker-icon.png"
// const MyMapComponent = () => {
//   const map = useMap()

//   useEffect(() => {
//     const handleFullscreenChange = () => {
//       const isFullscreen = map.isFullscreen()
//       console.log("Is fullscreen:", isFullscreen)
//     }

//     map.on("fullscreenchange", handleFullscreenChange)

//     return () => {
//       map.off("fullscreenchange", handleFullscreenChange)
//     }
//   }, [map])

//   return null
// }

const MapPage = ({
  childRef,
  resetGridAndFilters,
  initialData,
  initialPageId,
  btnAddCondition,
  pageAttr,
  storeName,
  filtersCustomAdd,
  handleFiltersChangeToParent,
  resetState,
  customHeaderSet,
  selectedDataTable
}) => {
  const { moduleId, pageId, id, relPageId, relPageType, relId } = useParams()

  const dispatch = useDispatch()
  const store = useSelector((state) => state.dynamicStore)

  const { t } = useTranslation()
  const ability = useContext(AbilityContext)

  const [filteredData, setFilteredData] = useState([])

  const { colors } = useContext(ThemeColors)
  const [sort_dir, setSortDir] = useState("desc")
  const [searchTerm, setSearchTerm] = useState("")
  const [currentPage, setCurrentPage] = useState(store.params?.page ?? 1)
  const [sort, setSort] = useState("id")
  const [rowsPerPage, setRowsPerPage] = useState(store.params?.length ?? 10)
  const [selectedRows, setSelectedRows] = useState([])

  const [filtersCustom, setFiltersCustom] = useState(filtersCustomAdd ?? {})

  const [toggleCleared, setToggleCleared] = useState(false)

  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [loading, setLoading] = useState(true)
  const [loadingModal, setLoadingModal] = useState(true)
  const [loadingPostPut, setLoadingPostPut] = useState(false)
  const [loadingDelete, setLoadingDelete] = useState(false)
  const [loadingText, setLoadingText] = useState("Please Wait")
  const [showModal, setShowModal] = useState(false)
  const [pageType, setPageType] = useState(null)
  const navigate = useNavigate()
  const location = useLocation()
  const diagramRef = useRef(null)
  const idField = pageAttr?.crudOpt?.treeviewIdField ?? "id"
  const parentIdField = pageAttr?.crudOpt?.treeviewParentIdField ?? "parent_id"
  const titleField = pageAttr?.crudOpt?.treeviewTitleField ?? "title" ?? "name"
  const codeField = pageAttr?.crudOpt?.treeviewAddSubIncrementField
  const indexField = pageAttr?.crudOpt?.treeviewIndexField ?? "index" ?? "order"
  const iconField = pageAttr?.crudOpt?.treeviewIconField ?? "icon"
  const [newDataTree, setNewDataTree] = useState({})
  const [selectedDataTree, setSelectedDataTree] = useState(null)
  const { skin } = useSkin()
  const colorsClass = [
    "primary",
    "info",
    "success",
    "warning",
    "secondary",
    "danger"
  ]

  const [isFullscreen, setIsFullscreen] = useState(!!document.fullscreenElement)
  //   const [oldStyleBackgroundColor, setOldStyleBackgroundColor] = useState("")
  //   // const [selectedData, setSelectedData] = useState(null)
  //   const requestFullscreen = () => {
  //     const element = document.getElementById("org_chart_container")
  //     if (element.requestFullscreen) {
  //       element.requestFullscreen()
  //     } else if (element.mozRequestFullScreen) {
  //       element.mozRequestFullScreen()
  //     } else if (element.webkitRequestFullscreen) {
  //       element.webkitRequestFullscreen()
  //     } else if (element.msRequestFullscreen) {
  //       element.msRequestFullscreen()
  //     }

  //     setIsFullscreen(true)
  //   }

  //   const exitFullscreen = () => {
  //     if (
  //       document.fullscreenElement ||
  //       document.mozFullScreenElement ||
  //       document.webkitFullscreenElement ||
  //       document.msFullscreenElement
  //     ) {
  //       if (document.exitFullscreen) {
  //         document.exitFullscreen()
  //       } else if (document.mozCancelFullScreen) {
  //         document.mozCancelFullScreen()
  //       } else if (document.webkitExitFullscreen) {
  //         document.webkitExitFullscreen()
  //       } else if (document.msExitFullscreen) {
  //         document.msExitFullscreen()
  //       }
  //     }

  //     setIsFullscreen(false)
  //   }

  const mapRef = useRef({})
  const field_marker = pageAttr?.crudOpt?.map_marker_fieldName
  // const centerMapAttr = pageAttr?.crudOpt?.map_marker_fieldName
  const mapCenterDefault = [-0.789275, 113.921327]
  //   const [allCoordinates, setAllCoordinates] = useState([])
  const [centerMap, setCenterMap] = useState(mapCenterDefault)
  const [bounds, setBounds] = useState({})
  const [markerPositions, setMarkerPositions] = useState([])
  const [mapDistanceFromMain, setMapDistanceFromMain] = useState({})
  const [markerPositionsElement, setMarkerPositionsElement] = useState()
  const [rowHighlight, setRowHighlight] = useState([])
  const [showModalTooltip, setShowModalTooltip] = useState(false)
  const [showSideTable, setShowSideTable] = useState(
    pageAttr?.crudOpt?.map_display_table_first_load === true
  )

  let mapOpt =
    pageAttr?.crudOpt?.mapOtions ??
    pageAttr?.crudOpt?.mapOptions ??
    store.mapOptions?.[storeName] ??
    {}
  let mapStyle = pageAttr?.crudOpt?.mapStyle ?? {}
  let mapOtherOpt = pageAttr?.crudOpt?.mapOtherOpt ?? {}
  let mapDefaultOpt = {
    zoom: store.pageData[storeName]?.data?.length > 0 ? 13 : 5,
    maxZoom: 20,
    scrollWheelZoom: true,
    center: centerMap,
    ...mapOpt,
    ...(store.mapOptions?.[storeName] ?? {})
    // fullscreenControl: true

    // bounds: bounds
    // boundsOptions: { padding: [1, 1] }
  }

  let mapDefaultStyle = {
    // height: "65vh",
    height: "-webkit-fill-available",
    minHeight: "65vh",
    borderRadius: "5px",
    zIndex: 0
  }

  // Event handler untuk fullscreen change
  const handleFullscreenChange = () => {
    // localStorage.setItem("isFullscreenMap", !!document.fullscreenElement)
    setIsFullscreen(!!document.fullscreenElement)
  }

  //   const handleFullscreenChange = () => {
  //     const map = mapRef.current
  //     console.log(mapRef)
  //     if (map) {

  //       const isFullscreen = map.isFullscreen()
  //       console.log("Map is in fullscreen mode:", isFullscreen)
  //     }
  //   }

  function getPolygonCenter(polygonCoords) {
    // console.log(polygonCoords)
    if (!Array.isArray(polygonCoords) || polygonCoords.length === 0) {
      return [NaN, NaN] // Handle empty or non-array input
    }

    let latSum = 0
    let lngSum = 0
    const numPoints = polygonCoords.length

    // Sum up the coordinates
    for (const point of polygonCoords) {
      if (!Array.isArray(point) || point.length !== 2) {
        return [NaN, NaN] // Handle invalid coordinate format
      }
      const [lat, lng] = point
      if (typeof lat !== "number" || typeof lng !== "number") {
        return [NaN, NaN] // Handle non-numeric coordinates
      }
      latSum += lat
      lngSum += lng
    }

    // Calculate the average
    const avgLat = latSum / numPoints
    const avgLng = lngSum / numPoints
    // console.log([avgLat, avgLng])
    return [avgLat, avgLng]
  }

  const getCenterAverage = (allCoordinatesArray) => {
    const allCoordinates = allCoordinatesArray.flat()
    const validCoordinates = allCoordinates.filter((coord) =>
      Array.isArray(coord)
    )
    const totalLatitude = validCoordinates.reduce((sum, coordinate) => {
      if (Array.isArray(coordinate[0])) {
        return sum + parseFloat(coordinate[0][0])
      }
      return sum + parseFloat(coordinate[0])
    }, 0)

    const totalLongitude = validCoordinates.reduce((sum, coordinate) => {
      if (Array.isArray(coordinate[0])) {
        return sum + parseFloat(coordinate[0][1])
      }
      return sum + parseFloat(coordinate[1])
    }, 0)

    const averageLatitude = totalLatitude / validCoordinates.length
    const averageLongitude = totalLongitude / validCoordinates.length
    console.log(`Titik tengah koordinat:${[averageLatitude, averageLongitude]}`)
    return [averageLatitude, averageLongitude]
  }

  const getBoundsFromMarkers = (markerPositions) => {
    let minLat = Infinity
    let maxLat = -Infinity
    let minLng = Infinity
    let maxLng = -Infinity

    markerPositions.forEach((position) => {
      if (Array.isArray(position[0])) {
        position.forEach((coord) => {
          const [lat, lng] = coord
          minLat = Math.min(minLat, lat)
          maxLat = Math.max(maxLat, lat)
          minLng = Math.min(minLng, lng)
          maxLng = Math.max(maxLng, lng)
        })
      } else {
        const [lat, lng] = position
        minLat = Math.min(minLat, lat)
        maxLat = Math.max(maxLat, lat)
        minLng = Math.min(minLng, lng)
        maxLng = Math.max(maxLng, lng)
      }
    })

    return [
      [minLat, minLng],
      [maxLat, maxLng]
    ]
  }

  const markersRef = useRef({})
  const modalTooltipRef = useRef({})

  const tooltipContentBtnFooter = (mapOpt, markerValueFix, id) => {
    return (
      <Fragment>
        {mapOtherOpt?.calcDistanceFromMainMarker &&
          mapOpt?.mainMarker &&
          id &&
          mapDistanceFromMain?.[id] && (
            <Button className="" color="secondary" outline disabled>
              {t("Distance")} {mapDistanceFromMain?.[id]} {t("Kilometers")}
              {renderIcon("material:6_ft_apart", "ms-50", 15)}
            </Button>
          )}
        {mapOpt?.navigationLink && (
          <Button
            className=""
            color="primary"
            outline
            onClick={() => {
              let urlNavigate = `https://www.google.com/maps/dir/${
                mapOpt?.mainMarker ?? mapOpt?.center ?? mapCenterDefault
              }/${markerValueFix.join(",")}`
              let modeUrl = ""
              if (
                mapOpt?.navigationLinkMode &&
                mapOpt?.navigationLinkMode === 2
              ) {
                modeUrl = "/data=!3m1!4b1!4m2!4m1!3e9?entry=ttu"
                urlNavigate = urlNavigate + modeUrl
              }

              window.open(urlNavigate, "_blank", "noreferrer")
            }}
          >
            {t("Google Navigation")}
            {renderIcon("material:navigation", "ms-50", 15)}
          </Button>
        )}
        <Button
          className=""
          color="primary"
          outline
          onClick={() => {
            window.open(
              `https://www.google.com/maps?layer=c&cbll=${markerValueFix.join(
                ","
              )}`,
              "_blank",
              "noreferrer"
            )
          }}
        >
          {t("Google Street View")}
          {renderIcon("fontAwesome:street-view", "ms-50", 15)}
        </Button>
      </Fragment>
    )
  }

  const tooltipContent = useCallback(
    (el, markerValueFix) => {
      return (
        <Fragment>
          {pageAttr.crudOpt?.data?.dynamicTooltipColumns &&
          (!pageAttr.crudOpt?.dynamicTooltipColumnsAsModal || isFullscreen) ? (
            <Popup className="custom-popup-map">
              {pageAttr.crudOpt?.dynamicTooltipColumnsTitleFieldName && (
                <h4>
                  {el?.[
                    pageAttr.crudOpt?.dynamicTooltipColumnsTitleFieldName
                  ] ?? ""}
                </h4>
              )}
              {pageAttr.crudOpt?.data?.dynamicTooltipColumns?.map(
                (item, index) => (
                  <Fragment>
                    {(item?.columnName || item?.fieldName) && (
                      <p className="mb-0 mt-50 fw-bolder">
                        {t(item?.columnName ?? item?.fieldName)}
                      </p>
                    )}
                    <DynamicRow
                      className="mb-0"
                      row={el}
                      column={item}
                      //   clickToDetailEvent={handleAddEdit}
                    />
                  </Fragment>
                )
              )}

              <Col
                md={12}
                className="mt-1"
                style={{
                  display: "flex",
                  gap: "6px"
                }}
              >
                {tooltipContentBtnFooter(mapOpt, markerValueFix, el?.id)}
              </Col>
            </Popup>
          ) : (
            rowHighlight?.includes(el.id) &&
            showModalTooltip === true && (
              <Modal
                ref={(ref) => (modalTooltipRef.current[el.id] = ref)}
                isOpen={
                  rowHighlight?.includes(el.id) && showModalTooltip === true
                }
                toggle={(e) => closeModal(e)}
                scrollable
                className={`modal-dialog-centered modal-lg basic-modal`}
              >
                <ModalHeader
                  className="bg-transparent"
                  toggle={(e) => closeModal(e)}
                >
                  <span
                    style={{
                      textTransform: "capitalize",
                      fontSize: "1.5rem"
                    }}
                  >
                    {el?.[
                      pageAttr.crudOpt?.dynamicTooltipColumnsTitleFieldName
                    ] ?? t("Detail Location")}
                  </span>
                </ModalHeader>
                <ModalBody>
                  {pageAttr.crudOpt?.data?.dynamicTooltipColumns?.map(
                    (item, index) => (
                      <Fragment>
                        {(item?.columnName || item?.fieldName) && (
                          <p
                            key={`tooltip_columnName_${item?.fieldName}_${index}`}
                            className="mb-0 mt-50 fw-bolder"
                          >
                            {t(item?.columnName ?? item?.fieldName)}
                          </p>
                        )}
                        <DynamicRow
                          className="mb-0"
                          row={el}
                          column={item}
                          //   clickToDetailEvent={handleAddEdit}
                        />
                      </Fragment>
                    )
                  )}
                </ModalBody>
                <ModalFooter>
                  {tooltipContentBtnFooter(mapOpt, markerValueFix, el?.id)}
                </ModalFooter>
              </Modal>
            )
          )}
        </Fragment>
      )
    },
    [showModalTooltip, rowHighlight]
  )

  function isValidCoordinate(coord, returnValue) {
    if (!/^[-+]?\d*\.?\d+,\s*[-+]?\d*\.?\d+$/.test(coord)) {
      return false
    }
    const [lat, lon] = coord.split(",").map(parseFloat)
    if (
      isNaN(lat) ||
      lat < -90 ||
      lat > 90 ||
      isNaN(lon) ||
      lon < -180 ||
      lon > 180
    ) {
      return false
    }

    const lonStr = coord.split(",")[1].trim()
    // console.log(lonStr)
    if (!lonStr || isNaN(lonStr)) {
      return false
    }
    const latStr = coord.split(",")[0].trim()
    if (latStr?.replace("-", "") === lonStr) {
      return false
    }

    if (/\.\d*0{5,}$/.test(lonStr)) {
      return false
    }

    const threshold = 0.001

    if (Math.abs(lat - lon) < threshold) {
      return false
    }

    if (lon < 1) {
      return false
    }

    if (lat === -6.96 && lon === 6.962) {
      return false
    }
    if (lat === 3.7373 && lon === 3.7443) {
      return false
    }

    if (isNaN(lat) || isNaN(lon)) {
      return false
    }

    if (returnValue) {
      return [lat, lon]
    } else {
      return true
    }
  }

  const getMarkerElement = (dataSource) => {
    // console.log("here....")
    let result = []
    if (mapOpt?.mainMarker) {
      result.push(
        <Marker
          position={mapOpt?.mainMarker}
          key={`map_main_marker`}
          id={`map_main_marker`}
          zIndexOffset={200}
          // ref={(ref) => (markersRef.current[0] = ref)}
          // data={el}
          // eventHandlers={{ click: () => handleMarkerClick(el) }}
          icon={
            mapOpt?.mainMarkerIcon
              ? new L.Icon({
                  iconUrl: mapOpt?.mainMarkerIcon,
                  shadowUrl:
                    "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png",
                  iconSize: [50, 60],
                  shadowSize: [50, 60]
                })
              : customMarkerIcon(
                  pageAttr?.crudOpt?.map_marker_icon_path ??
                    el?.[
                      pageAttr?.crudOpt?.map_marker_icon_fieldName ??
                        "marker_icon"
                    ]
                )
          }
        ></Marker>
      )
    }

    if (dataSource && Array.isArray(dataSource)) {
      dataSource.map((el) => {
        let markerValue = ""
        if (!Array.isArray(field_marker)) {
          markerValue = el?.[field_marker]
        }
        let markerValueFix
        if (typeof markerValue === "string" && markerValue.startsWith("[[")) {
          markerValueFix = [JSON.parse(markerValue)]
          result.push(
            <Polygon
              ref={(ref) => (markersRef.current[el.id] = ref)}
              key={`map_marker_${el?.id}`}
              id={`map_marker_${el?.id}`}
              data={el}
              pathOptions={{
                color:
                  el?.[pageAttr?.crudOpt?.map_marker_color_fieldName] ??
                  default_marker_path_color
              }}
              positions={markerValueFix}
              eventHandlers={{ click: () => handleMarkerClick(el) }}
            >
              {tooltipContent(el, getPolygonCenter(markerValueFix[0]))}
            </Polygon>
          )
        } else if (
          Array.isArray(markerValue) &&
          markerValue.every((elem) => Array.isArray(elem))
        ) {
          markerValueFix = [markerValue]
          result.push(
            <Polygon
              key={`map_marker_${el?.id}`}
              id={`map_marker_${el?.id}`}
              ref={(ref) => (markersRef.current[el.id] = ref)}
              data={el}
              pathOptions={{
                color:
                  el?.[pageAttr?.crudOpt?.map_marker_color_fieldName] ??
                  default_marker_path_color
              }}
              positions={markerValueFix}
              eventHandlers={{ click: () => handleMarkerClick(el) }}
            >
              {tooltipContent(el, getPolygonCenter(markerValueFix[0]))}
            </Polygon>
          )
        } else if (
          typeof markerValue === "string" &&
          !markerValue.startsWith("[[")
        ) {
          if (Array.isArray(field_marker)) {
            let new_markerValue = []
            field_marker?.map((fieldd) => {
              let markerVal = el?.[fieldd]
              if (markerVal) {
                new_markerValue.push(markerVal)
              }
            })
            markerValue = new_markerValue.join(",")
          }
          if (markerValue) {
            let validValue = isValidCoordinate(markerValue, true)
            if (validValue) {
              markerValueFix = [validValue]
              // console.log(tooltipContent(el, markerValueFix[0]))
              result.push(
                <Marker
                  position={markerValueFix[0]}
                  key={`map_marker_${el?.id}`}
                  id={`map_marker_${el?.id}`}
                  ref={(ref) => (markersRef.current[el.id] = ref)}
                  data={el}
                  eventHandlers={{
                    click: () => handleMarkerClick(el, true, markerValueFix[0])
                  }}
                  {...(pageAttr?.crudOpt?.map_marker_icon_fieldName ||
                  pageAttr?.crudOpt?.map_marker_icon_path
                    ? {
                        icon: customMarkerIcon(
                          pageAttr?.crudOpt?.map_marker_icon_path ??
                            el?.[
                              pageAttr?.crudOpt?.map_marker_icon_fieldName ??
                                "marker_icon"
                            ]
                        )
                      }
                    : {})}
                >
                  {tooltipContent(el, markerValueFix[0])}
                </Marker>
              )
            }
          }
        }
      })
    }

    return result
  }

  const getMarker = (dataSource) => {
    // console.log(dataSource)

    if (dataSource && Array.isArray(dataSource)) {
      let result = []
      let markerValueFixAll = []
      dataSource?.map((el) => {
        let markerValue = ""
        // console.log(field_marker, !Array.isArray(field_marker))
        if (!Array.isArray(field_marker)) {
          markerValue = el?.[field_marker]
        }
        // console.log(field_marker, markerValue)
        if (markerValue || markerValue === "") {
          let markerValueFix
          if (typeof markerValue === "string" && markerValue.startsWith("[[")) {
            markerValueFix = [JSON.parse(markerValue)]
          } else if (
            Array.isArray(markerValue) &&
            markerValue.every((elem) => Array.isArray(elem))
          ) {
            markerValueFix = [markerValue]
          } else if (
            typeof markerValue === "string" &&
            !markerValue.startsWith("[[")
          ) {
            if (Array.isArray(field_marker)) {
              let new_markerValue = []
              field_marker?.map((fieldd) => {
                let markerVal = el?.[fieldd]
                if (markerVal) {
                  new_markerValue.push(markerVal)
                }
              })
              // !Number.isNaN(markerVal)
              markerValue = new_markerValue.join(",")
              // console.log(markerValue)
            }

            let validValue = isValidCoordinate(markerValue, true)
            if (validValue) {
              markerValueFix = [validValue]
            }

            // console.log(markerValueFix)
          }
          if (markerValueFix) {
            markerValueFixAll.push(markerValueFix[0])
          }
        }
      })

      setMarkerPositionsElement(result)
      setMarkerPositions(markerValueFixAll)

      if (sessionStorage.getItem(`showSideTable_${storeName}`)) {
        setShowSideTable(
          sessionStorage.getItem(`showSideTable_${storeName}`) === "true"
        )
      }
    }
  }

  function LeafletgeoSearch() {
    const map = useMap()
    useEffect(() => {
      const provider = new OpenStreetMapProvider()

      const searchControl = new GeoSearchControl({
        provider,
        style: "bar",
        searchLabel: t("Search Address or Coordinate"),
        //       autoComplete: true, // optional: true|false  - default true
        // autoCompleteDelay: 250, // optional: number      - default 250
        //       popupFormat: ({ query, result }) => result.label, // optional: function    - default returns result label,
        // resultFormat: ({ result }) => result.label, // optional: function    - default returns result label
        // maxMarkers: 1, // optional: number      - default 1
        // retainZoomLevel: false, // optional: true|false  - default false
        // animateZoom: true, // optional: true|false  - default true
        // autoClose: false, // optional: true|false  - default false
        // searchLabel: 'Enter address', // optional: string      - default 'Enter address'
        // keepResult: false, // optional: true|false  - default false
        // updateMap: true, // optional: true|false  - default true
        // showMarker: true, // optional: true|false  - default true
        // showPopup: false, // optional: true|false  - default false
        marker: L.icon({
          // iconSize: [25, 41],
          // iconAnchor: [10, 41],
          // popupAnchor: [2, -40],
          iconUrl: default_marker_icon,
          shadowUrl:
            "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png"
        })
      })

      map.addControl(searchControl)

      return () => map.removeControl(searchControl)
    }, [])

    return null
  }

  const customMarkerIcon = (path) => {
    return path
      ? new L.Icon({
          iconUrl: path, // Ganti dengan path ke gambar ikon Anda
          iconRetinaUrl: path,
          iconSize: [40, 40] // Ukuran ikon (lebar, tinggi)
          // iconAnchor: [24, 40] // Posisi ikon yang terhubung dengan titik lokasi
        })
      : L.icon({
          iconSize: [25, 41],
          iconAnchor: [10, 41],
          popupAnchor: [2, -40],
          iconUrl: default_marker_icon,
          shadowUrl:
            "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png"
        })
  }

  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.invalidateSize() // Invalidate size when column size changes
    }
  }, [showSideTable])

  const showSideTableBtnClick = () => {
    //   alert("Custom control button clicked!")
    //   console.log("Custom control button clicked!")
    if (
      pageAttr?.crudOpt?.map_display_table &&
      pageAttr?.crudOpt?.map_display_table_position === "left"
    ) {
      const sideTableLeft = document.getElementById("sideTableLeft")
      let newState = sideTableLeft === null
      // console.log(sideTableLeft, newState)
      sessionStorage.setItem(`showSideTable_${storeName}`, newState)
      setShowSideTable(newState)

      if (sideTableLeft) {
        sideTableLeft.style.display =
          sideTableLeft.style.display === "none" ? "block" : "none"
      }
    } else if (
      pageAttr?.crudOpt?.map_display_table &&
      pageAttr?.crudOpt?.map_display_table_position === "right"
    ) {
      const sideTableRight = document.getElementById("sideTableRight")
      let newState = sideTableRight === null
      // console.log(sideTableRight, newState)
      sessionStorage.setItem(`showSideTable_${storeName}`, newState)
      setShowSideTable(newState)
      if (sideTableRight) {
        sideTableRight.style.display =
          sideTableRight.style.display === "none" ? "block" : "none"
      }
    }

    // console.log(showSideTablexCopy)

    // const sideTable = document.getElementById("sideTable")
    // if (sideTable) {
    //   sideTable.style.display =
    //     sideTable.style.display === "none" ? "block" : "none"
    // }
  }

  const goToMainMarkerBtnClick = () => {
    mapRef.current.setView(mapOpt.mainMarker, mapDefaultOpt?.zoom)
  }

  const tableColRef = useRef()
  function ShowSideTableBtnFunc() {
    const map = useMap()

    // Mengecek apakah kontrol kustom sudah ada di peta
    const existingControl = map
      .getContainer()
      .querySelector(".custom-control-show-map-table")
    if (!existingControl) {
      const button = L.DomUtil.create(
        "a",
        "leaflet-control-zoom-fullscreen custom-control-show-map-table"
      )
      button.href = "javascript:void(0)"
      button.title = t("Show Side Table")
      button.setAttribute("role", "button")
      button.setAttribute("aria-label", t("Show Side Table"))
      button.onclick = showSideTableBtnClick

      // Menambahkan ikon dari Material Icons ke dalam tombol
      const iconSpan = L.DomUtil.create(
        "span",
        "material-icons-outlined align-middle"
      )
      iconSpan.textContent = "table_view"
      button.appendChild(iconSpan)

      // Menambahkan tombol kontrol kustom ke peta
      const controlContainer = L.DomUtil.create(
        "div",
        "leaflet-bar leaflet-control"
      )
      controlContainer.appendChild(button)
      // map
      //   .getContainer()
      //   .querySelector(".leaflet-top.leaflet-right")
      //   .appendChild(controlContainer)

      const ShowSideTableBtn = L.Control.extend({
        onAdd: function () {
          // Membuat elemen button dan ikon
          // const button = L.DomUtil.create(
          //   "button",
          //   "leaflet-bar leaflet-control custom-control-show-map-table"
          // )
          // button.title = t("Show Side Table")

          // // Membuat elemen ikon
          // const iconSpan = L.DomUtil.create(
          //   "span",
          //   "material-icons-outlined align-middle"
          // )
          // iconSpan.textContent = "table_view"

          // // Menambahkan ikon ke dalam tombol
          // button.appendChild(iconSpan)

          // // Menambahkan event listener untuk menangani klik tombol
          // button.addEventListener("click", showSideTableBtnClick)

          // return button
          return controlContainer
        }
      })

      // Menambahkan kontrol kustom ke peta
      new ShowSideTableBtn({ position: "topright" }).addTo(map)

      // // Membuat kontrol kustom menggunakan Leaflet
      // const ShowSideTableBtn = L.Control.extend({
      //   onAdd: function () {
      //     const button = L.DomUtil.create(
      //       "button",
      //       "custom-control-show-map-table"
      //     )
      //     button.innerHTML = "Custom Button"
      //     button.onclick = showSideTableBtnClick
      //     return button
      //   }
      // })

      // // Menambahkan kontrol kustom ke peta
      // new ShowSideTableBtn({ position: "topright" }).addTo(map)
    }

    return null
  }

  function GoToMainMarkerBtnFunc() {
    const map = useMap()

    // Mengecek apakah kontrol kustom sudah ada di peta
    const existingControl = map
      .getContainer()
      .querySelector(".custom-control-go-to-main-marker")
    if (!existingControl) {
      const button = L.DomUtil.create(
        "a",
        "leaflet-control-zoom-fullscreen custom-control-go-to-main-marker"
      )
      button.href = "javascript:void(0)"
      button.title = t("Go to Main Marker")
      button.setAttribute("role", "button")
      button.setAttribute("aria-label", t("Go to Main Marker"))
      button.onclick = goToMainMarkerBtnClick

      // Menambahkan ikon dari Material Icons ke dalam tombol
      const iconSpan = L.DomUtil.create(
        "span",
        "material-icons-outlined align-middle"
      )
      iconSpan.textContent = "home"
      button.appendChild(iconSpan)

      // Menambahkan tombol kontrol kustom ke peta
      const controlContainer = L.DomUtil.create(
        "div",
        "leaflet-bar leaflet-control"
      )
      controlContainer.appendChild(button)

      const BtnElFix = L.Control.extend({
        onAdd: function () {
          return controlContainer
        }
      })

      new BtnElFix({ position: "topright" }).addTo(map)
    }

    return null
  }

  const handleFormChangeFromChild = (dataFirst) => {
    console.log(dataFirst)
    let data = dataFirst
    data["title"] = data.name
    // console.log(data)

    // Assuming node.data.data is the property you want to update
    const updatedNodeDataArray = newDataTree?.nodeDataArray?.map((nodeData) => {
      if (nodeData.key === data.id.toString()) {
        // Update the specific property in your node data
        return { ...nodeData, ...data }
      }
      return nodeData
    })
  }

  const [searchParams] = useSearchParams()

  useEffect(() => {
    let isLoadState = pageAttr?.crudOpt?.data?.dynamicColumnsFilter?.some(
      (item) => item?.rules?.required === true || item?.required === true
    )
    if (!isLoadState) {
      // console.log("Loading")
      if (
        // !store.pageData[storeName]?.data ||
        (pageAttr?.crudOpt?.getAlwaysGrid ||
          searchParams.get("refresh") === "1" ||
          !store.pageData[storeName]?.status) &&
        !store.pageData[storeName]?.isLoading &&
        // !store.pageData[storeName]?.status &&
        pageAttr?.crudOpt?.endpointName &&
        !pageAttr?.isFromAddPage
      ) {
        setLoading(true)
        setLoadingText(`${t("Loading Data")} ${t(pageAttr.pageTitle)}`)

        dispatch(
          pageAttr?.crudOpt?.serverSideGrid
            ? getDataList({
                storeName,
                endpointName: pageAttr?.crudOpt?.endpointName,
                sort_dir,
                sort,
                search: searchTerm,
                page: currentPage,
                length: rowsPerPage,
                ...filtersCustom
              })
            : getDataList({
                storeName,
                endpointName: pageAttr?.crudOpt?.endpointName,
                ...filtersCustom
              })
        ).then((x) => {
          getMarker(x?.payload?.data ?? [])
          // console.log(x?.payload?.data)
          setLoading(false)
        })
      } else {
        //////CACHE ON RELATIONS CRUD
        if (relPageId || pageAttr?.isViewOnForm) {
          // console.log(store.pageData[storeName]?.params)
          // console.log(pageAttr?.crudOpt?.initialFiltersCondition)
          const lastParentData = storeParent?.selectedData
          let curPageLastParam = store.pageData[storeName]?.params
          let curPageAttrPageFilArray =
            pageAttr?.crudOpt?.initialFiltersCondition
          let reloadState = false

          if (curPageAttrPageFilArray) {
            if (curPageAttrPageFilArray?.length > 1 && lastParentData) {
              curPageAttrPageFilArray?.map((childPar) => {
                // console.log(childPar?.fieldNameCurrent)
                // console.log(lastParentData[childPar?.value_from_parentFieldName])
                reloadState =
                  reloadState ||
                  `${curPageLastParam[curPageAttrPageFilField]}` !==
                    `${lastParentData[childPar?.value_from_parentFieldName]}`
              })
            } else {
              const curPageAttrPageFilField =
                curPageAttrPageFilArray[0]?.fieldNameCurrent
              // console.log(
              //   curPageAttrPageFilField,
              //   `${curPageLastParam[curPageAttrPageFilField]}`,
              //   `${id}`
              // )
              reloadState =
                `${curPageLastParam?.[curPageAttrPageFilField]}` !== `${id}`
            }
          }
          if (reloadState) {
            if (!store.pageData[storeName]?.isLoading) {
              dispatch(
                pageAttr?.crudOpt?.serverSideGrid
                  ? getDataList({
                      storeName,
                      endpointName: pageAttr?.crudOpt?.endpointName,
                      sort_dir,
                      sort,
                      search: searchTerm,
                      page: currentPage,
                      length: rowsPerPage,
                      ...filtersCustom
                    })
                  : getDataList({
                      storeName,
                      endpointName: pageAttr?.crudOpt?.endpointName,
                      ...filtersCustom
                    })
              ).then((x) => {
                // console.log(x)
                getMarker(x?.payload?.data ?? [])
                setLoading(false)
              })
            }
          }
        }

        getMarker(store.pageData[storeName]?.data ?? [])
        setLoading(false)
      }
    } else {
      setLoading(true)
      setLoadingText(`${t("Loading Data")} ${t(pageAttr.pageTitle)}`)
      getMarker(store.pageData[storeName]?.data)
      setLoading(false)
    }
  }, [store.pageData[storeName]?.data])

  useEffect(() => {
    document.body.style.paddingRight = "0"
    return () => {
      document.body.style.paddingRight = ""
    }
  }, [store.pageData[storeName]?.data])

  useEffect(() => {
    if (markerPositions.length > 0) {
      // Extract latitudes and longitudes from marker positions
      //   const latitudes = []
      //   const longitudes = []
      //   markerPositions.forEach((position) => {
      //     if (Array.isArray(position[0])) {
      //       position.forEach(([lat, lng]) => {
      //         latitudes.push(lat)
      //         longitudes.push(lng)
      //       })
      //     } else if (typeof position[0] === "number") {
      //       latitudes.push(position[0])
      //       longitudes.push(position[1])
      //     } else if (typeof position === "number") {
      //       latitudes.push(position)
      //     }
      //   })

      //   // Calculate the center of the bounding box
      //   const centerLat =
      //     latitudes.reduce((sum, lat) => sum + lat, 0) / latitudes.length
      //   const centerLng =
      //     longitudes.reduce((sum, lng) => sum + lng, 0) / longitudes.length

      //   console.log([centerLat, centerLng])
      //   setCenterMap([centerLat, centerLng])

      if (mapRef.current) {
        //   setBounds(boundss)
        // setTimeout(() => {
        //   mapRef.current?.flyTo(getCenterAverage(markerPositions), 12, {
        //     animate: true,
        //     duration: 0.5
        //   })
        if (!mapOpt?.mainMarker) {
          if (mapOpt?.center || store.mapOptions?.[storeName]?.center) {
            mapDefaultOpt["center"] =
              store.mapOptions?.[storeName]?.center ??
              mapOpt?.center ??
              mapCenterDefault
            mapRef.current.setZoom(
              store.mapOptions?.[storeName]?.center || mapOpt?.center ? 19 : 7
            )
          } else {
            const boundss = getBoundsFromMarkers(markerPositions)
            mapRef.current.fitBounds(boundss)
          }
        } else {
          if (!store.mapOptions?.[storeName]?.center) {
            mapDefaultOpt["center"] = mapOpt.mainMarker
            mapRef.current.setView(mapOpt.mainMarker, mapDefaultOpt?.zoom)
            // mapRef.current?.flyTo(mapOpt.mainMarker, mapDefaultOpt?.zoom, {
            //   animate: true,
            //   duration: 1.5
            // })
          } else {
            mapDefaultOpt["center"] = store.mapOptions?.[storeName]?.center
            mapRef.current.setView(
              store.mapOptions?.[storeName]?.center,
              store.mapOptions?.[storeName]?.zoom ?? mapDefaultOpt?.zoom
            )
          }
        }
        // }, 300)

        document.addEventListener("fullscreenchange", handleFullscreenChange)
        return () => {
          // Membersihkan event listener ketika komponen di-unmount
          document.removeEventListener(
            "fullscreenchange",
            handleFullscreenChange
          )
        }
      }
    }

    //   setCenterMap(getCenterAverage([...markerPositions]))
  }, [markerPositions])
  // const onRowClicked = (dataRow) => {
  //   // console.log(dataRow)
  //   const marker = markersRef.current[dataRow?.id]
  //   let marker_id = `map_marker_${dataRow?.id}`
  //   if (marker) {
  //     mapRef.current.closePopup()
  //     let updatedRowHighlight = []
  //     let showTooltipModal = null
  //     if (rowHighlight?.includes(dataRow?.id)) {
  //       mapRef.current.eachLayer((layer) => {
  //         if (layer instanceof L.Polygon) {
  //           layer.setStyle({ color: default_marker_path_color })
  //         }
  //       })
  //       const boundss = getBoundsFromMarkers(markerPositions)
  //       mapRef.current.fitBounds(boundss)
  //     } else {
  //       mapRef.current.eachLayer((layer) => {
  //         if (layer instanceof L.Polygon) {
  //           if (layer.options.id === marker_id) {
  //             layer.setStyle({ color: default_marker_path_color_selected })
  //           } else {
  //             layer.setStyle({ color: default_marker_path_color })
  //           }
  //         }
  //       })
  //       updatedRowHighlight = [dataRow?.id]
  //       showTooltipModal = dataRow?.id
  //       //   console.log(marker)
  //       let centerPan
  //       let boundsx
  //       try {
  //         centerPan = marker.getLatLng()
  //         // boundsx = L.latLngBounds(centerPan)
  //       } catch (error) {
  //         centerPan = marker.getCenter()
  //         // boundsx = L.latLngBounds(marker.getBounds())
  //       }
  //       if (centerPan) {
  //         // Variabel sementara untuk melacak apakah zoom sudah diatur
  //         let isZoomSet = false

  //         // Tambahkan event listener untuk menangani setZoom setelah flyTo selesai
  //         mapRef.current.on("moveend", () => {
  //           // Cek apakah zoom sudah diatur
  //           if (!isZoomSet) {
  //             // Set zoom level setelah transisi selesai
  //             mapRef.current.setZoom(mapRef.current.getZoom() - 1)
  //             // Atur isZoomSet menjadi true agar tidak dipicu lagi
  //             isZoomSet = true
  //           }
  //         })

  //         //   mapRef.current.setView(centerPan, 20)
  //         // mapRef.current.panTo(centerPan)
  //         // atau
  //         mapRef.current.flyTo(centerPan, 20, { duration: 1 })

  //         // fit bound
  //         // mapRef.current.setZoom(15)
  //         // mapRef.current.fitBounds(boundsx)
  //       }
  //     }

  //     setRowHighlight(updatedRowHighlight)
  //     //   setShowModalTooltip(showTooltipModal)
  //   }
  // }

  const onRowClicked = (dataRow) => {
    const marker = markersRef.current[dataRow?.id]
    let marker_id = `map_marker_${dataRow?.id}`
    if (marker) {
      mapRef.current.closePopup()
      let updatedRowHighlight = []
      let showTooltipModal = null
      const isHighlighted = rowHighlight?.includes(dataRow?.id)

      if (isHighlighted) {
        mapRef.current.eachLayer((layer) => {
          if (layer instanceof L.Polygon) {
            layer.setStyle({ color: default_marker_path_color })
          }
        })
        const boundss = getBoundsFromMarkers(markerPositions)
        mapRef.current.fitBounds(boundss)
      } else {
        mapRef.current.eachLayer((layer) => {
          if (layer instanceof L.Polygon) {
            const color =
              layer.options.id === marker_id
                ? default_marker_path_color_selected
                : default_marker_path_color
            layer.setStyle({ color })
          }
        })
        updatedRowHighlight = [dataRow?.id]
        showTooltipModal = dataRow?.id

        let centerPan
        try {
          centerPan = marker.getLatLng()
        } catch (error) {
          centerPan = marker.getCenter()
        }

        if (centerPan) {
          mapRef.current.flyTo(centerPan, 20, { duration: 0.5 })

          let isZoomSet = false
          const onMoveEnd = () => {
            if (!isZoomSet) {
              mapRef.current.setZoom(mapRef.current.getZoom() - 1)
              isZoomSet = true
              mapRef.current.off("moveend", onMoveEnd) // Remove listener after first trigger
            }
          }

          mapRef.current.on("moveend", onMoveEnd)
        }
      }

      setRowHighlight(updatedRowHighlight)
      // setShowModalTooltip(showTooltipModal);
    }
  }

  const closeModal = (e) => {
    // console.log(e)
    setShowModalTooltip(false)
  }

  const handleMarkerClick = (dataRow, isMarker, coordinates) => {
    // console.log(dataRow)
    // console.log(showModalTooltip)
    // setShowModalTooltip(!showModalTooltip)

    setShowModalTooltip(true)
    setRowHighlight([dataRow?.id])
    const marker = markersRef.current[dataRow?.id]
    let marker_id = `map_marker_${dataRow?.id}`
    if (marker) {
      //   console.log(marker)
      let centerPan
      let boundsx
      try {
        centerPan = marker.getLatLng()
        boundsx = L.latLngBounds(centerPan)
      } catch (error) {
        centerPan = marker.getCenter()
        boundsx = L.latLngBounds(marker.getBounds())
      }
      if (centerPan) {
        mapRef.current.panTo(centerPan)
        // atau
        // mapRef.current.flyTo(centerPan, 19)

        // fit bound
        if (boundsx?.LatLngBounds) {
          mapRef.current.fitBounds(boundsx)
        }

        // Mengubah warna polygon dengan ID tertentu
        mapRef.current.eachLayer((layer) => {
          if (layer instanceof L.Polygon) {
            if (layer.options.id === marker_id) {
              layer.setStyle({ color: default_marker_path_color_selected })
            } else {
              layer.setStyle({ color: default_marker_path_color })
            }
          }
        })
      }

      // let coordinates = marker.getLatLng() ?? marker.getCenter()
      let mainMarker = mapOpt?.mainMarker
      if (
        mapOtherOpt?.calcDistanceFromMainMarker &&
        mainMarker &&
        coordinates &&
        isMarker
      ) {
        let latlng1 = L.latLng(coordinates[0], coordinates[1])
        let latlng2 = L.latLng(mainMarker[0], mainMarker[1])
        let distanceInMeters = latlng1.distanceTo(latlng2)
        let distanceInKilometers = distanceInMeters / 1000
        let roundedDistanceInKilometers = distanceInKilometers.toFixed(1)
        // console.log(`${roundedDistanceInKilometers} kilometers`)
        let mapDistanceFromMainCopy = { ...mapDistanceFromMain }
        mapDistanceFromMainCopy[dataRow?.id] = roundedDistanceInKilometers
        setMapDistanceFromMain(mapDistanceFromMainCopy)
      }

      //   const tooltipModal = modalTooltipRef.current[dataRow?.id]
      //   console.log(tooltipModal)
      //   tooltipModal.toggle()

      //   setTimeout(() => {
      //     setShowModalTooltip(!showModalTooltip)
      //   }, 200)
      //   if (
      //     pageAttr.crudOpt?.data?.dynamicTooltipColumns &&
      //     pageAttr.crudOpt?.dynamicTooltipColumnsAsModal
      //   ) {
      //     return modalTooltipRender(dataRow)
      //   }
    }
  }

  const modalTooltipRender = async (dataRow) => {
    let titleText =
      dataRow?.[pageAttr.crudOpt?.dynamicTooltipColumnsTitleFieldName] ??
      t("Detail Location")

    let HtmlComponent = (
      <>
        {pageAttr.crudOpt?.data?.dynamicTooltipColumns?.map((item, index) => (
          <>
            {(item?.columnName || item?.fieldName) && (
              <p
                key={`tooltip_column_${index}`}
                className="mb-0 mt-50 fw-bolder"
              >
                {t(item?.columnName ?? item?.fieldName)}
              </p>
            )}
            <DynamicRow
              key={`tooltip_row_${index}`}
              className="mb-0"
              row={dataRow}
              column={item}
              //   clickToDetailEvent={handleAddEdit}
            />
          </>
        ))}
      </>
    )

    // const htmlString = ReactDOMServer.renderToString(<HtmlComponent />)
    let option = {
      title: <Provider store={storeMain}>{HtmlComponent}</Provider>
    }
    // option["title"] = titleText
    return await MySwal.fire(option)
  }

  const tableComp = (isFloating) => {
    let newPageAttr = { ...pageAttr }
    if (isFloating) {
      newPageAttr["crudOpt"] = {
        ...newPageAttr?.crudOpt,
        ...{
          disabledAdd: true
          // customTableStyles: {
          //   rows: {
          //     style: {
          //       fontSize: "10px",
          //       fontWeight: "bold",
          //       "&:not(:last-of-type)": {
          //         borderBottomWidth: "1px",
          //         borderBottomColor: "#b9b8c1",
          //         borderBottomStyle: "dashed"
          //       }
          //     }
          //   },
          //   headCells: {
          //     style: {
          //       fontSize: "10px",
          //       textTransform: "uppercase",
          //       backgroundColor: "#111111",
          //       color: "#ffffff !important"
          //     }
          //   },
          //   table: {
          //     style: {
          //       minHeight: "45vh"
          //     }
          //   }
          // }
        }
      }
    } else {
      newPageAttr["crudOpt"] = {
        ...newPageAttr?.crudOpt,
        tableFixedHeader: true,
        tableFixedHeaderScrollHeight: "52vh"
      }
    }

    return (
      <Table
        ref={childRef}
        key={resetGridAndFilters}
        pageAttr={newPageAttr}
        storeName={storeName}
        initialData={initialData}
        filtersCustomAdd={filtersCustomAdd}
        handleFiltersChangeToParent={handleFiltersChangeToParent}
        initialPageId={initialPageId}
        customHeaderSet={customHeaderSet}
        btnAddCondition={btnAddCondition}
        headerMode={2}
        onRowClickedToMap={onRowClicked}
        rowHighlight={rowHighlight}
      />
    )
  }

  function MapEvent() {
    const map = useMapEvents({
      zoomend: () => {
        const zoomLevel = map.getZoom()
        const center = map.getCenter()
        const centerArray = [center.lat, center.lng]
        dispatch(
          setMapOptions({
            storeName: storeName,
            data: {
              ...(store.mapOptions?.[storeName] ?? {}),
              zoom: zoomLevel,
              center: centerArray
            }
          })
        )
        // console.log("Map zoom level:", zoomLevel)
        // console.log("Map center position:", center)
      },
      moveend: () => {
        const center = map.getCenter()
        const zoomLevel = map.getZoom()
        const centerArray = [center.lat, center.lng]
        dispatch(
          setMapOptions({
            storeName: storeName,
            data: {
              ...(store.mapOptions?.[storeName] ?? {}),
              zoom: zoomLevel,
              center: centerArray
            }
          })
        )
        // console.log("Map center position:", center)
      }
    })

    return null
  }

  return (
    <UILoader
      blocking={loadingDelete || store?.pageData?.[storeName]?.isLoading}
      loader={<Loader loadingText={loadingText} />}
    >
      <Row id="map_table_container" className="match-height">
        {pageAttr?.crudOpt?.map_display_table &&
          pageAttr?.crudOpt?.map_display_table_position === "left" &&
          showSideTable && (
            <Col
              id="sideTableLeft"
              style={{
                minHeight: "65vh",
                display: showSideTable ? "block" : "none"
              }}
              md={4}
            >
              {tableComp()}
            </Col>
          )}
        <Col>
          <Card id="map_container" className="overflow-hidden">
            <MapContainer
              ref={mapRef}
              //   className="mb-1"
              key={`map_${storeName}`}
              id={`map_${storeName}`}
              style={{ ...mapDefaultStyle, ...mapStyle }}
              {...{
                ...mapDefaultOpt,
                mapOpt
              }}
              //   onFullscreenChange={handleFullscreenChange}
            >
              <TileLayer
                maxZoom={20}
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />

              <MapEvent />

              {(store?.pageData?.[storeName]?.data ||
                store.pageData[storeName]?.filteredData) &&
                markerPositions?.length > 0 && (
                  <Fragment>
                    {getMarkerElement(
                      store.pageData[storeName]?.filteredData ??
                        store?.pageData?.[storeName]?.data ??
                        []
                    )}
                    {mapOpt?.geoSearch !== false && <LeafletgeoSearch />}
                    {/* {mapOpt?.fullscreenControl !== false && (
                    <FullscreenControl
                      // position="topright"
                      title={t("Fullscreen mode")}
                      titleCancel={t("Exit fullscreen mode")}
                      forceSeparateButton={true}
                      //   fullscreenElement={document.getElementById(
                      //     "map_table_container"
                      //   )}
                      // fullscreenElement={
                      //   mapRef.current ? mapRef.current._container : null
                      // }
                      //   onFullscreenChange={handleFullscreenChange}
                    />
                  )} */}
                    {/* <MyMapComponent mapRef={mapRef} /> */}
                    {pageAttr?.crudOpt?.map_display_table &&
                      (pageAttr?.crudOpt?.map_display_table_position ===
                        "left" ||
                        pageAttr?.crudOpt?.map_display_table_position ===
                          "right") && <ShowSideTableBtnFunc />}

                    {mapOpt?.mainMarker && <GoToMainMarkerBtnFunc />}

                    {pageAttr?.crudOpt?.map_display_table &&
                      (pageAttr?.crudOpt?.map_display_table_position ===
                        "left" ||
                        pageAttr?.crudOpt?.map_display_table_position ===
                          "right") &&
                      document.fullscreenElement &&
                      showSideTable && (
                        <Col
                          id="sideTable"
                          className={isFullscreen ? "sideTable-fullscreen" : ""}
                          style={{
                            minHeight: "65vh",
                            position: "absolute",
                            //   display:
                            //     document.fullscreenElement && showSideTable
                            //       ? "block"
                            //       : "none",
                            zIndex: 1000,
                            boxShadow: "0 4px 24px 0 rgba(34, 41, 47, 0.1)",
                            ...(pageAttr?.crudOpt
                              ?.map_display_table_position === "left"
                              ? { left: "13px" }
                              : { right: "13px" }),
                            bottom: "-30px"
                          }}
                          md={pageAttr?.crudOpt?.map_display_table_size ?? 4}
                        >
                          {tableComp(true)}
                        </Col>
                      )}
                  </Fragment>
                )}
            </MapContainer>
          </Card>
        </Col>
        {pageAttr?.crudOpt?.map_display_table &&
          pageAttr?.crudOpt?.map_display_table_position === "right" &&
          showSideTable && (
            <Col
              id="sideTableRight"
              style={{
                minHeight: "65vh",
                display: showSideTable ? "block" : "none"
              }}
              md={pageAttr?.crudOpt?.map_display_table_size ?? 4}
            >
              {tableComp()}
            </Col>
          )}
      </Row>
    </UILoader>
  )
}

export default MapPage
