import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit"
import { resetData as resetActivitiesData } from "@src/views/my-info/store/my_activities"
import axios from "axios"
import qs from "qs"
import useJwt from "@src/auth/jwt/useJwt"
import produce from "immer"
import {
  getDepthValue,
  replaceLogo,
  flattenArray,
  addNewCounterEachItem,
  arrayToNested,
  updateExpandedToFalse,
  isNestedObjectArray
} from "@utils"

const axiosSso = useJwt.ssoAxiosInterceptor
const axiosHR = useJwt.hrAxiosInterceptor
const axiosMarketing = useJwt.marketingAxiosInterceptor
const axiosFinance = useJwt.financeAxiosInterceptor
const axiosGlobal = useJwt.globalAxiosInterceptor
const axiosFs = useJwt.fsAxiosInterceptor
const axiosSipkdDoc = useJwt.sipkdDocAxiosInterceptor
const axiosLab = useJwt.labAxiosInterceptor
const axiosWarehouse = useJwt.warehouseAxiosInterceptor
const axiosPurchasing = useJwt.purchasingAxiosInterceptor
const axiosPlain = useJwt.plainAxiosInterceptor
const axiosPlainNoHeaders = useJwt.plainNoHeadersAxiosInterceptor

function removeById(id, arr) {
  for (let i = 0; i < arr.length; i++) {
    if (arr[i].id === id) {
      // Remove the object from the array
      arr.splice(i, 1)
      return true
    } else if (Array.isArray(arr[i].children)) {
      // Recursively search the children array
      if (removeById(id, arr[i].children)) {
        return true
      }
    }
  }
  return false
}

function updateById(id, newData, arr) {
  for (let i = 0; i < arr.length; i++) {
    if (arr[i].id === id) {
      // Update the object with the new data
      Object.assign(arr[i], newData)
      return true
    } else if (Array.isArray(arr[i].children)) {
      // Recursively search the children array
      if (updateById(id, newData, arr[i].children)) {
        return true
      }
    }
  }
  return false
}

function addDataToNestedArray(nestedArray, parentId, newData) {
  let parentFound = false

  // Traverse the nested array and check if the parent_id exists
  for (let i = 0; i < nestedArray.length; i++) {
    const nestedObj = nestedArray[i]

    if (nestedObj.id === parentId) {
      if (!nestedObj.children) {
        nestedObj.children = []
      }
      nestedObj.children.push(newData)

      parentFound = true
      break
    } else if (nestedObj.children) {
      addDataToNestedArray(nestedObj.children, parentId, newData)
    }
  }

  // If parent_id does not exist in the current nested array, add the new data to the root
  if (!parentFound) {
    nestedArray.push(newData)
  }
}

function addDataListToNestedArray(nestedArray, parentId, newData) {
  let parentFound = false

  // Traverse the nested array and check if the parent_id exists
  for (let i = 0; i < nestedArray.length; i++) {
    const nestedObj = nestedArray[i]

    if (nestedObj.id === parentId) {
      // if (!nestedObj.children) {
      //   nestedObj.children = []
      // }
      nestedObj.children = newData

      parentFound = true
      break
    } else if (nestedObj.children) {
      addDataListToNestedArray(nestedObj.children, parentId, newData)
    }
  }
}

function getBaseUrlFromUrl(url) {
  // Create a temporary anchor element to parse the URL
  let a = document.createElement("a")
  a.href = url

  // Extract the server name and port
  let server = a.hostname
  let port = a.port || (a.protocol === "https:" ? "443" : "80")

  // Return the result as a string
  return `${a.protocol}//${server}:${port}/`
}

export const getBaseUrl = (
  baseUrlName,
  endpointName = null,
  removeDefaultHttpHeader
) => {
  let result = axios
  if (baseUrlName === "sso") {
    result = axiosSso
  } else if (baseUrlName === "hr") {
    result = axiosHR
  } else if (baseUrlName === "marketing") {
    result = axiosMarketing
  } else if (baseUrlName === "finance") {
    result = axiosFinance
  } else if (baseUrlName === "globaldata") {
    result = axiosGlobal
  } else if (baseUrlName === "fs") {
    result = axiosFs
  } else if (baseUrlName === "sipkddoc") {
    result = axiosSipkdDoc
  } else if (baseUrlName === "lab") {
    result = axiosLab
  } else if (baseUrlName === "warehouse") {
    result = axiosWarehouse
  } else if (baseUrlName === "purchasing") {
    result = axiosPurchasing
  } else if (
    !baseUrlName &&
    (endpointName?.includes("https:") || endpointName?.includes("http:"))
  ) {
    // const axiosNew = axios.create({
    //   baseURL: endpointName
    // })
    if (removeDefaultHttpHeader) {
      result = axiosPlainNoHeaders
    } else {
      result = axiosPlain
    }
  }

  let serverName =
    result?.defaults?.baseURL !== "" ? result?.defaults?.baseURL : endpointName
  let baseUrlStr = getBaseUrlFromUrl(serverName)
  let serversList = JSON.parse(sessionStorage.getItem("serversList") ?? "[]")
  if (
    serversList.indexOf(baseUrlStr) === -1 &&
    !baseUrlStr.includes("https://auth.insaba.co.id")
  ) {
    serversList.push(baseUrlStr)
  }
  sessionStorage.setItem("serversList", JSON.stringify(serversList))
  return result
}

const getKeySelector = (keySelector, response, pageAttr) => {
  // console.log(keySelector !== "")
  let responseFinal =
    keySelector || keySelector === ""
      ? keySelector !== ""
        ? keySelector?.includes(".")
          ? getDepthValue(response?.data, keySelector)
          : response?.data?.[keySelector]
        : response?.data
      : response?.data?.data ?? response?.data

  const data_idField = pageAttr?.crudOpt?.data_idField
  const dataHadId = responseFinal?.[0]?.id
  if (data_idField) {
    for (let i = 0; i < responseFinal.length; i++) {
      responseFinal[i]["id"] = responseFinal[i][data_idField]
    }
  }

  if (!data_idField && !dataHadId) {
    for (let i = 0; i < responseFinal.length; i++) {
      responseFinal[i]["id"] = i + 1
    }
  }

  // console.log(responseFinal)
  return responseFinal
}

export const setMapOptions = createAsyncThunk(
  `dynamicStore/setMapOptions`,
  async (params, { dispatch, rejectWithValue }) => {
    try {
      // console.log(params)
      return params
    } catch (error) {
      console.error(error)
      return rejectWithValue(error.response.data)
    }
  }
)

export const setDataFrameLoading = createAsyncThunk(
  `dynamicStore/setDataFrameLoading`,
  async (params, { dispatch, rejectWithValue }) => {
    try {
      // console.log(params)
      return params
    } catch (error) {
      console.error(error)
      return rejectWithValue(error.response.data)
    }
  }
)

export const setDataFrame = createAsyncThunk(
  `dynamicStore/setDataFrame`,
  async (params, { dispatch, rejectWithValue }) => {
    try {
      // console.log(params)
      return params
    } catch (error) {
      console.error(error)
      return rejectWithValue(error.response.data)
    }
  }
)

export const setDataFrameHtml = createAsyncThunk(
  `dynamicStore/setDataFrameHtml`,
  async (params, { dispatch, rejectWithValue }) => {
    try {
      // console.log(params)
      return params
    } catch (error) {
      console.error(error)
      return rejectWithValue(error.response.data)
    }
  }
)

export const setDataFrameHtmlModal = createAsyncThunk(
  `dynamicStore/setDataFrameHtmlModal`,
  async (params, { dispatch, rejectWithValue }) => {
    try {
      // console.log(params)
      return params
    } catch (error) {
      console.error(error)
      return rejectWithValue(error.response.data)
    }
  }
)

export const setStorePageAttributes = createAsyncThunk(
  `dynamicStore/setStorePageAttributes`,
  async (params, { dispatch, rejectWithValue }) => {
    try {
      // console.log(params)
      return params
    } catch (error) {
      console.error(error)
      return rejectWithValue(error.response.data)
    }
  }
)

export const getSummaryData = createAsyncThunk(
  `dynamicStore/getSummaryData`,
  async (params, { getState, rejectWithValue }) => {
    try {
      const storeName = params?.storeName
      const baseUrl = params?.baseUrl

      const pageAttr = params?.pageAttrParam

      const endpointName = params?.endpointName?.includes("?")
        ? params?.endpointName?.split("?")[0]
        : params?.endpointName

      let endpointNameParams =
        params?.endpointName?.includes("?") &&
        pageAttr?.crudOpt?.getApiSummaryDataWithParam &&
        !pageAttr?.crudOpt?.getApiSummaryDataEndpointName
          ? `?${params?.endpointName?.split("?")[1]}`
          : ""

      let fixEndPoint =
        endpointNameParams?.length > 0
          ? `${endpointName}/summary${endpointNameParams}`
          : pageAttr?.crudOpt?.getApiSummaryDataEndpointName ??
            `${endpointName}/summary`

      // console.log(
      //   pageAttr,
      //   params,
      //   !pageAttr?.crudOpt?.getApiSummaryDataEndpointName &&
      //     !pageAttr?.crudOpt?.getApiSummaryDataWithParam
      // )

      Object.keys(params).forEach((key) => {
        if (params[key] === null || params[key] === "") {
          delete params[key]
        }
      })

      delete params.endpointName
      delete params.storeName
      delete params.baseUrl
      delete params.pageAttrParam

      // console.log(
      //   storeName,
      //   baseUrl,
      //   getState()["dynamicStore"]?.pageAttributes[storeName]?.crudOpt?.baseUrl
      // )
      // console.log(fixEndPoint, endpointName, params)
      const baseUrlOpt = baseUrl ?? pageAttr?.crudOpt?.baseUrl
      const response = await getBaseUrl(baseUrlOpt, endpointName).get(
        fixEndPoint,
        {
          params
        }
      )
      // console.log(response)
      return {
        storeName: storeName,
        data: getKeySelector(
          getState()["dynamicStore"]?.pageAttributes[storeName]?.crudOpt
            ?.resp_key,
          response,
          getState()["dynamicStore"]?.pageAttributes[storeName]
        )
      }
    } catch (error) {
      console.log(error)
      return rejectWithValue(error.response?.data)
    }
  }
)

function updateUrlWithParams(url, newParams) {
  const [baseUrl, queryString] = url.split("?")
  let params = qs.parse(queryString)
  params = { ...params, ...newParams }
  const newQueryString = qs.stringify(params)
  return `${baseUrl}?${newQueryString}`
}

export const getDataList = createAsyncThunk(
  `dynamicStore/getDataList`,
  async (params, { getState, rejectWithValue, dispatch }) => {
    try {
      const originalParams = JSON.parse(JSON.stringify(params))
      let endpointName = params?.endpointName
      const storeName = params?.storeName
      const pageAttr = getState()["dynamicStore"]?.pageAttributes[storeName]
      const pageData = getState()["dynamicStore"]?.pageData[storeName]

      const baseUrl = params?.baseUrl
      let isLoadState = true
      // console.log(getState()["dynamicStore"]?.pageData[storeName]?.params)

      /////prevent load data if filter include required attr and undefined
      pageAttr?.crudOpt?.data?.dynamicColumnsFilter
        ?.filter(
          (x) => x?.rules?.required === true
          //   &&
          // x?.type !== "id_employee" &&
          // x?.fieldName !== "id_unit"
        )
        .map((y) => {
          // eslint-disable-next-line no-unneeded-ternary
          isLoadState = params?.[y.fieldName] ? true : false
        })

      // console.log(`isLoadState=${isLoadState} getDataList `)

      if (!isLoadState) {
        return {
          params: params,
          data: []
          // total: 0
        }
      }

      const serverSideGridQuery = pageAttr?.crudOpt?.serverSideGridQuery
      const serverSideGridPath = pageAttr?.crudOpt?.serverSideGridPath

      Object.keys(params).forEach((key) => {
        ////add payload type === path
        if (pageAttr?.crudOpt?.data?.dynamicColumnsFilter) {
          pageAttr?.crudOpt?.data?.dynamicColumnsFilter?.map((x) => {
            if (
              x?.fieldName === key &&
              x?.payloadType === "path" &&
              (params[key] || params[key] === null || params[key] === "")
            ) {
              const questionMarkIndex = endpointName.indexOf("?")
              if (questionMarkIndex !== -1) {
                const baseURLCurrent = endpointName.substring(
                  0,
                  questionMarkIndex
                )
                const queryParams = endpointName.substring(questionMarkIndex)
                const newPath =
                  params[key] === null || params[key] === ""
                    ? ""
                    : `/${params[key]}`
                endpointName = `${baseURLCurrent}${newPath}${queryParams}`
              } else {
                endpointName = `${endpointName}/${params[key]}`
              }
              delete params[key]
            }
          })
        }

        if (params[key] === null || params[key] === "") {
          delete params[key]
        }

        // console.log(params)
        if (serverSideGridQuery) {
          if (serverSideGridQuery[key]) {
            const copyOldVal = params[key]
            delete params[key]
            params[serverSideGridQuery[key]] = copyOldVal
          }
        }

        if (serverSideGridPath) {
          serverSideGridPath.map((keyPath) => {
            endpointName = `${endpointName}/${params[keyPath]}`
          })
        }
      })

      const serverSideGridResp = pageAttr?.crudOpt?.serverSideGridResp

      let serverSideRespTotalDataField = serverSideGridResp?.total ?? "total"
      let serverSideRespTotalPageField = serverSideGridResp?.pages ?? "pages"
      let serverSideRespLengthField = serverSideGridResp?.length ?? "length"
      // console.log(params)
      const copyParams = JSON.parse(JSON.stringify(params))
      // console.log(endpointName, copyParams)
      delete copyParams.endpointName
      delete copyParams.storeName
      delete copyParams.baseUrl
      delete copyParams.nodeMode

      const baseUrlOpt = baseUrl ?? pageAttr?.crudOpt?.baseUrl

      const removeDefaultHttpHeader = pageAttr?.crudOpt?.removeDefaultHttpHeader
      const headersExtra =
        pageAttr?.crudOpt?.headersExtra_GET ?? pageAttr?.crudOpt?.headersExtra

      let response = null

      if (
        pageAttr?.crudOpt?.endpointName_method &&
        pageAttr?.crudOpt?.endpointName_method !== "GET"
      ) {
        response = await getBaseUrl(
          baseUrlOpt,
          endpointName,
          removeDefaultHttpHeader
        )({
          method: pageAttr?.crudOpt?.endpointName_method,
          url: `${endpointName}`,
          params: copyParams,
          data: copyParams,
          ...(headersExtra ? { headers: headersExtra } : {})
        })
      } else {
        const updatedEndpointName = updateUrlWithParams(
          endpointName,
          copyParams
        )
        response = await getBaseUrl(
          baseUrlOpt,
          endpointName,
          removeDefaultHttpHeader
        ).get(`${updatedEndpointName}`, {
          // params: copyParams,
          ...(headersExtra ? { headers: headersExtra } : {})
        })
      }
      // console.log(response)
      // const response = await getBaseUrl(
      //   baseUrlOpt,
      //   endpointName,
      //   removeDefaultHttpHeader
      // ).get(`${endpointName}`, {
      //   params: copyParams,
      //   ...(headersExtra ? { headers: headersExtra } : {})
      // })

      const gridTheme = pageAttr?.crudOpt?.gridTheme
      let oldNewData = JSON.parse(JSON.stringify(pageData?.dataOld ?? []))

      if (pageAttr?.crudOpt?.dynamicColumnsFilterToSummary) {
        dispatch(
          getSummaryData({
            endpointName: endpointName,
            storeName: storeName,
            baseUrl: baseUrlOpt,
            ...(copyParams ?? {})
          })
        )
      }

      // console.log(response?.data, serverSideRespTotalDataField)
      if (gridTheme === "treeview" && oldNewData.length) {
        // const oldNewData2 = [...response.data.data, ...{ expanded: true }]
        return {
          params: originalParams,
          data: getKeySelector(pageAttr?.crudOpt?.resp_key, response, pageAttr),
          total:
            getDepthValue(response.data, serverSideRespTotalDataField) ??
            response.data?.[serverSideRespTotalDataField]
        }
      } else {
        return {
          params: originalParams,
          data: getKeySelector(pageAttr?.crudOpt?.resp_key, response, pageAttr),
          total:
            getDepthValue(response.data, serverSideRespTotalDataField) ??
            response.data?.[serverSideRespTotalDataField]
        }
      }
    } catch (error) {
      console.error(error)
      return rejectWithValue(error?.response?.data)
    }
  }
)

export const getDataById = createAsyncThunk(
  `dynamicStore/getDataById`,
  async (params, { getState, rejectWithValue }) => {
    try {
      const storeName = params?.storeName
      const pageAttr = getState()["dynamicStore"]?.pageAttributes[storeName]
      const pageData = getState()["dynamicStore"]?.pageData[storeName]

      // console.log(params)
      const baseUrl = params?.baseUrl

      const baseUrlOpt = baseUrl ?? pageAttr?.crudOpt?.baseUrl

      const endpointName = params?.endpointName?.includes("?")
        ? params?.endpointName?.split("?")[0]
        : params?.endpointName
      let finUrl = `${endpointName}/${params?.id}`
      const movePathIdToPayload_GET = pageAttr?.crudOpt?.movePathIdToPayload_GET
      if (movePathIdToPayload_GET) {
        finUrl = `${endpointName}?id=${params?.id}`
      }

      const removeDefaultHttpHeader = pageAttr?.crudOpt?.removeDefaultHttpHeader
      const headersExtra =
        pageAttr?.crudOpt?.headersExtra_GET ?? pageAttr?.crudOpt?.headersExtra

      const response = await getBaseUrl(
        baseUrlOpt,
        endpointName,
        removeDefaultHttpHeader
      ).get(finUrl, {
        ...(headersExtra ? { headers: headersExtra } : {})
      })

      // console.log(response)
      return {
        storeName: params?.storeName,
        data: getKeySelector(pageAttr?.crudOpt?.resp_key, response, pageAttr)
      }
    } catch (error) {
      console.error(error)
      return rejectWithValue(error.response.data)
    }
  }
)

export const setSelectedData = createAsyncThunk(
  `dynamicStore/setSelectedData`,
  async ({ rowData, storeName }, { getState }) => {
    try {
      // console.log(storeName)
      const pageAttr = getState()["dynamicStore"]?.pageAttributes[storeName]
      const dynamicFormData = pageAttr?.crudOpt?.data?.dynamicForm
      if (dynamicFormData) {
        const pageData = getState()["dynamicStore"]?.pageData[storeName]
        const dynamicColumnsFilterData = pageData?.dynamicColumnsFilterData
        dynamicFormData?.map((item) => {
          if (item?.initialValueFromFilter) {
            if (dynamicColumnsFilterData?.[item?.fieldName]) {
              rowData[item?.fieldName] =
                dynamicColumnsFilterData?.[item?.fieldName]
            }
          } else if (item?.type === "column" && item?.children) {
            item?.children.map((itemChild) => {
              if (itemChild?.initialValueFromFilter) {
                if (dynamicColumnsFilterData?.[itemChild?.fieldName]) {
                  rowData[itemChild?.fieldName] =
                    dynamicColumnsFilterData?.[itemChild?.fieldName]
                }
              }
            })
          }
        })
      }
      return { rowData, storeName }
    } catch (error) {
      console.error(error)
      return rejectWithValue(error.response.data)
    }
  }
)

export const setcurrentExpandedData = createAsyncThunk(
  `dynamicStore/setcurrentExpandedData`,
  async ({ rowData, storeName }) => {
    try {
      // console.log(rowData, storeName)
      return { rowData, storeName }
    } catch (error) {
      console.error(error)
      return rejectWithValue(error.response.data)
    }
  }
)

export const setExpandedData = createAsyncThunk(
  `dynamicStore/setExpandedData`,
  ({ rowData, storeName }) => {
    try {
      return { rowData, storeName }
    } catch (error) {
      console.error(error)
      return rejectWithValue(error.response.data)
    }
  }
)

export const setTableActiveTabs = createAsyncThunk(
  `dynamicStore/setTableActiveTabs`,
  ({ data, storeName }) => {
    try {
      return { data, storeName }
    } catch (error) {
      console.error(error)
      return rejectWithValue(error.response.data)
    }
  }
)

export const setExpandedDataComponent = createAsyncThunk(
  `dynamicStore/setExpandedDataComponent`,
  ({ rowData, storeName }) => {
    try {
      return { rowData, storeName }
    } catch (error) {
      console.error(error)
      return rejectWithValue(error.response.data)
    }
  }
)

export const setBtnAddCondition = createAsyncThunk(
  `dynamicStore/setBtnAddCondition`,
  async ({ type, storeName, pageAttrOri }, { getState, rejectWithValue }) => {
    try {
      const pageAttr = pageAttrOri?.crudOpt
      const condition = pageAttr?.btnAddTextCondition
      let result = {}
      let valueToCompare
      const finUrl = pageAttr?.btnAddTextConditionDataFrom?.api
      const currentTime = pageAttr?.btnAddTextConditionDataFrom?.currentTime
      const baseUrlOpt = pageAttr?.btnAddTextConditionDataFrom?.baseUrl
      if (condition) {
        if (type === "add") {
          if (finUrl) {
            valueToCompare = await getBaseUrl(baseUrlOpt, finUrl)
              .get(finUrl)
              .then((res) => {
                let keySelector
                const resp_key = pageAttr?.btnAddTextConditionDataFrom?.resp_key
                const field_value =
                  pageAttr?.btnAddTextConditionDataFrom?.field_value

                if (resp_key) {
                  keySelector = res?.data?.[resp_key]
                } else {
                  keySelector = res?.data ?? null
                }

                if (keySelector) {
                  if (Array.isArray(keySelector)) {
                    return keySelector[0]?.[field_value]
                  } else {
                    return keySelector?.[field_value]
                  }
                }
              })
          } else if (currentTime) {
            const nowTime = new Date()
            const nowTimeStr = `${`0${nowTime.getHours()}`.slice(
              -2
            )}:${`0${nowTime.getMinutes()}`.slice(-2)}`
            valueToCompare = Date.parse(`2023-01-01 ${nowTimeStr}`)

            // const nowTime = new Date()
            // const nowTimeStr = `${
            //   (nowTime.getHours() < 10 ? "0" : "") + nowTime.getHours()
            // }:${(nowTime.getMinutes() < 10 ? "0" : "") + nowTime.getMinutes()}`
            // valueToCompare = new Date(`2023-01-01 ${nowTimeStr}`).getTime()
          }
        }
      }

      if (valueToCompare) {
        condition?.map((item) => {
          let test = false
          let valueToCompare2 = item?.value
          if (pageAttr?.btnAddTextConditionDataFrom?.currentTime) {
            valueToCompare2 = new Date(
              `2023-01-01 ${valueToCompare2}`
            ).getTime()
          }

          if (item?.operator === "<") {
            test = valueToCompare < valueToCompare2
          } else if (item?.operator === ">") {
            test = valueToCompare > valueToCompare2
          } else if (item?.operator === "===") {
            test = valueToCompare === valueToCompare2
          } else if (item?.operator === "!==") {
            test = valueToCompare !== valueToCompare2
          }
          // console.log(item, test)
          if (test) {
            result = item
          }
        })
      }
      // console.log(result)
      return { result, storeName }
    } catch (error) {
      console.error(error)
      return rejectWithValue(error.response.data)
    }
  }
)

export const resetSelectedData = createAsyncThunk(
  `dynamicStore/resetSelectedData`,
  (storeName) => {
    return storeName
  }
)

export const setInitialDataAdd = createAsyncThunk(
  `dynamicStore/setInitialDataAdd`,
  (params) => {
    return { data: params.data, storeName: params.storeName }
  }
)

let findDeep = function (datax, key) {
  return datax.some(function (e) {
    if (
      datax?.fieldName === key &&
      datax?.rules?.required &&
      datax?.type === "file"
    ) {
      return true
    } else if (e.children) {
      return findDeep(e.children, key)
    }
  })
}

const uploadFile = (
  fileArgsList,
  callback_page,
  storeName,
  baseUrlOpt,
  endpointName,
  id,
  removeDefaultHttpHeader,
  headersExtra,
  movePathIdToPayload_PUT
) => {
  let finUrl = `${endpointName}/${id}`

  if (Object.keys(fileArgsList).length > 0) {
    let formData = new FormData()
    Object.keys(fileArgsList).forEach((fieldName) => {
      formData.append(fieldName, fileArgsList[fieldName])
    })
    formData.append("callback_page", callback_page)
    formData.append("storeName", storeName)
    if (movePathIdToPayload_PUT) {
      finUrl = `${endpointName}`
      formData.append("id", id)
    }

    getBaseUrl(
      baseUrlOpt,
      endpointName,
      removeDefaultHttpHeader
    )({
      method: "put",
      url: finUrl,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        ...(headersExtra ? headersExtra : {})
      }
    })
  }
}

export const postData = createAsyncThunk(
  `dynamicStore/postData`,
  async (data, { dispatch, getState, rejectWithValue }) => {
    const storeName = data?.storeName
    const pageAttr = getState()["dynamicStore"]?.pageAttributes[storeName]
    const pageData = getState()["dynamicStore"]?.pageData[storeName]
    const baseUrl = data?.baseUrl
    const callback_page = data?.callback_page
    const isFromModal = data?.isFromModal
    const getApiAfterPost = pageAttr?.crudOpt?.getApiAfterPost
    const gridTheme = pageAttr?.crudOpt?.gridTheme

    let endpointName = data?.endpointName?.includes("?")
      ? data?.endpointName?.split("?")[0]
      : data?.endpointName

    const customEndpoint = pageAttr?.crudOpt?.endpointName_POST
    if (customEndpoint) {
      endpointName = customEndpoint
    }

    const isTemporary = data?.isTemporary
    // console.log(isTemporary)

    try {
      Object.keys(data).forEach((key) => {
        if (data[key] === null || data[key] === "") {
          delete data[key]
        }
      })

      // const originalParams = JSON.parse(JSON.stringify(data))
      delete data.endpointName
      delete data.storeName
      delete data.baseUrl
      delete data.callback_page
      delete data.isFromModal
      delete data.isTemporary

      const formDataPayload = pageAttr?.crudOpt?.payloadType_POST === "FormData"

      let fileArgsList = {}
      if (!formDataPayload) {
        fileArgsList = Object.entries(data).reduce((acc, [key, value]) => {
          if (typeof value?.name === "string") {
            acc[key] = value

            const dynamicFormAttr = pageAttr?.crudOpt?.data?.dynamicForm

            // IF FILE IN DYNAMIC FORM
            if (findDeep(dynamicFormAttr, key)) {
              delete data[key]
            } else {
              if (key === "appLogo") {
                data.logo[key] = "uploading"
              } else {
                data[key] = "uploading"
              }
            }
          }
          return acc
        }, {})
      }

      const removeDefaultHttpHeader = pageAttr?.crudOpt?.removeDefaultHttpHeader
      const headersExtra =
        pageAttr?.crudOpt?.headersExtra_POST ?? pageAttr?.crudOpt?.headersExtra

      if (!isTemporary) {
        const baseUrlOpt = baseUrl ?? pageAttr?.crudOpt?.baseUrl
        let response = null
        if (!formDataPayload) {
          response = await getBaseUrl(
            baseUrlOpt,
            endpointName,
            removeDefaultHttpHeader
          )({
            method: "post",
            url: `${endpointName}`,
            data: data,
            ...(headersExtra ? { headers: headersExtra } : {})
          })
        } else {
          let formDataPost = new FormData()
          Object.keys(data).forEach((fieldName) => {
            formDataPost.append(fieldName, data[fieldName])
          })

          response = await getBaseUrl(
            baseUrlOpt,
            endpointName,
            removeDefaultHttpHeader
          )({
            method: "post",
            url: endpointName,
            data: formDataPost,
            headers: {
              "Content-Type": "multipart/form-data",
              ...(headersExtra ? headersExtra : {})
            }
          })
        }

        if (response) {
          const id = response?.data?.data?.id
          if (getApiAfterPost) {
            if (gridTheme === "treeview" || gridTheme === "gantt_chart") {
              dispatch(getDataList(pageData?.params))
            } else {
              // await dispatch(resetData(storeName))
              // console.log(pageData?.params)
              await dispatch(getDataList(pageData?.params))
            }
          } else {
            if (gridTheme === "treeview") {
              let oldData = JSON.parse(JSON.stringify(pageData?.data ?? []))
              const idField = pageAttr?.crudOpt?.treeviewIdField ?? "id"
              const parentIdField =
                pageAttr?.crudOpt?.treeviewParentIdField ?? "parent_id"
              const parent_ids = [
                response?.data?.data[parentIdField] ??
                  response?.data?.data[idField]
              ]
              if (oldData) {
                if (
                  response?.data?.data[parentIdField] ||
                  response?.data?.data[idField]
                ) {
                  oldData.forEach(function iter(a) {
                    if (parent_ids.includes(a[idField])) {
                      a.expanded = true
                      a?.children?.push(response.data?.data)
                      // Object.keys(response.data?.data)?.map((key) => {
                      //   a[key] = response.data?.data?.[key]
                      // })
                      // a.expanded = true
                    }
                    // eslint-disable-next-line no-unused-expressions
                    Array.isArray(a.children) && a.children.forEach(iter)
                  })

                  // JIKA DATA SEDANG DI FILTER MAKA UPDATE DATA ORIGINALNYA
                  let pageDataTempFilterTree = JSON.parse(
                    JSON.stringify(
                      getState()["dynamicStore"]?.pageDataTempFilterTree[
                        storeName
                      ] ?? []
                    )
                  )
                  if (pageDataTempFilterTree) {
                    pageDataTempFilterTree.forEach(function iter(a) {
                      if (parent_ids.includes(a[idField])) {
                        a?.children?.push(response.data?.data)
                      }
                      // eslint-disable-next-line no-unused-expressions
                      Array.isArray(a.children) && a.children.forEach(iter)
                    })
                  }

                  dispatch(
                    setDataList({
                      data: oldData,
                      storeName: storeName,
                      dataTempTree: pageDataTempFilterTree
                    })
                  )
                }
              }
            } else if (gridTheme === "table_tree") {
              await dispatch(
                addDataToChildren({
                  rowData: response.data?.data,
                  storeName: storeName,
                  isFromModal: isFromModal
                })
              )
            } else {
              if (pageData?.data) {
                await dispatch(
                  addDataById({
                    rowData: response.data?.data,
                    storeName: storeName,
                    isFromModal: isFromModal
                  })
                )
              } else {
                await dispatch(resetData(storeName))
              }
            }
          }

          if (pageAttr?.crudOpt?.getApiSummaryData) {
            dispatch(
              getSummaryData({
                endpointName: endpointName,
                storeName: storeName,
                baseUrl: baseUrlOpt
              })
            )
          }

          dispatch(resetSelectedData(storeName))
          dispatch(resetActivitiesData({}))

          if (Object.keys(fileArgsList).length > 0) {
            let finUrl = `${endpointName}/${id}`
            const movePathIdToPayload_PUT =
              pageAttr?.crudOpt?.movePathIdToPayload_PUT
            if (movePathIdToPayload_PUT) {
              finUrl = `${endpointName}`
            }
            let formData = new FormData()
            Object.keys(fileArgsList).forEach((fieldName) => {
              formData.append(fieldName, fileArgsList[fieldName])
            })
            formData.append("callback_page", callback_page)
            formData.append("storeName", storeName)
            if (movePathIdToPayload_PUT) {
              formData.append("id", id)
            }
            getBaseUrl(
              baseUrlOpt,
              endpointName,
              removeDefaultHttpHeader
            )({
              method: "put",
              url: finUrl,
              data: formData,
              headers: {
                "Content-Type": "multipart/form-data",
                ...(headersExtra ? headersExtra : {})
              }
            })
          }
        }

        return {
          storeName: storeName,
          data: !getApiAfterPost ? response?.data : {}
        }
      } else {
        await dispatch(
          addDataById({
            rowData: data,
            storeName: storeName,
            isFromModal: isFromModal
          })
        )
        return { storeName: storeName, data: data }
      }
    } catch (error) {
      // console.error(error)
      return rejectWithValue({
        storeName: storeName,
        error: error.response.data
      })
    }
  }
)

export const postDataMulti = createAsyncThunk(
  `dynamicStore/postDataMulti`,
  async (data, { dispatch, getState, rejectWithValue }) => {
    const storeName = data?.storeName
    const pageAttr = getState()["dynamicStore"]?.pageAttributes[storeName]
    const pageData = getState()["dynamicStore"]?.pageData[storeName]
    const baseUrl = data?.baseUrl
    const callback_page = data?.callback_page
    const isFromModal = data?.isFromModal
    const getApiAfterPost = pageAttr?.crudOpt?.getApiAfterPost

    const gridTheme = pageAttr?.crudOpt?.gridTheme

    let endpointName = data?.endpointName?.includes("?")
      ? data?.endpointName?.split("?")[0]
      : data?.endpointName

    const customEndpoint = pageAttr?.crudOpt?.endpointName_POST_multi
    if (customEndpoint) {
      endpointName = customEndpoint
    }

    const isTemporary = data?.isTemporary
    // console.log(isTemporary)

    try {
      // console.log("postDataMulti")
      Object.keys(data).forEach((key) => {
        if (data[key] === null || data[key] === "") {
          delete data[key]
        }
      })

      // const originalParams = JSON.parse(JSON.stringify(data))
      delete data.endpointName
      delete data.storeName
      delete data.baseUrl
      delete data.callback_page
      delete data.isFromModal
      delete data.isTemporary

      let fileArgsList = []
      let newDataCopy = { ...data }
      delete newDataCopy.repeater
      let newData = []

      data.repeater.map((repeater) => {
        Object.keys(repeater).forEach((key) => {
          if (repeater[key] === null || repeater[key] === "") {
            delete repeater[key]
          }
        })
        newData.push({ ...repeater, ...newDataCopy })
      })

      const dynamicFormAttr = pageAttr?.crudOpt?.data?.dynamicForm
      newData.map((dt, index) => {
        Object.entries(dt).reduce((acc, [key, value]) => {
          if (typeof value?.name === "string") {
            acc[key] = value
            acc["id"] = `uploading_${index}`
            acc["key"] = key
            // IF FILE IN DYNAMIC FORM
            if (findDeep(dynamicFormAttr, key)) {
              delete dt[key]
            } else {
              dt[key] = `uploading_${index}`
            }
          }
          if (acc && Object.keys(acc).length) {
            fileArgsList.push(acc)
          }
        }, {})
      })

      const baseUrlOpt = baseUrl ?? pageAttr?.crudOpt?.baseUrl
      data = newData
      let responseData = null

      const removeDefaultHttpHeader = pageAttr?.crudOpt?.removeDefaultHttpHeader
      const headersExtra =
        pageAttr?.crudOpt?.headersExtra_POST ?? pageAttr?.crudOpt?.headersExtra

      if (!isTemporary) {
        const response = await getBaseUrl(
          baseUrlOpt,
          endpointName,
          removeDefaultHttpHeader
        )({
          method: "post",
          url: `${endpointName}`,
          data: data,
          ...(headersExtra ? { headers: headersExtra } : {})
        })

        if (response?.data) {
          responseData = response?.data
        }

        if (getApiAfterPost) {
          let copyParams = JSON.parse(JSON.stringify(pageData?.params))
          if (gridTheme === "treeview" || gridTheme === "gantt_chart") {
            dispatch(getDataList(copyParams))
          } else {
            // console.log(copyParams)

            await dispatch(getDataList(copyParams)).then((c) => {
              // console.log(c)
            })
            // await dispatch(resetData(storeName))
          }
        } else {
          if (gridTheme === "treeview") {
            let oldData = JSON.parse(JSON.stringify(pageData?.data ?? []))
            const idField = pageAttr?.crudOpt?.treeviewIdField ?? "id"
            const parentIdField =
              pageAttr?.crudOpt?.treeviewParentIdField ?? "parent_id"
            const parent_ids = [
              response?.data?.data[0][parentIdField] ??
                response?.data?.data[0][idField]
            ]
            if (oldData) {
              if (
                response?.data?.data[0][parentIdField] ||
                response?.data?.data[0][idField]
              ) {
                oldData.forEach(function iter(a) {
                  if (parent_ids.includes(a[idField])) {
                    a.expanded = true
                    response?.data?.data.map((it) => {
                      a?.children?.push(it)
                    })
                  }
                  // eslint-disable-next-line no-unused-expressions
                  Array.isArray(a.children) && a.children.forEach(iter)
                })
                dispatch(
                  setDataList({
                    data: oldData,
                    storeName: storeName
                  })
                )
              }
            }
          } else {
            if (pageData?.data) {
              await dispatch(
                addDataByIdMulti({
                  rowData: !isTemporary ? responseData?.data : responseData,
                  storeName: storeName,
                  isFromModal: isFromModal
                })
              )
            } else {
              await dispatch(resetData(storeName))
            }
          }
        }

        if (pageAttr?.crudOpt?.getApiSummaryData) {
          dispatch(
            getSummaryData({
              endpointName: endpointName,
              storeName: storeName,
              baseUrl: baseUrlOpt
            })
          )
        }

        dispatch(resetSelectedData(storeName))
        dispatch(resetActivitiesData({}))

        const headersExtraPut =
          pageAttr?.crudOpt?.headersExtra_PUT ?? pageAttr?.crudOpt?.headersExtra
        const movePathIdToPayload_PUT =
          pageAttr?.crudOpt?.movePathIdToPayload_PUT
        fileArgsList.map((fileArgs) => {
          const fileKey = fileArgs["key"]
          const fileId = fileArgs["id"]
          const file = { [fileKey]: fileArgs[fileKey] }
          const id = response?.data?.data.find((a) => a[fileKey] === fileId)?.id
          if (file && id) {
            uploadFile(
              file,
              callback_page,
              storeName,
              baseUrlOpt,
              endpointName,
              id,
              removeDefaultHttpHeader,
              headersExtraPut,
              movePathIdToPayload_PUT
            )
          }
        })
      } else {
        data.forEach((obj) => {
          obj.id = Math.floor(Math.random() * 1000) // Assign random ID to the "id" key
          // obj.id_PriceQuotation = Math.floor(Math.random() * 1000) // Assign random ID value to the "id_PriceQuotation" key
        })
        responseData = data
        await dispatch(
          addDataByIdMulti({
            rowData: responseData,
            storeName: storeName,
            isFromModal: isFromModal
          })
        )
      }
      return { storeName: storeName, data: responseData }
    } catch (error) {
      console.error(error)
      return rejectWithValue({
        storeName: storeName,
        error: error.response.data
      })
    }
  }
)

const isObjectNested = (obj) => {
  for (const key in obj) {
    if (typeof obj[key] === "object" && obj[key] !== null) {
      if (isObjectNested(obj[key])) {
        return true
      }
    }
  }
  return false
}

const flattenObject = (obj) => {
  let result = {}

  const recurse = (current, parentKey = "") => {
    for (let key in current) {
      const newKey = key

      if (typeof current[key] === "object" && current[key] !== null) {
        recurse(current[key], newKey)
      } else {
        result[newKey] = current[key]
      }
    }
  }

  recurse(obj)
  return result
}

const getDifference = (a, b) => {
  return Object.fromEntries(
    Object.entries(b).filter(([key, val]) => {
      return (
        // key in a &&
        Array.isArray(a[key])
          ? JSON.stringify(a[key]).replaceAll(/null/gi, `""`) !==
              JSON.stringify(val)
          : a[key] !== val
        // && val !== ""
      )
    })
  )
}

export const putData = createAsyncThunk(
  `dynamicStore/putData`,
  async (param, { dispatch, getState, rejectWithValue }) => {
    const storeName = param?.storeName
    const pageAttr = getState()["dynamicStore"]?.pageAttributes?.[storeName]
    const pageData = getState()["dynamicStore"]?.pageData?.[storeName]
    let endpointName = param?.endpointName?.includes("?")
      ? param?.endpointName?.split("?")[0]
      : param?.endpointName

    const customEndpoint = pageAttr?.crudOpt?.endpointName_PUT
    if (customEndpoint) {
      endpointName = customEndpoint
    }

    const baseUrl = param?.baseUrl
    const baseUrlOpt = baseUrl ?? pageAttr?.crudOpt?.baseUrl
    const gridTheme = pageAttr?.crudOpt?.gridTheme

    try {
      const getApiAfterPut = pageAttr?.crudOpt?.getApiAfterPut
      const getApiAfterUpload = pageAttr?.crudOpt?.getApiAfterUpload
      const id =
        param?.dataSelected?.id ?? pageData?.selectedData?.id ?? param?.id

      const comparedDataSource = isObjectNested(
        param?.dataSelected ?? pageData.selectedData
      )
        ? flattenObject(param?.dataSelected ?? pageData.selectedData)
        : param?.dataSelected ?? pageData.selectedData
      let data = getDifference(comparedDataSource, param)
      for (let key in data) {
        if (data[key] === undefined) {
          data[key] = null
        }
      }
      // console.log(flattenObject(pageData.selectedData), param)
      // console.log(data)

      delete data.endpointName
      delete data.storeName
      delete data.baseUrl
      delete data.callback_page
      delete data.isFromModal
      delete data.isTemporary
      delete data.dataSelected

      const updateCurrentStore = param?.updateCurrentStore
      delete param?.updateCurrentStore
      delete data.updateCurrentStore

      const formDataPayload = pageAttr?.crudOpt?.payloadType_PUT === "FormData"

      let fileArgsList = {}
      if (!formDataPayload) {
        fileArgsList = Object.entries(data).reduce((acc, [key, value]) => {
          if (typeof value?.name === "string") {
            acc[key] = value
            delete data[key]
          }
          return acc
        }, {})
      }

      let response = null
      let finUrl = `${endpointName}/${id}`

      const movePathIdToPayload_PUT = pageAttr?.crudOpt?.movePathIdToPayload_PUT
      if (movePathIdToPayload_PUT) {
        finUrl = `${endpointName}`
        data["id"] = id
      }

      const removeDefaultHttpHeader = pageAttr?.crudOpt?.removeDefaultHttpHeader
      const headersExtra =
        pageAttr?.crudOpt?.headersExtra_PUT ?? pageAttr?.crudOpt?.headersExtra

      if (Object.keys(data)?.length > 0 || fileArgsList) {
        if (Object.keys(data)?.length === 0) {
          response = {
            data: pageData.selectedData
          }
        } else {
          if (!formDataPayload) {
            response = await getBaseUrl(
              baseUrlOpt,
              endpointName,
              removeDefaultHttpHeader
            )({
              method: "put",
              url: finUrl,
              data: data,
              ...(headersExtra ? { headers: headersExtra } : {})
            })
          } else {
            let formDataPut = new FormData()
            Object.keys(data).forEach((fieldName) => {
              formDataPut.append(fieldName, data[fieldName])
            })

            if (movePathIdToPayload_PUT) {
              formDataPut.append("id", id)
            }

            response = await getBaseUrl(
              baseUrlOpt,
              endpointName,
              removeDefaultHttpHeader
            )({
              method: "put",
              url: finUrl,
              data: formDataPut,
              headers: {
                "Content-Type": "multipart/form-data",
                ...(headersExtra ? headersExtra : {})
              }
            })
          }

          if (getApiAfterPut) {
            if (gridTheme === "calendar") {
              dispatch(getDataList(pageData?.params))
            } else {
              dispatch(getDataList(pageData?.params))
            }
          } else {
            let responseData = response?.data
              ? { ...data, ...(response.data?.data ?? {}) }
              : data
            if (gridTheme === "table_tree") {
              dispatch(
                setDataToChildren({
                  rowData: responseData,
                  storeName: storeName
                })
              )
            } else {
              if (updateCurrentStore !== false || !getApiAfterPut) {
                dispatch(
                  setDataById({
                    rowData: responseData,
                    storeName: storeName
                  })
                )
              }
            }
          }

          // if (pageAttr?.crudOpt?.getApiSummaryData) {
          //   dispatch(
          //     getSummaryData({
          //       endpointName: endpointName,
          //       storeName: storeName,
          //       baseUrl: baseUrlOpt
          //     })
          //   )
          // }

          dispatch(resetSelectedData(storeName))
          dispatch(resetActivitiesData({}))
        }
      }

      if (Object.keys(fileArgsList).length > 0) {
        let formData = new FormData()
        Object.keys(fileArgsList).forEach((fieldName) => {
          formData.append(fieldName, fileArgsList[fieldName])
        })
        formData.append("callback_page", param?.callback_page)
        formData.append("storeName", storeName)
        if (movePathIdToPayload_PUT) {
          formData.append("id", id)
        }

        await getBaseUrl(
          baseUrlOpt,
          endpointName,
          removeDefaultHttpHeader
        )({
          method: "put",
          url: finUrl,
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data",
            ...(headersExtra ? headersExtra : {})
          }
        })

        if (getApiAfterUpload) {
          if (gridTheme === "calendar") {
            dispatch(getDataList(pageData?.params))
          } else {
            dispatch(getDataList(pageData?.params))
            // await dispatch(resetData(storeName))
          }
        }
      }

      return {
        storeName: storeName,
        data: response?.data
          ? { ...data, ...(response.data?.data ?? {}) }
          : data
      }
    } catch (error) {
      console.error(error)
      return rejectWithValue({
        storeName: storeName,
        error: error
      })
    }
  }
)

export const putDataTree = createAsyncThunk(
  `dynamicStore/putDataTree`,
  async (data, { dispatch, getState, rejectWithValue }) => {
    const storeName = data?.storeName

    const pageAttr = getState()["dynamicStore"]?.pageAttributes[storeName]
    const pageData = getState()["dynamicStore"]?.pageData[storeName]

    const endpointName = data?.endpointName?.includes("?")
      ? data?.endpointName?.split("?")[0]
      : data?.endpointName

    const customEndpoint = pageAttr?.crudOpt?.endpointName_PUT
    if (customEndpoint) {
      endpointName = customEndpoint
    }

    const baseUrl = data?.baseUrl
    const id = data.id ?? pageData?.selectedData?.id
    const notReload = data["notReload"]
    const getApiAfterPut = pageAttr?.crudOpt?.getApiAfterPut
    const getApiSummaryData = pageAttr?.crudOpt?.getApiSummaryData
    if (notReload) {
      delete data["notReload"]
    }

    const gridTheme = pageAttr?.crudOpt?.gridTheme

    try {
      Object.keys(data).forEach((key) => {
        if (data[key] === null || data[key] === "") {
          delete data[key]
        }
      })

      delete data.endpointName
      delete data.storeName
      delete data.baseUrl
      delete data.callback_page
      delete data.isFromModal

      const baseUrlOpt = baseUrl ?? pageAttr?.crudOpt?.baseUrl

      const formDataPayload = pageAttr?.crudOpt?.payloadType_PUT === "FormData"
      let fileArgsList = {}
      if (!formDataPayload) {
        fileArgsList = Object.entries(data).reduce((acc, [key, value]) => {
          if (typeof value?.name === "string") {
            acc[key] = value
            delete data[key]
          }
          return acc
        }, {})
      }

      let response = null
      let finUrl = `${endpointName}/${id}`

      const movePathIdToPayload_PUT = pageAttr?.crudOpt?.movePathIdToPayload_PUT
      if (movePathIdToPayload_PUT) {
        finUrl = `${endpointName}`
        data["id"] = id
      }

      const removeDefaultHttpHeader = pageAttr?.crudOpt?.removeDefaultHttpHeader
      const headersExtra =
        pageAttr?.crudOpt?.headersExtra_PUT ?? pageAttr?.crudOpt?.headersExtra

      if (!formDataPayload) {
        response = await getBaseUrl(
          baseUrlOpt,
          endpointName,
          removeDefaultHttpHeader
        )({
          method: "put",
          url: finUrl,
          data: data,
          ...(headersExtra ? { headers: headersExtra } : {})
        })
      } else {
        let formDataPut = new FormData()
        Object.keys(data).forEach((fieldName) => {
          formDataPut.append(fieldName, data[fieldName])
        })

        if (movePathIdToPayload_PUT) {
          formDataPut.append("id", id)
        }

        response = await getBaseUrl(
          baseUrlOpt,
          endpointName,
          removeDefaultHttpHeader
        )({
          method: "put",
          url: finUrl,
          data: formDataPut,
          headers: {
            "Content-Type": "multipart/form-data",
            ...(headersExtra ? headersExtra : {})
          }
        })
      }

      // console.log(notReload)
      if (getApiAfterPut && !notReload) {
        if (gridTheme === "treeview" || gridTheme === "gantt_chart") {
          dispatch(getDataList(pageData?.params))
        } else {
          await dispatch(resetData(storeName))
        }
      } else {
        if (gridTheme === "treeview") {
          let oldData = JSON.parse(JSON.stringify(pageData?.data ?? []))

          const idField = pageAttr?.crudOpt?.treeviewIdField ?? "id"
          const parentIdField =
            pageAttr?.crudOpt?.treeviewParentIdField ?? "parent_id"

          const idTree = data?.[idField] ?? pageData?.selectedData?.[idField]
          const parentIdTree =
            data?.[parentIdField] ?? pageData?.selectedData?.[parentIdField]
          const ids = [idTree]
          if (oldData && ids) {
            // oldData.forEach(function iter(a) {
            //   if (ids.includes(a?.[idField])) {
            //     Object.keys(response.data?.data)?.map((key) => {
            //       a[key] = response.data?.data?.[key]
            //     })
            //   }
            //   // eslint-disable-next-line no-unused-expressions
            //   Array.isArray(a.children) && a.children.forEach(iter)
            // })
            // console.log(idTree, response.data?.data, oldData[0])

            let oldDataCopy = JSON.parse(JSON.stringify(oldData))
            let respCopy = JSON.parse(JSON.stringify(response.data?.data))

            let flatArray = flattenArray(oldDataCopy, idField, parentIdField)
            let flatArrayCopy = JSON.parse(JSON.stringify(flatArray))

            const indexTarget = flatArrayCopy.findIndex(
              (obj) => obj.id === idTree
            )

            if (indexTarget !== -1) {
              flatArrayCopy[indexTarget] = respCopy
              flatArrayCopy[indexTarget]["expanded"] = true
            }

            // console.log(flatArrayCopy)
            // if (
            //   flatArrayCopy.length > 0 &&
            //   flatArrayCopy[0]?.[parentIdField] !== null
            // ) {
            //   flatArrayCopy[0][parentIdField] = null
            // }

            let updatedNestedObject = arrayToNested(
              flatArrayCopy,
              idField,
              parentIdField
            )
            // console.log(updatedNestedObject)

            // JIKA DATA SEDANG DI FILTER MAKA UPDATE DATA ORIGINALNYA
            let dataTempTree
            const pageDataTempFilterTree =
              getState()["dynamicStore"]?.pageDataTempFilterTree[storeName]
            if (pageDataTempFilterTree) {
              let flatArray2 = flattenArray(
                pageDataTempFilterTree,
                idField,
                parentIdField
              )
              let flatArrayCopy2 = JSON.parse(JSON.stringify(flatArray2))

              const indexTarget = flatArrayCopy2.findIndex(
                (obj) => obj.id === idTree
              )

              if (indexTarget !== -1) {
                flatArrayCopy2[indexTarget] = respCopy
              }

              let updatedNestedObject2 = arrayToNested(
                flatArrayCopy2,
                idField,
                parentIdField
              )
              // console.log(updatedNestedObject2)
              dataTempTree = [updatedNestedObject2]
            }

            dispatch(
              setDataList({
                data: [updatedNestedObject],
                storeName: storeName,
                dataTempTree: dataTempTree
              })
            )
          }
        } else {
          dispatch(
            setDataById({ rowData: response.data?.data, storeName: storeName })
          )
        }
      }

      if (Object.keys(fileArgsList).length > 0) {
        let formData = new FormData()
        Object.keys(fileArgsList).forEach((fieldName) => {
          formData.append(fieldName, fileArgsList[fieldName])
        })
        formData.append("callback_page", data?.callback_page)
        formData.append("storeName", storeName)

        getBaseUrl(
          baseUrlOpt,
          endpointName
        )({
          method: "put",
          url: finUrl,
          data: formData,
          headers: { "Content-Type": "multipart/form-data" }
        })
      }

      await dispatch(resetSelectedData(storeName))
      dispatch(resetActivitiesData({}))
      return { storeName: storeName, data: response?.data }
    } catch (error) {
      return rejectWithValue({
        storeName: storeName,
        error: error
      })
    }
  }
)

export const putDataMulti = createAsyncThunk(
  `dynamicStore/putDataMulti`,
  async (param, { dispatch, getState, rejectWithValue }) => {
    const storeName = param?.storeName
    const pageAttr = getState()["dynamicStore"]?.pageAttributes?.[storeName]
    const pageData = getState()["dynamicStore"]?.pageData?.[storeName]
    let endpointName = param?.endpointName?.includes("?")
      ? param?.endpointName?.split("?")[0]
      : param?.endpointName

    const customEndpoint = pageAttr?.crudOpt?.endpointName_PUT
    if (customEndpoint) {
      endpointName = customEndpoint
    }

    const baseUrl = param?.baseUrl
    const baseUrlOpt = baseUrl ?? pageAttr?.crudOpt?.baseUrl
    const gridTheme = pageAttr?.crudOpt?.gridTheme

    try {
      const getApiAfterPut = pageAttr?.crudOpt?.getApiAfterPut
      const getApiAfterUpload = pageAttr?.crudOpt?.getApiAfterUpload

      let data = param?.data

      delete data.endpointName
      delete data.storeName
      delete data.baseUrl
      delete data.callback_page
      delete data.isFromModal
      delete data.isTemporary

      const updateCurrentStore = param?.updateCurrentStore
      delete param?.updateCurrentStore
      delete data.updateCurrentStore

      const formDataPayload = pageAttr?.crudOpt?.payloadType_PUT === "FormData"

      let fileArgsList = {}
      if (!formDataPayload) {
        fileArgsList = Object.entries(data).reduce((acc, [key, value]) => {
          if (typeof value?.name === "string") {
            acc[key] = value
            delete data[key]
          }
          return acc
        }, {})
      }

      let response = null
      let finUrl = `${endpointName}`

      const removeDefaultHttpHeader = pageAttr?.crudOpt?.removeDefaultHttpHeader
      const headersExtra =
        pageAttr?.crudOpt?.headersExtra_PUT ?? pageAttr?.crudOpt?.headersExtra

      if (Object.keys(data)?.length > 0 || fileArgsList) {
        if (Object.keys(data)?.length === 0) {
          response = {
            data: pageData.selectedData
          }
        } else {
          if (!formDataPayload) {
            response = await getBaseUrl(
              baseUrlOpt,
              endpointName,
              removeDefaultHttpHeader
            )({
              method: "put",
              url: finUrl,
              data: data,
              ...(headersExtra ? { headers: headersExtra } : {})
            })
          } else {
            let formDataPut = new FormData()
            Object.keys(data).forEach((fieldName) => {
              formDataPut.append(fieldName, data[fieldName])
            })

            if (movePathIdToPayload_PUT) {
              formDataPut.append("id", id)
            }

            response = await getBaseUrl(
              baseUrlOpt,
              endpointName,
              removeDefaultHttpHeader
            )({
              method: "put",
              url: finUrl,
              data: formDataPut,
              headers: {
                "Content-Type": "multipart/form-data",
                ...(headersExtra ? headersExtra : {})
              }
            })
          }

          if (getApiAfterPut) {
            if (gridTheme === "calendar") {
              dispatch(getDataList(pageData?.params))
            } else {
              dispatch(getDataList(pageData?.params))
            }
          } else {
            let responseData = response?.data
              ? [...data, ...(response.data?.data ?? [])]
              : data
            if (gridTheme === "table_tree") {
              dispatch(
                setDataToChildren({
                  rowData: responseData,
                  storeName: storeName
                })
              )
            } else {
              if (updateCurrentStore !== false || !getApiAfterPut) {
                dispatch(
                  setDataById({
                    rowData: responseData,
                    storeName: storeName
                  })
                )
              }
            }
          }

          // if (pageAttr?.crudOpt?.getApiSummaryData) {
          //   dispatch(
          //     getSummaryData({
          //       endpointName: endpointName,
          //       storeName: storeName,
          //       baseUrl: baseUrlOpt
          //     })
          //   )
          // }

          dispatch(resetSelectedData(storeName))
          dispatch(resetActivitiesData({}))
        }
      }

      if (Object.keys(fileArgsList).length > 0) {
        let formData = new FormData()
        Object.keys(fileArgsList).forEach((fieldName) => {
          formData.append(fieldName, fileArgsList[fieldName])
        })
        formData.append("callback_page", param?.callback_page)
        formData.append("storeName", storeName)
        if (movePathIdToPayload_PUT) {
          formData.append("id", id)
        }

        await getBaseUrl(
          baseUrlOpt,
          endpointName,
          removeDefaultHttpHeader
        )({
          method: "put",
          url: finUrl,
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data",
            ...(headersExtra ? headersExtra : {})
          }
        })

        if (getApiAfterUpload) {
          if (gridTheme === "calendar") {
            dispatch(getDataList(pageData?.params))
          } else {
            dispatch(getDataList(pageData?.params))
            // await dispatch(resetData(storeName))
          }
        }
      }

      return {
        storeName: storeName,
        data: response?.data ? [...data, ...(response.data?.data ?? [])] : data
      }
    } catch (error) {
      console.error(error)
      return rejectWithValue({
        storeName: storeName,
        error: error
      })
    }
  }
)

export const setViewOnlyTree = createAsyncThunk(
  `dynamicStore/setViewOnlyTree`,
  async (data, { dispatch, getState, rejectWithValue }) => {
    const storeName = data?.storeName
    try {
      return { storeName: storeName, data: data.data }
    } catch (error) {
      return rejectWithValue({
        storeName: storeName,
        error: error
      })
    }
  }
)

export const setOrgChartData = createAsyncThunk(
  `dynamicStore/setOrgChartData`,
  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      return data
    } catch (error) {
      return rejectWithValue({
        storeName: storeName,
        error: error
      })
    }
  }
)

export const lazyLoadByParent = createAsyncThunk(
  `dynamicStore/lazyLoadByParent`,
  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      let dataResponse
      await dispatch(
        getDataList({
          storeName: data?.storeName,
          endpointName: data?.endpointName,
          [data?.parentIdField ?? "parent_id"]: data.currentId,
          nodeMode: true
        })
      ).then((res) => {
        // console.log(res?.payload?.data)
        dataResponse = res?.payload?.data
      })
      return { ...data, data: dataResponse }
    } catch (error) {
      return rejectWithValue({
        storeName: storeName,
        error: error
      })
    }
  }
)

export const setOrgChartDataProp = createAsyncThunk(
  `dynamicStore/setOrgChartDataProp`,
  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      return { storeName: data.storeName, data: data.data }
    } catch (error) {
      return rejectWithValue({
        storeName: storeName,
        error: error
      })
    }
  }
)

export const setOrgChartData2 = createAsyncThunk(
  `dynamicStore/setOrgChartData2`,
  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      return data
    } catch (error) {
      return rejectWithValue({
        storeName: storeName,
        error: error
      })
    }
  }
)

export const updateOrgChartData = createAsyncThunk(
  `dynamicStore/updateOrgChartData`,
  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      return data
    } catch (error) {
      return rejectWithValue({
        storeName: storeName,
        error: error
      })
    }
  }
)

export const expandCollapseAllTree = createAsyncThunk(
  `dynamicStore/expandCollapseAllTree`,
  async (data, { dispatch, getState, rejectWithValue }) => {
    const storeName = data?.storeName

    const pageAttr = getState()["dynamicStore"]?.pageAttributes[storeName]
    const pageData = getState()["dynamicStore"]?.pageData[storeName]
    let oldDataCopy = JSON.parse(JSON.stringify(pageData?.data))
    try {
      if (oldDataCopy?.length > 1) {
        let updatedNestedObject = []
        oldDataCopy?.forEach((objItem) => {
          const newItem = updateExpandedToFalse(objItem, data.value)
          updatedNestedObject.push(newItem)
        })
        return { storeName: storeName, data: updatedNestedObject }
      } else {
        let updatedNestedObject = updateExpandedToFalse(
          oldDataCopy[0],
          data.value
        )
        // console.log(updatedNestedObject)
        return { storeName: storeName, data: [updatedNestedObject] }
      }
    } catch (error) {
      console.error(error)
      return rejectWithValue({
        storeName: storeName,
        error: error
      })
    }
  }
)

export const deleteData = createAsyncThunk(
  `dynamicStore/deleteData`,
  async (param, { dispatch, getState, rejectWithValue }) => {
    const storeName = param?.storeName
    const pageAttr = getState()["dynamicStore"]?.pageAttributes[storeName]
    const pageData = getState()["dynamicStore"]?.pageData[storeName]

    const baseUrl = param?.baseUrl
    const baseUrlOpt = baseUrl ?? pageAttr?.crudOpt?.baseUrl

    let endpointName = param?.endpointName?.includes("?")
      ? param?.endpointName?.split("?")[0]
      : param?.endpointName

    const customEndpoint = pageAttr?.crudOpt?.endpointName_DELETE
    if (customEndpoint) {
      endpointName = customEndpoint
    }

    const removeDefaultHttpHeader = pageAttr?.crudOpt?.removeDefaultHttpHeader
    const headersExtra =
      pageAttr?.crudOpt?.headersExtra_DELETE ?? pageAttr?.crudOpt?.headersExtra

    try {
      const getApiAfterDelete = pageAttr?.crudOpt?.getApiAfterDelete
      const id = param?.id

      const gridTheme = pageAttr?.crudOpt?.gridTheme

      let finUrl = `${endpointName}/${id}`
      const movePathIdToPayload_DELETE =
        pageAttr?.crudOpt?.movePathIdToPayload_DELETE
      if (movePathIdToPayload_DELETE) {
        finUrl = `${endpointName}`
      }
      const formDataPayload =
        pageAttr?.crudOpt?.payloadType_DELETE === "FormData"

      if (getApiAfterDelete) {
        let response = null
        if (movePathIdToPayload_DELETE) {
          if (!formDataPayload) {
            response = await getBaseUrl(
              baseUrlOpt,
              endpointName,
              removeDefaultHttpHeader
            )({
              method: "delete",
              url: finUrl,
              data: {
                id: id
              },
              ...(headersExtra ? { headers: headersExtra } : {})
            })
          } else {
            let formData = new FormData()
            formData.append("id", id)

            response = await getBaseUrl(
              baseUrlOpt,
              endpointName,
              removeDefaultHttpHeader
            )({
              method: "delete",
              url: finUrl,
              data: formData,
              headers: {
                "Content-Type": "multipart/form-data",
                ...(headersExtra ? headersExtra : {})
              }
            })
          }

          // response = await getBaseUrl(baseUrlOpt, endpointName, removeDefaultHttpHeader).delete(finUrl, {
          //   data: {
          //     id: id
          //   }
          // })
        } else {
          response = await getBaseUrl(
            baseUrlOpt,
            endpointName,
            removeDefaultHttpHeader
          ).delete(finUrl)
        }
        if (gridTheme === "treeview") {
          dispatch(getDataList(pageData?.params))
        } else {
          await dispatch(getDataList(pageData?.params))
          // await dispatch(resetData(storeName))
        }

        if (pageAttr?.crudOpt?.getApiSummaryData) {
          dispatch(
            getSummaryData({
              endpointName: endpointName,
              storeName: storeName,
              baseUrl: baseUrlOpt
            })
          )
        }

        dispatch(resetActivitiesData({}))
        return { storeName: storeName, data: response?.data }
      } else {
        if (gridTheme === "table_tree") {
          dispatch(removeDataFromChildren({ id, storeName }))
        } else {
          dispatch(removeDataById({ id, storeName }))
        }

        let response = null
        if (movePathIdToPayload_DELETE) {
          if (!formDataPayload) {
            response = await getBaseUrl(
              baseUrlOpt,
              endpointName,
              removeDefaultHttpHeader
            )({
              method: "delete",
              url: finUrl,
              data: {
                id: id
              },
              ...(headersExtra ? { headers: headersExtra } : {})
            })
          } else {
            let formData = new FormData()
            formData.append("id", id)

            response = await getBaseUrl(
              baseUrlOpt,
              endpointName,
              removeDefaultHttpHeader
            )({
              method: "delete",
              url: finUrl,
              data: formData,
              headers: {
                "Content-Type": "multipart/form-data",
                ...(headersExtra ? headersExtra : {})
              }
            })
          }

          // response = await getBaseUrl(baseUrlOpt, endpointName,
          //   removeDefaultHttpHeader).delete(finUrl, {
          //   data: {
          //     id: id
          //   }
          // })
        } else {
          response = await getBaseUrl(
            baseUrlOpt,
            endpointName,
            removeDefaultHttpHeader
          )({
            method: "delete",
            url: finUrl,
            ...(headersExtra ? { headers: headersExtra } : {})
          })
        }

        if (pageAttr?.crudOpt?.getApiSummaryData) {
          dispatch(
            getSummaryData({
              endpointName: endpointName,
              storeName: storeName,
              baseUrl: baseUrlOpt
            })
          )
        }

        dispatch(resetActivitiesData({}))
        return { storeName: storeName, data: response?.data }
      }
    } catch (error) {
      return rejectWithValue({
        storeName: storeName,
        error: error.response.data
      })
    }
  }
)

export const deleteSelectedRowData = createAsyncThunk(
  `dynamicStore/deleteSelectedRowData`,
  async (data, { dispatch, getState, rejectWithValue }) => {
    const storeName = data?.storeName
    const pageAttr = getState()["dynamicStore"]?.pageAttributes[storeName]
    const baseUrl = data?.baseUrl ?? pageAttr?.crudOpt?.baseUrl

    let endpointName = data?.endpointName?.includes("?")
      ? data?.endpointName?.split("?")[0]
      : data?.endpointName

    const customEndpoint = pageAttr?.crudOpt?.endpointName_DELETE_multi
    if (customEndpoint) {
      endpointName = customEndpoint
    }

    const removeDefaultHttpHeader = pageAttr?.crudOpt?.removeDefaultHttpHeader
    const headersExtra =
      pageAttr?.crudOpt?.headersExtra_DELETE ?? pageAttr?.crudOpt?.headersExtra
    const formDataPayload = pageAttr?.crudOpt?.payloadType_DELETE === "FormData"
    try {
      const getApiAfterDelete = pageAttr?.crudOpt?.getApiAfterDelete
      const id = data?.id
      const baseUrlOpt = baseUrl ?? pageAttr?.baseUrl
      let response = null
      if (!formDataPayload) {
        response = await getBaseUrl(
          baseUrlOpt,
          endpointName,
          removeDefaultHttpHeader
        )({
          method: "delete",
          url: `${endpointName}`,
          data: {
            id: id
          },
          ...(headersExtra ? { headers: headersExtra } : {})
        })
      } else {
        let formData = new FormData()
        formData.append("id", id)

        response = await getBaseUrl(
          baseUrlOpt,
          endpointName,
          removeDefaultHttpHeader
        )({
          method: "delete",
          url: endpointName,
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data",
            ...(headersExtra ? headersExtra : {})
          }
        })
      }

      if (getApiAfterDelete) {
        await dispatch(resetData(storeName))
      } else {
        dispatch(removeDataById({ id, storeName }))
      }

      if (pageAttr?.crudOpt?.getApiSummaryData) {
        dispatch(
          getSummaryData({
            endpointName: endpointName,
            storeName: storeName,
            baseUrl: baseUrlOpt
          })
        )
      }

      dispatch(resetActivitiesData({}))
      return { storeName: storeName, data: response?.data }
    } catch (error) {
      return rejectWithValue({
        storeName: storeName,
        error: error.response.data
      })
    }
  }
)

export const updateSelectedRowData = createAsyncThunk(
  `dynamicStore/updateSelectedRowData`,
  async (data, { dispatch, getState, rejectWithValue }) => {
    const storeName = data?.storeName
    const pageAttr = getState()["dynamicStore"]?.pageAttributes[storeName]
    const baseUrl = data?.baseUrl ?? pageAttr?.crudOpt?.baseUrl

    let endpointName = data?.endpointName?.includes("?")
      ? data?.endpointName?.split("?")[0]
      : data?.endpointName

    const customEndpoint = pageAttr?.crudOpt?.endpointName_DELETE_multi
    if (customEndpoint) {
      endpointName = customEndpoint
    }

    const removeDefaultHttpHeader = pageAttr?.crudOpt?.removeDefaultHttpHeader
    const headersExtra =
      pageAttr?.crudOpt?.headersExtra_DELETE ?? pageAttr?.crudOpt?.headersExtra
    const formDataPayload = pageAttr?.crudOpt?.payloadType_DELETE === "FormData"
    try {
      const getApiAfterDelete = pageAttr?.crudOpt?.getApiAfterDelete
      const id = data?.id
      const baseUrlOpt = baseUrl ?? pageAttr?.baseUrl
      let response = null
      if (!formDataPayload) {
        response = await getBaseUrl(
          baseUrlOpt,
          endpointName,
          removeDefaultHttpHeader
        )({
          method: "delete",
          url: `${endpointName}`,
          data: {
            id: id
          },
          ...(headersExtra ? { headers: headersExtra } : {})
        })
      } else {
        let formData = new FormData()
        formData.append("id", id)

        response = await getBaseUrl(
          baseUrlOpt,
          endpointName,
          removeDefaultHttpHeader
        )({
          method: "delete",
          url: endpointName,
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data",
            ...(headersExtra ? headersExtra : {})
          }
        })
      }

      if (getApiAfterDelete) {
        await dispatch(resetData(storeName))
      } else {
        dispatch(removeDataById({ id, storeName }))
      }

      if (pageAttr?.crudOpt?.getApiSummaryData) {
        dispatch(
          getSummaryData({
            endpointName: endpointName,
            storeName: storeName,
            baseUrl: baseUrlOpt
          })
        )
      }

      dispatch(resetActivitiesData({}))
      return { storeName: storeName, data: response?.data }
    } catch (error) {
      return rejectWithValue({
        storeName: storeName,
        error: error.response.data
      })
    }
  }
)

export const setParamsNoServerSide = createAsyncThunk(
  `dynamicStore/setParamsNoServerSide`,
  async (params) => {
    return params
  }
)

export const resetData = createAsyncThunk(
  `dynamicStore/resetData`,
  async (storeName) => {
    return storeName
  }
)

export const addDataById = createAsyncThunk(
  `dynamicStore/addDataById`,
  async ({ rowData, storeName, isFromModal }) => {
    return { data: rowData, storeName: storeName, isFromModal: isFromModal }
  }
)

/////////////CHILDREN START////////////
export const addDataToChildren = createAsyncThunk(
  `dynamicStore/addDataToChildren`,
  async ({ rowData, storeName, isFromModal }) => {
    return { data: rowData, storeName: storeName, isFromModal: isFromModal }
  }
)

export const removeDataFromChildren = createAsyncThunk(
  `dynamicStore/removeDataFromChildren`,
  async (param) => {
    return param
  }
)

export const setDataToChildren = createAsyncThunk(
  `dynamicStore/setDataToChildren`,
  async ({ rowData, storeName }) => {
    return { data: rowData, storeName: storeName }
  }
)

/////////////CHILDREN END////////////

export const addDataByIdMulti = createAsyncThunk(
  `dynamicStore/addDataByIdMulti`,
  async ({ rowData, storeName, isFromModal }) => {
    return { data: rowData, storeName: storeName, isFromModal: isFromModal }
  }
)

export const removeDataById = createAsyncThunk(
  `dynamicStore/removeDataById`,
  async (param) => {
    return param
  }
)

export const setDataById = createAsyncThunk(
  `dynamicStore/setDataById`,
  async ({ rowData, storeName }) => {
    // console.log(rowData)
    return { data: rowData, storeName: storeName }
  }
)

export const filterCurrentData = createAsyncThunk(
  `dynamicStore/filterCurrentData`,
  async ({ data, storeName }) => {
    return { data: data, storeName: storeName }
  }
)

export const setDataListFiltered = createAsyncThunk(
  `dynamicStore/setDataListFiltered`,
  async ({ data, storeName, params }) => {
    return { data: data, storeName: storeName, params: params }
  }
)

export const resetDataListFiltered = createAsyncThunk(
  `dynamicStore/resetDataListFiltered`,
  async ({ storeName }) => {
    return { storeName: storeName }
  }
)

export const updateCurrentdata = createAsyncThunk(
  `dynamicStore/updateCurrentdata`,
  async (putData, { rejectWithValue }) => {
    try {
      // dispatch(resetActivitiesData({}))
      return putData
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const putDataWidget = createAsyncThunk(
  `dynamicStore/putDataWidget`,
  async (param, { dispatch, getState, rejectWithValue }) => {
    const endpointName = param?.endpointName
    const storeName = param?.storeName
    const baseUrl = param?.baseUrl

    try {
      let response = null
      delete param?.endpointName
      delete param?.storeName
      delete param?.baseUrl

      if (Object.keys(param)?.length > 0) {
        const baseUrlOpt =
          baseUrl ??
          getState()["dynamicStore"]?.pageAttributes[storeName]?.crudOpt
            ?.baseUrl
        response = await getBaseUrl(baseUrlOpt, endpointName).put(
          `${endpointName}`,
          param
        )
      }
      return { storeName: storeName, data: response?.data }
    } catch (error) {
      return rejectWithValue({
        storeName: storeName,
        error: error
      })
    }
  }
)

export const postDataWidget = createAsyncThunk(
  `dynamicStore/postDataWidget`,
  async (param, { dispatch, getState, rejectWithValue }) => {
    const endpointName = param?.endpointName?.includes("?")
      ? param?.endpointName?.split("?")[0]
      : param?.endpointName
    const storeName = param?.storeName
    const baseUrl = param?.baseUrl

    try {
      let response = null
      delete param?.endpointName
      delete param?.storeName
      delete param?.baseUrl

      if (Object.keys(param)?.length > 0) {
        const baseUrlOpt =
          baseUrl ??
          getState()["dynamicStore"]?.pageAttributes[storeName]?.crudOpt
            ?.baseUrl
        response = await getBaseUrl(baseUrlOpt, endpointName).post(
          `${endpointName}`,
          param
        )
      }
      return { storeName: storeName, data: response?.data }
    } catch (error) {
      return rejectWithValue({
        storeName: storeName,
        error: error
      })
    }
  }
)

////FOR TREE MODE
export const setDataList = createAsyncThunk(
  `dynamicStore/setDataList`,
  async (rowData, { rejectWithValue }) => {
    return rowData
  }
)

export const setDynamicFiltersData = createAsyncThunk(
  `dynamicStore/setDynamicFiltersData`,
  async (rowData, { rejectWithValue }) => {
    return rowData
  }
)

export const setModalImport = createAsyncThunk(
  `dynamicStore/setModalImport`,
  async (rowdata, { rejectWithValue }) => {
    return { rowdata }
  }
)

function transformToTargetFormat(data, crudOpt) {
  // console.log(data, crudOpt)
  const idField = crudOpt?.treeviewIdField ?? "id"
  const parentIdField = crudOpt?.treeviewParentIdField ?? "parent_id"
  const titleField = crudOpt?.treeviewTitleField ?? "title" ?? "name"
  const codeField = crudOpt?.treeviewAddSubIncrementField
  const indexField = crudOpt?.treeviewIndexField ?? "index" ?? "order"
  const iconField = crudOpt?.treeviewIconField ?? "icon"

  const result = {
    class: "go.GraphLinksModel",
    nodeDataArray: [],
    linkDataArray: []
  }
  const idMap = {}

  for (const item of data) {
    const key = item[idField].toString()
    // const key = item[idField]
    const parent = item[parentIdField]
    const parentKey =
      item[parentIdField] !== null ? item[parentIdField].toString() : undefined

    // const childNodes = item.filter((n) => n[parentIdField] === node.key)
    // console.log(item)

    const node = {
      ...item,
      key: key,
      // parent: parent,
      name: item[titleField],
      title: item[titleField],
      __level__: null
    }

    result.nodeDataArray.push(node)

    if (parentKey !== undefined) {
      const link = {
        from: parentKey,
        to: key
      }
      result.linkDataArray.push(link)
    }

    idMap[key] = node
  }

  return result
}

function getMaxLevel(data, currentLevel = 1) {
  let maxLevel = currentLevel
  if (Array.isArray(data.children) && data.children.length > 0) {
    data.children.forEach((child) => {
      const childMaxLevel = getMaxLevel(child, currentLevel + 1)
      maxLevel = Math.max(maxLevel, childMaxLevel)
    })
  }
  return maxLevel
}

const fetchStatus = "idle" | "loading" | "succeeded" | "failed"

export const StoreSlice = createSlice({
  name: "dynamicStore",
  initialState: {
    // PAGE-ATTRIBUTES
    pageAttributes: {},

    // MIXED
    pageData: {},
    pageDataTemp: {},
    pageDataTempHeader: {},
    pageDataTempFilterTree: {},
    pageDataTempFilterTreeCurrent: {},
    // SUMMARY
    statusSummary: fetchStatus,
    isLoadingSummary: false,
    errorSummary: {},
    dataSummary: [],

    // ALL
    status: {},
    isLoading: false,
    isLoadingNode: {},
    error: {},
    data: {},
    dataOld: {},
    isTemporaryData: [],
    total: 1,
    filteredData: null,

    params: {},
    selectedData: {},
    expandedData: {},
    tableActiveTabs: {},
    expandedDataComponent: {},
    dataDropdown: {},

    currentExpandedDataGlobal: {},

    // DETAIL
    statusDetail: fetchStatus,
    isLoadingDetail: false,
    errorDetail: "" | null,

    // ADD&EDIT
    statusAddEdit: fetchStatus,
    isLoadingAddEdit: false,
    errorAddEdit: "" | null,

    // ADD&EDIT
    statusDelete: fetchStatus,
    isLoadingDelete: false,
    errorDelete: "" | null,

    // IFRAME DATA
    dataFrame: {},
    isLoadingFrame: false,
    dataFrameHtml: {},
    dataFrameHtmlModal: {},
    isLoadingFrameHtml: false,
    dataFrameLoading: {},

    ////MODAL IMPORT
    showModalImport: {},

    ////MAP_OPTIONS
    mapOptions: {}
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      ///SET MAP OPTIONS
      .addCase(setMapOptions.fulfilled, (state, action) => {
        let mapOptionsCopy = { ...state.mapOptions }
        mapOptionsCopy[action.payload.storeName] = action.payload.data
        state.mapOptions = mapOptionsCopy
      })

      ///SET IFRAME DATA
      .addCase(setDataFrame.fulfilled, (state, action) => {
        let dataFrameCopy = { ...state.dataFrame }
        dataFrameCopy[action.payload.storeName] = action.payload.data
        state.dataFrame = dataFrameCopy
      })

      .addCase(setDataFrameLoading.fulfilled, (state, action) => {
        let dataFrameCopy = { ...state.dataFrameLoading }
        // console.log(action.payload.data)
        dataFrameCopy[action.payload.storeName] = action.payload.data
        state.dataFrameLoading = dataFrameCopy
        // console.log(state.dataFrameHtml)
      })

      ///SET IFRAME HTML DATA
      .addCase(setDataFrameHtml.fulfilled, (state, action) => {
        let dataFrameCopy = { ...state.dataFrameHtml }
        dataFrameCopy[action.payload.storeName] = action.payload.data
        state.dataFrameHtml = dataFrameCopy
        // console.log(state.dataFrameHtml)
      })

      ///SET IFRAME HTML DATA
      .addCase(setDataFrameHtmlModal.fulfilled, (state, action) => {
        let dataFrameCopy = { ...state.dataFrameHtmlModal }
        dataFrameCopy[action.payload.storeName] = action.payload.data
        state.dataFrameHtmlModal = dataFrameCopy
        // console.log(state.dataFrameHtml)
      })

      ///INIT PAGE ATTR
      .addCase(setStorePageAttributes.fulfilled, (state, action) => {
        // console.log(action)
        // console.log(state.pageAttributes)
        let pageAttributesCopy = { ...state.pageAttributes }
        pageAttributesCopy[action.payload.storeName] = action.payload.data
        state.pageAttributes = pageAttributesCopy
      })

      ///SUMMARY
      .addCase(getSummaryData.pending, (state, action) => {
        let pageDataCopy = { ...state.pageData }
        if (pageDataCopy[action.meta?.arg?.storeName]) {
          pageDataCopy[action.meta?.arg?.storeName]["statusSummary"] = "loading"
          pageDataCopy[action.meta?.arg?.storeName]["isLoadingSummary"] = true
          pageDataCopy[action.meta?.arg?.storeName]["dataSummary"] = []
        } else {
          pageDataCopy[action.meta?.arg?.storeName] = {
            statusSummary: "loading",
            isLoadingSummary: true
          }
        }
        state.pageData = pageDataCopy
      })
      .addCase(getSummaryData.fulfilled, (state, action) => {
        // console.log(action.payload)
        let pageDataCopy = { ...state.pageData }
        // pageDataCopy[action.payload?.storeName] = {
        //   ...pageDataCopy[action.payload?.storeName],
        //   ...{
        //     isLoadingSummary: false,
        //     statusSummary: "succeeded",
        //     dataSummary: action.payload.data
        //   }
        // }
        if (pageDataCopy[action.payload?.storeName]) {
          pageDataCopy[action.payload?.storeName]["statusSummary"] = "succeeded"
          pageDataCopy[action.payload?.storeName]["isLoadingSummary"] = false
          pageDataCopy[action.payload?.storeName]["dataSummary"] =
            action.payload.data
        } else {
          pageDataCopy[action.payload?.storeName] = {
            statusSummary: "succeeded",
            isLoadingSummary: false,
            dataSummary: action.payload.data
          }
        }
        state.pageData = pageDataCopy
      })
      .addCase(getSummaryData.rejected, (state, action) => {
        // console.log(action)
        let pageDataCopy = { ...state.pageData }
        // pageDataCopy[action.payload?.storeName] = {
        //   ...pageDataCopy[action.payload?.storeName],
        //   ...{
        //     isLoadingSummary: false,
        //     statusSummary: "failed",
        //     dataSummary: [],
        //     errorSummary: action.error.message
        //   }
        // }
        if (pageDataCopy[action.payload?.storeName]) {
          pageDataCopy[action.payload?.storeName]["statusSummary"] = "failed"
          pageDataCopy[action.payload?.storeName]["isLoadingSummary"] = false
          pageDataCopy[action.payload?.storeName]["dataSummary"] =
            action.payload?.data
        } else {
          pageDataCopy[action.payload?.storeName] = {
            statusSummary: "failed",
            isLoadingSummary: false,
            dataSummary: action.payload?.data
          }
        }
        state.pageData = pageDataCopy
      })

      ///ALL
      .addCase(getDataList.pending, (state, action) => {
        // console.log(action)
        if (!action.meta?.arg?.nodeMode) {
          let pageDataCopy = { ...state.pageData }
          let storeName =
            action.meta?.arg?.storeName ?? action.meta?.arg?.params?.storeName

          if (pageDataCopy[storeName]) {
            pageDataCopy[storeName]["status"] = "loading"
            pageDataCopy[storeName]["isLoading"] = true
            // pageDataCopy[action.meta?.arg?.storeName]["data"] = []
          } else {
            pageDataCopy[storeName] = {
              status: "loading",
              isLoading: true
            }
          }
          // console.log(pageDataCopy)
          state.pageData = pageDataCopy
        }
      })
      .addCase(getDataList.fulfilled, (state, action) => {
        if (!action.meta?.arg?.nodeMode) {
          // console.log(action.payload.data)
          let pageDataCopy = { ...state.pageData }
          const crudOpt =
            state.pageAttributes[action.payload?.params?.storeName]?.crudOpt
          if (crudOpt?.gridTheme === "timeline") {
            action.payload.data.sort(
              (a, b) =>
                b[crudOpt.timeLineDateField] - a[crudOpt.timeLineDateField]
            )
          }

          let dataNew = action.payload.data
          if (
            crudOpt?.gridTheme === "treeview" &&
            !crudOpt?.treeviewHideCounter
          ) {
            if (dataNew.length === 1) {
              dataNew = [addNewCounterEachItem(dataNew)]
              // console.log(dataNew)
            } else {
              let newData2 = []
              for (let item of dataNew) {
                newData2.push(addNewCounterEachItem([item]))
              }
              dataNew = newData2
            }
          }

          // console.log(action.payload)
          pageDataCopy[action.payload?.params?.storeName] = {
            ...pageDataCopy[action.payload?.params?.storeName],
            isLoading: false,
            status: "succeeded",
            data: dataNew,
            dataOld: dataNew,
            params: action.payload.params,
            total: action.payload.total
            // dataDropdown: action.payload?.data?.map((item) => ({
            //   value: item.id,
            //   label: item.title,
            //   post: false,
            //   put: false,
            //   delete: false,
            //   get: false
            // }))
          }
          state.pageData = pageDataCopy

          //////////// AUTO FORM DYNAMIC
          let storeName = action.payload?.params?.storeName
          let pageAttributesCopy = { ...state.pageAttributes }
          if (
            pageAttributesCopy[storeName] &&
            !pageAttributesCopy[storeName]?.crudOpt?.data?.dynamicForm
          ) {
            let genDynamicForm = []
            Object.keys(pageDataCopy[storeName]?.data?.[0] ?? {})?.map(
              (item, i) => {
                // console.log(item)
                if (item !== "id") {
                  genDynamicForm.push({
                    fieldName: item,
                    size: 6
                  })
                }
              }
            )
            if (!pageAttributesCopy[storeName]?.crudOpt?.data) {
              if (pageAttributesCopy[storeName].crudOpt) {
                pageAttributesCopy[storeName].crudOpt["data"] = {
                  dynamicForm: genDynamicForm
                }
              } else {
                pageAttributesCopy[storeName] = {
                  crudOpt: {
                    data: {
                      dynamicForm: genDynamicForm
                    }
                  }
                }
              }
            } else {
              pageAttributesCopy[storeName].crudOpt.data["dynamicForm"] =
                genDynamicForm
            }
            // console.log(pageAttributesCopy)
            state.pageAttributes = pageAttributesCopy
          }

          ////////////AUTO DYNAMIC COLUMNS
          if (
            pageAttributesCopy[storeName]?.crudOpt?.gridTheme === "grid_card" &&
            (pageAttributesCopy[storeName]?.crudOpt?.dynamicColumnsFieldsAuto ||
              !pageAttributesCopy[storeName]?.crudOpt?.data?.dynamicColumns)
          ) {
            if (pageDataCopy[storeName]?.data?.length > 0) {
              let result = []
              const columnsKeyExist = pageAttributesCopy[
                storeName
              ]?.crudOpt?.data?.dynamicColumns?.map((obj) => {
                return obj?.fieldName
              })

              const globalProperties =
                pageAttributesCopy[storeName]?.crudOpt
                  ?.dynamicColumnsGlobalProperties

              Object.keys(pageDataCopy[storeName]?.data?.[0])?.map(
                (existKey) => {
                  if (
                    !columnsKeyExist?.includes(existKey) &&
                    existKey !== "id"
                  ) {
                    const columnTitle = existKey.replace(/([A-Z])/g, " $1")
                    let columnTitleEnd =
                      columnTitle?.charAt(0)?.toUpperCase() +
                      columnTitle?.slice(1)
                    const column = {
                      fieldName: `${existKey}`,
                      columnName: columnTitleEnd?.replaceAll("_", " "),
                      ...globalProperties
                    }

                    result.push(column)
                  }
                }
              )
              if (result.length > 0) {
                if (!pageAttributesCopy[storeName]?.crudOpt?.data) {
                  pageAttributesCopy[storeName].crudOpt["data"] = {
                    dynamicColumns: result
                  }
                } else {
                  pageAttributesCopy[storeName].crudOpt.data["dynamicColumns"] =
                    result
                }
              }
            }
          }
        }
      })
      .addCase(getDataList.rejected, (state, action) => {
        // console.log(action)
        let pageDataCopy = { ...state.pageData }
        // pageDataCopy[action.payload?.params?.storeName] = {
        //   isLoading: false,
        //   status: "failed"
        // }
        pageDataCopy[action?.meta?.arg?.storeName] = {
          ...(pageDataCopy?.[action?.meta?.arg?.storeName] ?? {}),
          isLoading: false,
          status: "failed",
          error: `${action.payload?.message}`
        }
        // console.log(pageDataCopy)
        state.pageData = pageDataCopy
      })

      .addCase(setBtnAddCondition.pending, (state, action) => {
        let pageDataCopy = { ...state.pageData }
        if (pageDataCopy[action.meta?.arg?.storeName]) {
          pageDataCopy[action.meta?.arg?.storeName][
            "isLoadingBtnAddCondition"
          ] = true
          pageDataCopy[action.meta?.arg?.storeName]["result"] = true
        } else {
          pageDataCopy[action.meta?.arg?.storeName] = {
            isLoadingBtnAddCondition: true,
            btnAddCondition: true
          }
        }
        state.pageData = pageDataCopy
      })
      .addCase(setBtnAddCondition.rejected, (state, action) => {
        let pageDataCopy = { ...state.pageData }
        pageDataCopy[action?.meta?.arg?.storeName] = {
          ...(pageDataCopy?.[action?.meta?.arg?.storeName] ?? {}),
          isLoadingBtnAddCondition: false,
          btnAddCondition: false
        }
        state.pageData = pageDataCopy
      })
      .addCase(setBtnAddCondition.fulfilled, (state, action) => {
        // console.log(action)
        let pageDataCopy = { ...state.pageData }
        if (!pageDataCopy[action.payload?.storeName]) {
          pageDataCopy[action.payload?.storeName] = {
            btnAddCondition: action.payload.result,
            isLoadingBtnAddCondition: false
          }
        } else {
          pageDataCopy[action.payload?.storeName]["btnAddCondition"] =
            action.payload.result
          pageDataCopy[action.payload?.storeName][
            "isLoadingBtnAddCondition"
          ] = false
        }
        state.pageData = pageDataCopy
      })

      .addCase(setTableActiveTabs.fulfilled, (state, action) => {
        let pageDataCopy = { ...state.tableActiveTabs }
        if (!pageDataCopy[action.payload?.storeName]) {
          pageDataCopy[action.payload?.storeName] = [action.payload.data]
        } else {
          const index = pageDataCopy[action.payload?.storeName]?.findIndex(
            (item) => item.id === action.payload?.data?.id
          )
          if (index !== -1) {
            pageDataCopy[action.payload?.storeName][index] = action.payload.data
          } else {
            pageDataCopy[action.payload?.storeName].push(action.payload.data)
          }
        }
        state.tableActiveTabs = pageDataCopy
        // console.log(current(state).tableActiveTabs)
      })

      .addCase(setExpandedData.fulfilled, (state, action) => {
        let pageDataCopy = { ...state.pageData }
        if (!pageDataCopy[action.payload?.storeName]) {
          pageDataCopy[action.payload?.storeName] = {
            expandedData: action.payload.rowData
          }
        } else {
          pageDataCopy[action.payload?.storeName]["expandedData"] =
            action.payload.rowData
        }
        state.pageData = pageDataCopy
      })

      .addCase(setExpandedDataComponent.fulfilled, (state, action) => {
        let pageDataCopy = { ...state.pageData }
        if (pageDataCopy[action.payload?.storeName]?.expandedDataComponent) {
          pageDataCopy[action.payload?.storeName]["expandedDataComponent"] = {
            ...pageDataCopy[action.payload?.storeName]?.expandedDataComponent,
            ...action.payload.rowData
          }
        } else {
          pageDataCopy[action.payload?.storeName]["expandedDataComponent"] =
            action.payload.rowData
        }
        state.pageData = pageDataCopy
      })

      ///DETAIL
      .addCase(setSelectedData.fulfilled, (state, action) => {
        let pageDataCopy = { ...state.pageData }
        if (!pageDataCopy[action.payload?.storeName]) {
          pageDataCopy[action.payload?.storeName] = {
            selectedData: action.payload.rowData,
            isLoadingDetail: false
          }
        } else {
          pageDataCopy[action.payload?.storeName]["selectedData"] =
            action.payload.rowData
          pageDataCopy[action.payload?.storeName]["isLoadingDetail"] = false
        }
        // console.log(pageDataCopy)

        state.pageData = pageDataCopy

        ////////////AUTO DYNAMIC FORM
        let pageAttributesCopy = { ...state.pageAttributes }
        // console.log(pageAttributesCopy, action.payload?.storeName)
        if (
          pageAttributesCopy[action.payload?.storeName] &&
          !pageAttributesCopy[action.payload?.storeName]?.crudOpt?.data
            ?.dynamicForm
        ) {
          let genDynamicForm = []
          Object.keys(action.payload.rowData ?? {})?.map((item, i) => {
            // console.log(item)
            if (item !== "id") {
              genDynamicForm.push({
                fieldName: item,
                size: 6
              })
            }
          })
          if (!pageAttributesCopy[action.payload?.storeName]?.crudOpt?.data) {
            pageAttributesCopy[action.payload?.storeName].crudOpt["data"] = {
              dynamicForm: genDynamicForm
            }
          } else {
            pageAttributesCopy[action.payload?.storeName].crudOpt.data[
              "dynamicForm"
            ] = genDynamicForm
          }
          // console.log(pageAttributesCopy)
          state.pageAttributes = pageAttributesCopy
        }
      })

      .addCase(setcurrentExpandedData.fulfilled, (state, action) => {
        let pageDataCopy = { ...state.pageData }
        if (!pageDataCopy[action.payload?.storeName]) {
          pageDataCopy[action.payload?.storeName] = {
            currentExpandedData: action.payload.rowData
          }
        } else {
          pageDataCopy[action.payload?.storeName]["currentExpandedData"] =
            action.payload.rowData
        }

        state.pageData = pageDataCopy
        state.currentExpandedDataGlobal = action.payload.rowData
        // console.log(pageDataCopy)
      })

      .addCase(getDataById.pending, (state, action) => {
        let pageDataCopy = { ...state.pageData }
        if (pageDataCopy[action.meta?.arg?.storeName]) {
          pageDataCopy[action.meta?.arg?.storeName]["selectedData"] = null
          pageDataCopy[action.meta?.arg?.storeName]["isLoadingDetail"] = true
        } else {
          pageDataCopy[action.meta?.arg?.storeName] = {
            selectedData: null,
            isLoadingDetail: true
          }
        }
        state.pageData = pageDataCopy
      })
      .addCase(getDataById.fulfilled, (state, action) => {
        // console.log(action)
        let pageDataCopy = { ...state.pageData }
        if (pageDataCopy[action.payload?.storeName]) {
          pageDataCopy[action.payload?.storeName]["selectedData"] =
            action.payload.data
          pageDataCopy[action.payload?.storeName]["isLoadingDetail"] = false
        } else {
          pageDataCopy[action.payload?.storeName] = {
            selectedData: action.payload.data,
            isLoadingDetail: false
          }
        }
        state.pageData = pageDataCopy
      })
      .addCase(getDataById.rejected, (state, action) => {
        // console.log(action)
        let pageDataCopy = { ...state.pageData }
        if (pageDataCopy[action.payload?.storeName]) {
          pageDataCopy[action.payload?.storeName]["selectedData"] = null
          pageDataCopy[action.payload?.storeName]["isLoadingDetail"] = false
        } else {
          pageDataCopy[action.payload?.storeName] = {
            selectedData: null,
            isLoadingDetail: false
          }
        }
        state.pageData = pageDataCopy
      })

      ///EDIT
      .addCase(putData.pending, (state, action) => {
        let pageDataCopy = { ...state.pageData }
        if (pageDataCopy[action.meta?.arg?.storeName]) {
          pageDataCopy[action.meta?.arg?.storeName]["statusAddEdit"] = "loading"
          pageDataCopy[action.meta?.arg?.storeName]["isLoadingAddEdit"] = true
        } else {
          pageDataCopy[action.meta?.arg?.storeName] = {
            statusAddEdit: "loading",
            isLoadingAddEdit: true
          }
        }
        state.pageData = pageDataCopy
      })
      .addCase(putData.fulfilled, (state, action) => {
        // console.log(action.payload)
        let pageDataCopy = { ...state.pageData }
        pageDataCopy[action.payload?.storeName]["statusAddEdit"] = "succeeded"
        pageDataCopy[action.payload?.storeName]["isLoadingAddEdit"] = false
        state.pageData = pageDataCopy
      })
      .addCase(putData.rejected, (state, action) => {
        // console.log(action)
        if (action.payload) {
          let pageDataCopy = { ...state.pageData }
          pageDataCopy[action.payload?.storeName]["statusAddEdit"] = "failed"
          pageDataCopy[action.payload?.storeName]["isLoadingAddEdit"] = false
          // pageDataCopy[action.payload?.storeName]["errorAddEdit"] =
          //   action.payload?.message
          state.pageData = pageDataCopy
        }
      })

      .addCase(putDataMulti.pending, (state, action) => {
        let pageDataCopy = { ...state.pageData }
        if (pageDataCopy[action.meta?.arg?.storeName]) {
          pageDataCopy[action.meta?.arg?.storeName]["statusAddEdit"] = "loading"
          pageDataCopy[action.meta?.arg?.storeName]["isLoadingAddEdit"] = true
        } else {
          pageDataCopy[action.meta?.arg?.storeName] = {
            statusAddEdit: "loading",
            isLoadingAddEdit: true
          }
        }
        state.pageData = pageDataCopy
      })
      .addCase(putDataMulti.fulfilled, (state, action) => {
        // console.log(action.payload)
        let pageDataCopy = { ...state.pageData }
        pageDataCopy[action.payload?.storeName]["statusAddEdit"] = "succeeded"
        pageDataCopy[action.payload?.storeName]["isLoadingAddEdit"] = false
        state.pageData = pageDataCopy
      })
      .addCase(putDataMulti.rejected, (state, action) => {
        // console.log(action)
        if (action.payload) {
          let pageDataCopy = { ...state.pageData }
          pageDataCopy[action.payload?.storeName]["statusAddEdit"] = "failed"
          pageDataCopy[action.payload?.storeName]["isLoadingAddEdit"] = false
          // pageDataCopy[action.payload?.storeName]["errorAddEdit"] =
          //   action.payload?.message
          state.pageData = pageDataCopy
        }
      })

      .addCase(putDataTree.pending, (state, action) => {
        let pageDataCopy = { ...state.pageData }
        if (pageDataCopy[action.meta?.arg?.storeName]) {
          pageDataCopy[action.meta?.arg?.storeName]["statusAddEdit"] = "loading"
          pageDataCopy[action.meta?.arg?.storeName]["isLoadingAddEdit"] = true
        } else {
          pageDataCopy[action.meta?.arg?.storeName] = {
            statusAddEdit: "loading",
            isLoadingAddEdit: true
          }
        }
        state.pageData = pageDataCopy
      })
      .addCase(putDataTree.fulfilled, (state, action) => {
        let pageDataCopy = { ...state.pageData }
        pageDataCopy[action.payload?.storeName]["statusAddEdit"] = "succeeded"
        pageDataCopy[action.payload?.storeName]["isLoadingAddEdit"] = false
        state.pageData = pageDataCopy
      })
      .addCase(putDataTree.rejected, (state, action) => {
        if (action.payload) {
          let pageDataCopy = { ...state.pageData }
          pageDataCopy[action.payload?.storeName]["statusAddEdit"] = "failed"
          pageDataCopy[action.payload?.storeName]["isLoadingAddEdit"] = false
          state.pageData = pageDataCopy
        }
      })

      .addCase(expandCollapseAllTree.fulfilled, (state, action) => {
        let pageDataCopy = { ...state.pageData }
        pageDataCopy[action.payload?.storeName].data = action.payload.data
        state.pageData = pageDataCopy
      })

      .addCase(setViewOnlyTree.fulfilled, (state, action) => {
        // console.log(action.payload)
        let newDataOnlyTree = action.payload.data
        if (newDataOnlyTree) {
          let pageDataCopy = { ...state.pageData }
          if (newDataOnlyTree?.parent_id !== null) {
            newDataOnlyTree["isRoot"] = true
          }
          // backup dulu
          let pageDataTempFilterTreeCopy = { ...state.pageDataTempFilterTree }
          pageDataTempFilterTreeCopy[action.payload?.storeName] = JSON.parse(
            JSON.stringify(pageDataCopy[action.payload?.storeName].data)
          )
          state.pageDataTempFilterTree = pageDataTempFilterTreeCopy

          pageDataCopy[action.payload?.storeName].data = [newDataOnlyTree]
          state.pageData = pageDataCopy

          let pageDataTempFilterTreeCurrentCopy = {
            ...state.pageDataTempFilterTreeCurrent
          }
          pageDataTempFilterTreeCurrentCopy[action.payload?.storeName] =
            newDataOnlyTree

          state.pageDataTempFilterTreeCurrent =
            pageDataTempFilterTreeCurrentCopy
        } else {
          let pageDataCopy = { ...state.pageData }
          pageDataCopy[action.payload?.storeName].data =
            state.pageDataTempFilterTree[action.payload?.storeName]
          state.pageData = pageDataCopy

          let pageDataTempFilterTreeCopy = { ...state.pageDataTempFilterTree }
          pageDataTempFilterTreeCopy[action.payload?.storeName] = null
          state.pageDataTempFilterTree = pageDataTempFilterTreeCopy

          let pageDataTempFilterTreeCurrentCopy = {
            ...state.pageDataTempFilterTreeCurrent
          }
          pageDataTempFilterTreeCurrentCopy[action.payload?.storeName] = null
          state.pageDataTempFilterTreeCurrent =
            pageDataTempFilterTreeCurrentCopy
        }
      })

      .addCase(lazyLoadByParent.pending, (state, action) => {
        // console.log(action.meta)
        let pageDataCopy = { ...state.isLoadingNode }
        let storeName = action.meta?.arg?.storeName
        let currentId = action.meta?.arg?.currentId
        // console.log(current(state).isLoadingNode)
        if (pageDataCopy[storeName]) {
          const index = pageDataCopy[storeName]?.indexOf(currentId)
          // console.log(index)
          if (index !== -1) {
            pageDataCopy[storeName][index] = currentId
          } else {
            pageDataCopy[storeName].push(currentId)
          }
        } else {
          pageDataCopy[storeName] = [currentId]
        }

        state.isLoadingNode = pageDataCopy
      })
      .addCase(lazyLoadByParent.fulfilled, (state, action) => {
        // console.log(action.payload)
        let storeName = action.payload?.storeName
        let currentId = action.payload?.currentId

        let newData = action.payload?.data
        // console.log(newData)
        // if (newData?.length > 0) {
        let pageDataCopy = { ...state.pageData }
        addDataListToNestedArray(
          pageDataCopy[storeName]?.data,
          currentId,
          newData
        )
        state.pageData = pageDataCopy
        // }

        let loadingNodeCopy = { ...state.isLoadingNode }
        if (loadingNodeCopy[storeName]) {
          const index = loadingNodeCopy[storeName].indexOf(currentId)
          // console.log(index)
          if (index !== -1) {
            loadingNodeCopy[storeName].splice(index, 1)
          }
        }
        // state.isLoadingNode = loadingNodeCopy
      })

      .addCase(setOrgChartData.fulfilled, (state, action) => {
        // console.log(action.payload)
        let respData = action.payload.data
        let maxLevel = 1
        if (respData) {
          let pageDataCopy = { ...state.pageData }

          let flattenRespData
          if (respData?.length > 0 && isNestedObjectArray(respData)) {
            // console.log("here")
            try {
              maxLevel = getMaxLevel(respData?.[0])
            } catch (error) {
              console.error(error)
            }

            flattenRespData = flattenArray(respData)
          } else {
            flattenRespData = respData
          }
          // console.log(respData?.length, flattenRespData)

          if (flattenRespData) {
            const crudOpt = {
              ...state.pageAttributes[action.payload?.storeName]?.crudOpt
            }
            let newRespData = transformToTargetFormat(flattenRespData, crudOpt)
            // console.log(newRespData)
            if (newRespData) {
              pageDataCopy[action.payload?.storeName]["OrgChartData"] =
                newRespData
              pageDataCopy[action.payload?.storeName]["OrgChartData_maxLevel"] =
                maxLevel
              state.pageData = pageDataCopy
            }
          }
        }
      })

      .addCase(setOrgChartDataProp.fulfilled, (state, action) => {
        if (action.payload?.data) {
          let pageDataCopy = { ...state.pageData }
          pageDataCopy[action.payload?.storeName]["OrgChartDataProp"] = {
            ...pageDataCopy[action.payload?.storeName]["OrgChartDataProp"],
            ...action.payload.data
          }
          state.pageData = pageDataCopy
        }
      })

      .addCase(setOrgChartData2.fulfilled, (state, action) => {
        // console.log(action.payload)
        let respData = JSON.parse(action.payload.data)
        if (respData) {
          let pageDataCopy = { ...state.pageData }

          pageDataCopy[action.payload?.storeName]["OrgChartData"] = respData
          state.pageData = pageDataCopy
        }
      })

      // .addCase(updateOrgChartData.pending, (state, action) => {
      //   let pageDataCopy = { ...state.pageData }
      //   if (pageDataCopy[action.meta?.arg?.storeName]) {
      //     pageDataCopy[action.meta?.arg?.storeName]["statusAddEdit"] = "loading"
      //     pageDataCopy[action.meta?.arg?.storeName]["isLoadingAddEdit"] = true
      //   } else {
      //     pageDataCopy[action.meta?.arg?.storeName] = {
      //       statusAddEdit: "loading",
      //       isLoadingAddEdit: true
      //     }
      //   }
      //   state.pageData = pageDataCopy
      // })
      .addCase(updateOrgChartData.fulfilled, (state, action) => {
        let data = action.payload.data
        // console.log(data)
        const storeName = action.payload?.storeName
        // console.log(data)
        if (data) {
          let pageDataCopy = { ...state.pageData }
          let oldData = JSON.parse(
            JSON.stringify(pageDataCopy[storeName]["OrgChartData"])
          )
          const newRespData = oldData?.nodeDataArray?.map((nodeData) => {
            if (nodeData.key === data.key) {
              // Update the specific property in your node data
              return { ...nodeData, ...data }
            }
            return nodeData
          })

          pageDataCopy[storeName]["OrgChartData"] = {
            // ...oldData,
            nodeDataArray: newRespData,
            linkDataArray: oldData?.linkDataArray
          }
          // console.log(pageDataCopy[storeName]["OrgChartData"])
          state.pageData = pageDataCopy
        }
      })
      ///ADD
      .addCase(postData.pending, (state, action) => {
        // console.log(action.meta?.arg?.storeName)
        let pageDataCopy = { ...state.pageData }
        if (pageDataCopy[action.meta?.arg?.storeName]) {
          pageDataCopy[action.meta?.arg?.storeName]["statusAddEdit"] = "loading"
          pageDataCopy[action.meta?.arg?.storeName]["isLoadingAddEdit"] = true
        } else {
          pageDataCopy[action.meta?.arg?.storeName] = {
            statusAddEdit: "loading",
            isLoadingAddEdit: true
          }
        }
        state.pageData = pageDataCopy
      })
      .addCase(postData.fulfilled, (state, action) => {
        // console.log(action.payload)
        let pageDataCopy = { ...state.pageData }
        pageDataCopy[action.payload?.storeName]["statusAddEdit"] = "succeeded"
        pageDataCopy[action.payload?.storeName]["isLoadingAddEdit"] = false
        state.pageData = pageDataCopy
      })
      .addCase(postData.rejected, (state, action) => {
        // console.log(action)
        if (action.payload) {
          let pageDataCopy = { ...state.pageData }
          pageDataCopy[action.payload?.storeName]["statusAddEdit"] = "failed"
          pageDataCopy[action.payload?.storeName]["isLoadingAddEdit"] = false
          // pageDataCopy[action.payload?.storeName]["errorAddEdit"] =
          //   action.payload?.message
          state.pageData = pageDataCopy
        }
      })

      .addCase(postDataMulti.pending, (state, action) => {
        // console.log(action.meta?.arg?.storeName)
        let pageDataCopy = { ...state.pageData }
        if (pageDataCopy[action.meta?.arg?.storeName]) {
          pageDataCopy[action.meta?.arg?.storeName]["statusAddEdit"] = "loading"
          pageDataCopy[action.meta?.arg?.storeName]["isLoadingAddEdit"] = true
        } else {
          pageDataCopy[action.meta?.arg?.storeName] = {
            statusAddEdit: "loading",
            isLoadingAddEdit: true
          }
        }
        state.pageData = pageDataCopy
      })
      .addCase(postDataMulti.fulfilled, (state, action) => {
        // console.log(action.payload)
        let pageDataCopy = { ...state.pageData }
        pageDataCopy[action.payload?.storeName]["statusAddEdit"] = "succeeded"
        pageDataCopy[action.payload?.storeName]["isLoadingAddEdit"] = false
        state.pageData = pageDataCopy
      })
      .addCase(postDataMulti.rejected, (state, action) => {
        // console.log(action)
        if (action.payload) {
          let pageDataCopy = { ...state.pageData }
          pageDataCopy[action.payload?.storeName]["statusAddEdit"] = "failed"
          pageDataCopy[action.payload?.storeName]["isLoadingAddEdit"] = false
          // pageDataCopy[action.payload?.storeName]["errorAddEdit"] =
          //   action.payload?.message
          state.pageData = pageDataCopy
        }
      })

      ///DELETE
      .addCase(deleteData.pending, (state, action) => {
        let pageDataCopy = { ...state.pageData }
        if (pageDataCopy[action.meta?.arg?.storeName]) {
          pageDataCopy[action.meta?.arg?.storeName]["statusDelete"] = "loading"
          pageDataCopy[action.meta?.arg?.storeName]["isLoadingDelete"] = true
        } else {
          pageDataCopy[action.meta?.arg?.storeName] = {
            statusAddEdit: "loading",
            isLoadingDelete: true
          }
        }
        state.pageData = pageDataCopy
      })
      .addCase(deleteData.fulfilled, (state, action) => {
        let pageDataCopy = { ...state.pageData }
        pageDataCopy[action.payload?.storeName]["statusDelete"] = "succeeded"
        pageDataCopy[action.payload?.storeName]["isLoadingDelete"] = false
        state.pageData = pageDataCopy
      })
      .addCase(deleteData.rejected, (state, action) => {
        if (action.payload) {
          let pageDataCopy = { ...state.pageData }
          pageDataCopy[action.payload?.storeName]["statusDelete"] = "failed"
          pageDataCopy[action.payload?.storeName]["isLoadingDelete"] = false
          // pageDataCopy[action.payload?.storeName]["errorDelete"] =
          //   action.payload?.message
          state.pageData = pageDataCopy
        }
      })

      ///DELETE-SELECTED-ROW
      .addCase(deleteSelectedRowData.pending, (state, action) => {
        let pageDataCopy = { ...state.pageData }
        if (pageDataCopy[action.meta?.arg?.storeName]) {
          pageDataCopy[action.meta?.arg?.storeName]["statusDelete"] = "loading"
          pageDataCopy[action.meta?.arg?.storeName]["isLoadingDelete"] = true
        } else {
          pageDataCopy[action.meta?.arg?.storeName] = {
            statusAddEdit: "loading",
            isLoadingDelete: true
          }
        }
        state.pageData = pageDataCopy
      })
      .addCase(deleteSelectedRowData.fulfilled, (state, action) => {
        let pageDataCopy = { ...state.pageData }
        pageDataCopy[action.payload?.storeName]["statusDelete"] = "succeeded"
        pageDataCopy[action.payload?.storeName]["isLoadingDelete"] = false
        state.pageData = pageDataCopy
      })
      .addCase(deleteSelectedRowData.rejected, (state, action) => {
        if (action.payload) {
          let pageDataCopy = { ...state.pageData }
          pageDataCopy[action.payload?.storeName]["statusDelete"] = "failed"
          pageDataCopy[action.payload?.storeName]["isLoadingDelete"] = false
          // pageDataCopy[action.payload?.storeName]["errorDelete"] =
          //   action.payload?.message
          state.pageData = pageDataCopy
        }
      })

      ///OTHERS
      .addCase(setParamsNoServerSide.fulfilled, (state, action) => {
        // console.log(action)
        let pageDataCopy = { ...state.pageData }
        try {
          pageDataCopy[action?.payload?.storeName]["params"] = {
            // ...(pageDataCopy[action?.payload?.storeName]["params"] ?? {}),
            ...(action?.payload ?? {})
          }
          state.pageData = pageDataCopy
        } catch (error) {
          console.error(error)
        }
      })

      .addCase(resetData.fulfilled, (state, action) => {
        // console.log(action)
        let pageDataCopy = { ...state.pageData }
        // console.log(action?.payload)
        try {
          pageDataCopy[action?.payload]["data"] = null
          pageDataCopy[action?.payload]["dataDropdown"] = null
          pageDataCopy[action?.payload]["OrgChartData"] = null
          pageDataCopy[action?.payload]["OrgChartData_maxLevel"] = null
          // pageDataCopy[action?.payload]["selectedData"] = null

          pageDataCopy[action?.payload]["params"] = null
          pageDataCopy[action?.payload]["filteredData"] = null

          pageDataCopy[action?.payload]["total"] = null

          state.pageData = pageDataCopy
        } catch (error) {
          console.error(error)
        }
      })

      .addCase(resetSelectedData.fulfilled, (state, action) => {
        let pageDataCopy = { ...state.pageData }
        if (pageDataCopy[action?.payload]?.selectedData) {
          pageDataCopy[action?.payload]["selectedData"] = null
          state.pageData = pageDataCopy
        }
      })

      .addCase(setInitialDataAdd.fulfilled, (state, action) => {
        let pageDataCopy = { ...state.pageData }
        pageDataCopy[action?.payload?.storeName]["selectedData"] =
          action?.payload?.data
        state.pageData = pageDataCopy
      })

      ////////////////CHILDREN START////////////////
      .addCase(addDataToChildren.fulfilled, (state, action) => {
        // console.log(action)
        let pageDataCopy = { ...state.pageData }
        const storeName = action?.payload?.storeName
        const data = action?.payload?.data

        // if (pageDataCopy[storeName]?.data) {
        //   const parentData = pageDataCopy[storeName].data.find(
        //     (res) => res.id === data?.parent_id
        //   )
        //   if (parentData) {
        //     parentData?.children?.push(action?.payload?.data)
        //     pageDataCopy[storeName]["total"] =
        //       pageDataCopy[storeName]["total"] ?? 0 + 1
        //   }
        // } else {
        //   pageDataCopy[storeName] = {
        //     data: [data],
        //     total: 1
        //   }
        // }

        addDataToNestedArray(
          pageDataCopy[storeName]?.data ?? [],
          data?.parent_id,
          data
        )
        state.pageData = pageDataCopy
      })

      .addCase(removeDataFromChildren.fulfilled, (state, action) => {
        // console.log(action)
        let pageDataCopy = { ...state.pageData }
        if (pageDataCopy[action?.payload?.storeName]?.data) {
          // const parent_id = action?.payload?.data?.parent_id
          // const id = action?.payload?.data?.id
          // const parentData = pageDataCopy[action?.payload?.storeName].data.find(
          //   (data) => data.id === parent_id
          // )
          // if (parentData) {
          //   const objWithIdIndex = parentData?.children?.findIndex(
          //     (obj) => obj?.id === id
          //   )

          //   if (objWithIdIndex > -1) {
          //     parentData?.children?.splice(objWithIdIndex, 1)
          //   }
          removeById(
            action.payload.id,
            pageDataCopy[action?.payload?.storeName]?.data
          )
          pageDataCopy[action?.payload?.storeName]["total"] =
            pageDataCopy[action?.payload?.storeName]["total"] ?? 1 - 1
          state.pageData = pageDataCopy
          // }
        }
      })

      .addCase(setDataToChildren.fulfilled, (state, action) => {
        console.log("setDataToChildren")
        let pageDataCopy = { ...state.pageData }
        if (
          pageDataCopy[action?.payload?.storeName]?.selectedData?.id ===
          action.payload?.data?.id
        ) {
          pageDataCopy[action.payload.storeName].selectedData =
            action.payload?.data
        }

        if (pageDataCopy[action?.payload?.storeName].data) {
          const newData = action.payload?.data
          updateById(
            newData?.id,
            newData,
            pageDataCopy[action?.payload?.storeName].data
          )
        }
        state.pageData = pageDataCopy
      })

      ////////////////CHILDREN END////////////////

      .addCase(addDataById.fulfilled, (state, action) => {
        // console.log(action)
        let pageDataCopy = { ...state.pageData }

        if (pageDataCopy[action?.payload?.storeName]?.data) {
          //  const objIndex = pageDataCopy[
          //   action?.payload?.storeName
          // ].data.findIndex((obj) => obj.id === action.payload?.data?.id)
          // if (objIndex !== -1) {
          //   pageDataCopy[action?.payload?.storeName].data[objIndex] =
          //     action.payload.data
          // }
          let newData = action?.payload?.data
          delete newData["isFromModal"]
          delete newData["storeName"]
          pageDataCopy[action?.payload?.storeName].data.unshift(newData)
          pageDataCopy[action?.payload?.storeName]["total"] =
            pageDataCopy[action?.payload?.storeName]["total"] ?? 0 + 1
        } else if (
          !pageDataCopy[action?.payload?.storeName]?.data &&
          action?.payload?.isFromModal
        ) {
          pageDataCopy[action?.payload?.storeName] = {
            data: [newData],
            total: 1
          }
        }
        const crudOpt = state.pageAttributes[action.payload?.storeName]?.crudOpt
        if (crudOpt?.gridTheme === "timeline") {
          pageDataCopy[action?.payload?.storeName].data.sort(
            (a, b) =>
              b[crudOpt.timeLineDateField] - a[crudOpt.timeLineDateField]
          )
        }
        state.pageData = pageDataCopy
      })

      .addCase(addDataByIdMulti.fulfilled, (state, action) => {
        // console.log(action)
        let pageDataCopy = { ...state.pageData }
        if (pageDataCopy[action?.payload?.storeName]?.data) {
          // pageDataCopy[action?.payload?.storeName].data = [
          //   ...pageDataCopy[action?.payload?.storeName].data,
          //   ...action?.payload?.data
          // ]
          const newData = action?.payload?.data
          if (newData) {
            const newDataMap = {}
            newData.forEach((item) => {
              newDataMap[item.id] = item
            })
            const updatedData = pageDataCopy[
              action?.payload?.storeName
            ].data.map((item) => {
              if (newDataMap.hasOwnProperty(item.id)) {
                return newDataMap[item.id]
              }
              return item
            })
            newData.forEach((item) => {
              if (
                !pageDataCopy[action?.payload?.storeName].data.find(
                  (existingItem) => existingItem.id === item.id
                )
              ) {
                updatedData.push(item)
              }
            })

            pageDataCopy[action?.payload?.storeName].data = updatedData
          }

          pageDataCopy[action?.payload?.storeName]["total"] =
            pageDataCopy[action?.payload?.storeName]["total"] ??
            0 + action?.payload?.data?.length
        } else if (
          !pageDataCopy[action?.payload?.storeName]?.data &&
          action?.payload?.isFromModal
        ) {
          pageDataCopy[action?.payload?.storeName] = {
            data: action?.payload?.data,
            total: action?.payload?.data?.length
          }
        }
        const crudOpt = state.pageAttributes[action.payload?.storeName]?.crudOpt
        if (crudOpt?.gridTheme === "timeline") {
          pageDataCopy[action?.payload?.storeName].data.sort(
            (a, b) =>
              b[crudOpt.timeLineDateField] - a[crudOpt.timeLineDateField]
          )
        }
        state.pageData = pageDataCopy
      })

      // .addCase(setDataById.fulfilled, (state, action) => {
      //   console.log("setDataById")
      //   // console.log(action.payload.data)
      //   // console.log(action)
      //   let pageDataCopy = { ...state.pageData }
      //   if (
      //     pageDataCopy[action?.payload?.storeName]?.selectedData?.id ===
      //     action.payload?.data?.id
      //   ) {
      //     pageDataCopy[action.payload.storeName].selectedData =
      //       action.payload?.data
      //   }
      //   if (pageDataCopy[action?.payload?.storeName].data) {
      //     const objIndex = pageDataCopy[
      //       action?.payload?.storeName
      //     ].data.findIndex((obj) => obj.id === action.payload?.data?.id)
      //     if (objIndex !== -1) {
      //       pageDataCopy[action?.payload?.storeName].data[objIndex] =
      //         action.payload.data
      //     }
      //   }
      //   const crudOpt = state.pageAttributes[action.payload?.storeName]?.crudOpt
      //   if (crudOpt?.gridTheme === "timeline") {
      //     pageDataCopy[action?.payload?.storeName].data.sort(
      //       (a, b) =>
      //         b[crudOpt.timeLineDateField] - a[crudOpt.timeLineDateField]
      //     )
      //   }
      //   state.pageData = pageDataCopy
      // })

      .addCase(setDataById.fulfilled, (state, action) => {
        // console.log("setDataById")
        const { payload } = action

        state.pageData = produce(state.pageData, (draftPageData) => {
          let data = draftPageData[payload.storeName]?.data
          // console.log(data)
          // console.log(payload.data)
          // Update the matching data object, if found
          if (data && payload.data) {
            const objIndex = data.findIndex(
              (obj) => obj.id === payload.data?.id
            )

            if (objIndex !== -1) {
              let newData = payload.data
              delete newData["storeName"]
              data[objIndex] = { ...data[objIndex], ...(newData ?? {}) }
            }
          }

          const crudOpt = state.pageAttributes[payload.storeName]?.crudOpt
          if (crudOpt?.gridTheme === "timeline") {
            data.sort(
              (a, b) =>
                b[crudOpt.timeLineDateField] - a[crudOpt.timeLineDateField]
            )
          }

          // If the updated data object matches the selectedData object, update selectedData indirectly
          if (
            draftPageData[payload.storeName]?.selectedData?.id ===
            payload.data?.id
          ) {
            draftPageData[payload.storeName].selectedData = {
              ...draftPageData[payload.storeName].selectedData,
              ...payload.data
            }
          }
        })
      })

      .addCase(filterCurrentData.fulfilled, (state, action) => {
        console.log("filterCurrentData")
        // console.log(action)
        let pageDataCopy = { ...state.pageData }
        pageDataCopy[action.payload.storeName].data = action.payload?.data
        state.pageData = pageDataCopy
      })

      .addCase(setDataListFiltered.fulfilled, (state, action) => {
        console.log("setDataListFiltered")
        // console.log(action)
        let pageDataCopy = { ...state.pageData }
        pageDataCopy[action.payload.storeName].filteredData =
          action.payload?.data
        // console.log(action.payload?.params)
        if (action.payload?.params) {
          pageDataCopy[action.payload.storeName].params = action.payload.params
        }
        state.pageData = pageDataCopy
      })

      .addCase(resetDataListFiltered.fulfilled, (state, action) => {
        console.log("resetDataListFiltered")
        // console.log(action)
        let pageDataCopy = { ...state.pageData }
        pageDataCopy[action.payload.storeName].filteredData = null
        if (action?.meta?.arg?.params) {
          pageDataCopy[action.payload.storeName].params =
            action?.meta?.arg?.params
        }
        state.pageData = pageDataCopy
      })

      .addCase(removeDataById.fulfilled, (state, action) => {
        // console.log(action)
        let pageDataCopy = { ...state.pageData }
        if (Array.isArray(action.payload.id)) {
          pageDataCopy[action?.payload?.storeName].data = pageDataCopy[
            action?.payload?.storeName
          ].data.filter((obj) => action.payload.id.indexOf(obj.id) === -1)
          pageDataCopy[action?.payload?.storeName]["total"] =
            pageDataCopy[action?.payload?.storeName]["total"] -
            action.payload.id.length
        } else {
          pageDataCopy[action?.payload?.storeName].data = pageDataCopy[
            action?.payload?.storeName
          ].data.filter(function (obj) {
            return obj.id !== action.payload.id
          })
          pageDataCopy[action?.payload?.storeName]["total"] =
            pageDataCopy[action?.payload?.storeName]["total"] - 1
        }
        state.pageData = pageDataCopy
      })

      //EDIT on Widget
      .addCase(putDataWidget.pending, (state, action) => {
        let pageDataCopy = { ...state.pageData }
        if (pageDataCopy[action.meta?.arg?.storeName]) {
          pageDataCopy[action.meta?.arg?.storeName]["statusAddEdit"] = "loading"
          pageDataCopy[action.meta?.arg?.storeName]["isLoadingAddEdit"] = true
        } else {
          pageDataCopy[action.meta?.arg?.storeName] = {
            statusAddEdit: "loading",
            isLoadingAddEdit: true
          }
        }
        state.pageData = pageDataCopy
      })
      .addCase(putDataWidget.fulfilled, (state, action) => {
        // console.log(action.payload)
        let pageDataCopy = { ...state.pageData }
        pageDataCopy[action.payload?.storeName]["statusAddEdit"] = "succeeded"
        pageDataCopy[action.payload?.storeName]["isLoadingAddEdit"] = false
        state.pageData = pageDataCopy
      })
      .addCase(putDataWidget.rejected, (state, action) => {
        // console.log(action)
        if (action.payload) {
          let pageDataCopy = { ...state.pageData }
          pageDataCopy[action.payload?.storeName]["statusAddEdit"] = "failed"
          pageDataCopy[action.payload?.storeName]["isLoadingAddEdit"] = false
          // pageDataCopy[action.payload?.storeName]["errorAddEdit"] =
          //   action.payload?.message
          state.pageData = pageDataCopy
        }
      })

      //posting on Widget
      .addCase(postDataWidget.pending, (state, action) => {
        let pageDataCopy = { ...state.pageData }
        if (pageDataCopy[action.meta?.arg?.storeName]) {
          pageDataCopy[action.meta?.arg?.storeName]["statusAddEdit"] = "loading"
          pageDataCopy[action.meta?.arg?.storeName]["isLoadingAddEdit"] = true
        } else {
          pageDataCopy[action.meta?.arg?.storeName] = {
            statusAddEdit: "loading",
            isLoadingAddEdit: true
          }
        }
        state.pageData = pageDataCopy
      })
      .addCase(postDataWidget.fulfilled, (state, action) => {
        // console.log(action.payload)
        let pageDataCopy = { ...state.pageData }
        pageDataCopy[action.payload?.storeName]["statusAddEdit"] = "succeeded"
        pageDataCopy[action.payload?.storeName]["isLoadingAddEdit"] = false
        state.pageData = pageDataCopy
      })
      .addCase(postDataWidget.rejected, (state, action) => {
        // console.log(action)
        if (action.payload) {
          let pageDataCopy = { ...state.pageData }
          pageDataCopy[action.payload?.storeName]["statusAddEdit"] = "failed"
          pageDataCopy[action.payload?.storeName]["isLoadingAddEdit"] = false
          // pageDataCopy[action.payload?.storeName]["errorAddEdit"] =
          //   action.payload?.message
          state.pageData = pageDataCopy
        }
      })

      ////FROM NOTIFICATION
      .addCase(updateCurrentdata.fulfilled, (state, action) => {
        // console.log(action)
        // let pageDataCopy = { ...current(state).pageData }
        let pageDataCopy = JSON.parse(JSON.stringify(current(state).pageData))
        if (pageDataCopy[action.payload?.storeName]) {
          // console.log(pageDataCopy[action.payload?.storeName].data)
          const field = action.payload["field"]
          const file = action.payload["file"]
          // console.log(action.payload["id"], field, file)
          if (action.payload?.storeName === "_admin__unit") {
            // let pageDataCopyUnit = JSON.stringify(current(state).pageData)
            // console.log(pageDataCopyUnit)
            pageDataCopy[action.payload?.storeName]["data"] = [
              replaceLogo(
                pageDataCopy?.[action.payload?.storeName]?.data[0],
                file,
                parseInt(action.payload["id"])
              )
            ]
            // console.log(pageDataCopy[action.payload?.storeName].data)
          } else {
            const objIndex = pageDataCopy[
              action.payload?.storeName
            ]?.data?.findIndex(
              (obj) => obj.id === parseInt(action.payload["id"])
            )
            // console.log(pageDataCopy[action.payload?.storeName].data)
            // console.log(objIndex, field, file)
            // Check if the data array exists and has the specified index
            if (
              Array.isArray(pageDataCopy[action.payload?.storeName].data) &&
              objIndex >= 0 &&
              objIndex < pageDataCopy[action.payload?.storeName].data.length
            ) {
              let objIndexObj =
                pageDataCopy[action.payload?.storeName].data[objIndex]

              if (objIndexObj?.[field]) {
                objIndexObj[field] = file
              } else if (objIndexObj?.attributes) {
                objIndexObj.attributes[field] = file
              } else if (field === "appLogo") {
                objIndexObj.logo[field] = file
              }
            } else {
              // Handle the case where the data array or index is invalid
              console.error("Invalid data array or index")
            }
          }
        } else {
          // Handle the case where pageDataCopy[action.payload?.storeName] is undefined
          console.error("pageDataCopy[action.payload?.storeName] is undefined")
        }

        if (
          pageDataCopy[action.payload?.storeName].selectedData?.id ===
          parseInt(action.payload["id"])
        ) {
          pageDataCopy[action.payload?.storeName].selectedData[
            action.payload["field"]
          ] = action.payload["file"]
        }
        state.pageData = pageDataCopy
      })

      /////FOR TREE MODE
      .addCase(setDataList.fulfilled, (state, action) => {
        // console.log(action.payload)
        if (!state.pageData[action.payload?.storeName]) {
          state.pageData[action.payload?.storeName] = {
            data: action.payload.data,
            status: true
          }
        } else {
          state.pageData[action.payload?.storeName].data = action.payload.data
          if (action.payload.dataTempTree) {
            state.pageDataTempFilterTree[action.payload?.storeName] =
              action.payload.dataTempTree
          }
        }
      })

      /////FOR STORED DYNAMIC COLUMNS FILTERS DATA
      .addCase(setDynamicFiltersData.fulfilled, (state, action) => {
        if (!state.pageData[action.payload?.storeName]) {
          state.pageData[action.payload?.storeName] = {
            dynamicColumnsFilterData: action.payload.data
          }
        } else {
          state.pageData[action.payload?.storeName].dynamicColumnsFilterData =
            action.payload.data
        }
      })

      //////SET MODAL IMPORT SHOW
      .addCase(setModalImport.fulfilled, (state, action) => {
        if (
          state.showModalImport &&
          state.showModalImport[action.payload?.rowdata?.storeName] ===
            undefined
        ) {
          state.showModalImport = {
            ...state.showModalImport,
            [action.payload?.rowdata?.storeName]: true
          }
        } else {
          state.showModalImport[action.payload?.rowdata?.storeName] =
            !state.showModalImport[action.payload?.rowdata?.storeName]
        }
      })
  }
})

// export const { setPageAttributes } = StoreSlice.actions
export default StoreSlice.reducer
