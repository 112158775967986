import {
  getDepthValue,
  neutralizeBack,
  revivalBack,
  isNestedObjectArray,
  flattenArray,
  arrayToNested,
  updateExpandedToFalse
} from "@src/utility/Utils"
import { AbilityContext } from "@src/utility/context/Can"
import debounce from "lodash.debounce"
import {
  Fragment,
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useState,
  useRef
} from "react"
import UILoader from "@components/ui-loader"
import {
  ErrorFetchData,
  Loader,
  NoDataDisplay,
  TableSkeletonLoading
} from "@src/shared/CommonComponents"
import { Edit2, Info, Trash2, X } from "react-feather"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import ModalAddEdit from "./modal"
import {
  deleteData,
  deleteSelectedRowData,
  getDataById,
  getDataList,
  putDataTree,
  resetSelectedData,
  setDataList,
  setSelectedData,
  expandCollapseAllTree,
  setViewOnlyTree,
  setOrgChartData,
  setOrgChartData2,
  updateOrgChartData,
  postData,
  setOrgChartDataProp
} from "./store"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Skeleton } from "@mui/material"
import SortableTree, {
  toggleExpandedForAll
} from "@nosferatu500/react-sortable-tree"
import { ThemeColors } from "@src/utility/context/ThemeColors"
import DynamicRow from "@src/utility/generateForm/dynamicRow"
import { renderIcon } from "@src/views/apps/menu/store/my_menu"
import "@styles/react/libs/react-select/_react-select.scss"
import "@styles/react/libs/tables/react-dataTable-component.scss"
import toast from "react-hot-toast"
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  UncontrolledButtonDropdown,
  UncontrolledTooltip
} from "reactstrap"
import Swal from "sweetalert2"
import withReactContent from "sweetalert2-react-content"
import { useSkin } from "@hooks/useSkin"
import { Fullscreen, FullscreenExit } from "@mui/icons-material"
import CustomHeader from "./CustomHeaders"
const MySwal = withReactContent(Swal)
const BootstrapCheckbox = forwardRef((props, ref) => (
  <div className="form-check">
    <Input type="checkbox" ref={ref} {...props} />
  </div>
))
import { ReactDiagram } from "gojs-react"
import * as go from "gojs"
import DynamicForm from "../../utility/generateForm/dynamicForm"

let layoutsTemplate = {
  default2: {
    treeStyle: go.TreeLayout.StyleLastParents,
    arrangement: go.TreeLayout.ArrangementHorizontal,
    angle: 90,
    layerSpacing: 35,

    // alternateAngle: 0,
    // alternateLayerSpacing: 35,
    // alternateAlignment: go.TreeLayout.AlignmentStart,
    // alternateNodeIndent: 10,
    // alternateNodeIndentPastParent: 1.0,
    // alternateNodeSpacing: 10,
    // alternateLayerSpacingParentOverlap: 1.0,
    // alternatePortSpot: new go.Spot(0.01, 1, 10, 0),
    // alternateChildPortSpot: go.Spot.Left,

    // properties for the "last parents":
    alternateAngle: 90,
    alternateLayerSpacing: 35,
    alternateAlignment: go.TreeLayout.AlignmentCenterChildren,
    alternateNodeSpacing: 20
  },
  default3: {
    treeStyle: go.TreeLayout.StyleLastParents,
    arrangement: go.TreeLayout.ArrangementHorizontal,
    angle: 90,
    layerSpacing: 35,
    // properties for the "last parents":
    alternateAngle: 90,
    alternateLayerSpacing: 35,
    alternateAlignment: go.TreeLayout.AlignmentBus,
    alternateNodeSpacing: 20
  },
  default: {
    treeStyle: go.TreeLayout.StyleLastParents,
    // layerStyle: go.TreeLayout.LayerIndividual,
    arrangement: go.TreeLayout.ArrangementHorizontal,
    // alignment: go.TreeLayout.AlignmentCenterChildren,
    angle: 90,
    layerSpacing: 35,
    // nodeIndent: 0,
    // setsChildPortSpot: true,
    // setsPortSpot: true,
    // properties for the "last parents":
    alternateAngle: 0,
    alternateLayerSpacing: 35,
    alternateAlignment: go.TreeLayout.AlignmentStart,
    // alternateAlignment: go.TreeLayout.AlignmentBus,
    alternateNodeIndent: 0,
    alternateNodeIndentPastParent: 1.0,
    alternateNodeSpacing: 20,
    alternateLayerSpacingParentOverlap: 1.0,
    alternatePortSpot: new go.Spot(0.01, 1, 10, 0),
    alternateChildPortSpot: go.Spot.Left
  },
  left: {
    treeStyle: go.TreeLayout.StyleLastParents,
    arrangement: go.TreeLayout.ArrangementHorizontal,
    angle: 0,
    alternateAngle: 0,
    alternateAlignment: go.TreeLayout.AlignmentCenterChildren
  },
  right: {
    treeStyle: go.TreeLayout.StyleLastParents,
    arrangement: go.TreeLayout.ArrangementHorizontal,
    angle: 180,
    alternateAngle: 180,
    alternateAlignment: go.TreeLayout.AlignmentCenterChildren
  }
}

// Fungsi untuk memeriksa apakah node adalah asisten
function isAssistant(n) {
  if (n === null) return false
  return n.data.isAssistant
}

class SideTreeLayout extends go.TreeLayout {
  makeNetwork(coll) {
    const net = super.makeNetwork(coll)
    const vertexcoll = new go.Set(/*go.TreeVertex*/)
    vertexcoll.addAll(net.vertexes)
    for (const it = vertexcoll.iterator; it.next(); ) {
      const parent = it.value
      let acount = 0
      const ait = parent.destinationVertexes
      while (ait.next()) {
        if (isAssistant(ait.value.node)) acount++
      }
      if (acount > 0) {
        const asstedges = new go.Set(/*go.TreeEdge*/)
        const childedges = new go.Set(/*go.TreeEdge*/)
        let eit = parent.destinationEdges
        while (eit.next()) {
          const e = eit.value
          if (isAssistant(e.toVertex.node)) {
            asstedges.add(e)
          } else {
            childedges.add(e)
          }
        }

        // Remove all edges from parent
        eit = asstedges.iterator
        while (eit.next()) {
          parent.deleteDestinationEdge(eit.value)
        }
        eit = childedges.iterator
        while (eit.next()) {
          parent.deleteDestinationEdge(eit.value)
        }

        // Create dummy node if needed for spacing
        if (acount % 2 === 1) {
          const dummy = net.createVertex()
          net.addVertex(dummy)
          net.linkVertexes(parent, dummy, asstedges.first().link)
        }

        // Re-add assistant edges
        eit = asstedges.iterator
        while (eit.next()) {
          parent.addDestinationEdge(eit.value)
        }

        // Create a substitute vertex for regular children
        const subst = net.createVertex()
        net.addVertex(subst)

        // Reparent regular children to the substitute vertex
        eit = childedges.iterator
        while (eit.next()) {
          const ce = eit.value
          ce.fromVertex = subst
          subst.addDestinationEdge(ce)
        }

        // Link substitute vertex to the parent
        net.linkVertexes(parent, subst, null)
      }
    }
    return net
  }

  assignTreeVertexValues(v) {
    let any = false
    const children = v.children
    for (let i = 0; i < children.length; i++) {
      const c = children[i]
      if (isAssistant(c.node)) {
        any = true
        break
      }
    }
    if (any) {
      const currentViewAngle =
        localStorage.getItem("orgChartCurrentViewAngle") ?? "default"
      const currentLayoutsActive =
        layoutsTemplate[currentViewAngle ?? "default"]
      Object.keys(currentLayoutsActive).forEach((key) => {
        v[key] = currentLayoutsActive[key]
      })

      if (currentViewAngle === "left" || currentViewAngle === "right") {
        // v.arrangement = go.TreeLayout.ArrangementHorizontal
        v.alignment = go.TreeLayout.AlignmentStart
      } else {
        v.alignment = go.TreeLayout.AlignmentBus
      }
      //  // Menyesuaikan alignment untuk asisten di samping
      // v.nodeSpacing = 30
      v.layerSpacing = 20 // Memberi jarak yang cukup antara node asisten dan node lainnya
      // // Adjust layout for assistant nodes to not overlap with non-assistant nodes
      // v.spacing = new go.Size(0, 40) // Ensure vertical spacing
    } else if (v.node === null && v.childrenCount > 0) {
      const currentViewAngle = "default"
      const currentLayoutsActive =
        layoutsTemplate[currentViewAngle ?? "default"]
      Object.keys(currentLayoutsActive).forEach((key) => {
        v[key] = currentLayoutsActive[key]
      })
      // found the substitute parent for non-assistant children
      v.alignment = go.TreeLayout.AlignmentCenterChildren
      // v.breadthLimit = 3000
      // v.layerSpacing = 150
    } else {
      // console.log("here")
      // const currentViewAngle = "default"
      // const currentLayoutsActive = layoutsTemplate[currentViewAngle]
      // Object.keys(currentLayoutsActive).forEach((key) => {
      //   v[key] = currentLayoutsActive[key]
      // })
      // v.nodeSpacing = 60
      // v.layerSpacing = 150
      // v.alignment = go.TreeLayout.AlignmentCenterChildren
      // v.alternateLayerSpacing =
      //   layoutsTemplate[currentViewAngle ?? "default"]?.alternateLayerSpacing ??
      //   250
      // v.layerSpacing =
      //   layoutsTemplate[currentViewAngle ?? "default"]?.layerSpacing ?? 35
    }
  }

  commitLinks() {
    super.commitLinks()
    const eit = this.network.edges.iterator
    while (eit.next()) {
      const e = eit.value
      if (e.link === null) continue
      const r = e.link
      const subst = e.fromVertex
      if (subst.node === null && r.routing === go.Link.Orthogonal) {
        r.updateRoute()
        r.startRoute()
        const p1 = subst.center
        const p2 = r.getPoint(2).copy()
        const p3 = r.getPoint(3).copy()
        const p5 = r.getPoint(r.pointsCount - 1)
        let dist = 10
        if (subst.angle === 270 || subst.angle === 180) dist = -20
        if (subst.angle === 90 || subst.angle === 270) {
          p2.y = p5.y - dist
          p3.y = p5.y - dist
        } else {
          p2.x = p5.x - dist
          p3.x = p5.x - dist
        }
        r.setPoint(2, p2)
        r.setPoint(3, p3)
        r.commitRoute()
      }
    }
  }
}

const OrgChart3 = ({ pageAttr, storeName, filtersCustomAdd }) => {
  const { moduleId, pageId, id, relPageId, relPageType, relId } = useParams()

  const dispatch = useDispatch()
  const store = useSelector((state) => state.dynamicStore)
  const orgChartData = useSelector(
    (state) => state.dynamicStore?.pageData[storeName]?.OrgChartData
  )
  const [diagramModel, setDiagramModel] = useState(null)
  const { t } = useTranslation()
  const ability = useContext(AbilityContext)

  const [filteredData, setFilteredData] = useState([])

  const { colors } = useContext(ThemeColors)
  const [sort_dir, setSortDir] = useState("desc")
  const [searchTerm, setSearchTerm] = useState("")
  const [currentPage, setCurrentPage] = useState(store.params?.page ?? 1)
  const [sort, setSort] = useState("id")
  const [rowsPerPage, setRowsPerPage] = useState(store.params?.length ?? 10)
  const [selectedRows, setSelectedRows] = useState([])

  const [filtersCustom, setFiltersCustom] = useState(filtersCustomAdd ?? {})

  const [toggleCleared, setToggleCleared] = useState(false)

  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [expandedAll, setExpandedAll] = useState(true)
  const [loading, setLoading] = useState(true)
  const [loadingModal, setLoadingModal] = useState(true)
  const [loadingPostPut, setLoadingPostPut] = useState(false)
  const [loadingDelete, setLoadingDelete] = useState(false)
  const [loadingText, setLoadingText] = useState("Please Wait")
  const [showModal, setShowModal] = useState(false)
  const [pageType, setPageType] = useState(null)
  const navigate = useNavigate()
  const location = useLocation()
  const diagramRef = useRef(null)
  const idField = pageAttr?.crudOpt?.treeviewIdField ?? "id"
  const parentIdField = pageAttr?.crudOpt?.treeviewParentIdField ?? "parent_id"
  const titleField = pageAttr?.crudOpt?.treeviewTitleField ?? "title" ?? "name"
  const codeField = pageAttr?.crudOpt?.treeviewAddSubIncrementField
  const indexField = pageAttr?.crudOpt?.treeviewIndexField ?? "index" ?? "order"
  const iconField = pageAttr?.crudOpt?.treeviewIconField ?? "icon"
  const treeviewStylesByDepth = pageAttr?.crudOpt?.treeviewStylesByDepth
  const [newDataTree, setNewDataTree] = useState({})
  const [selectedDataTree, setSelectedDataTree] = useState(null)
  const { skin } = useSkin()
  const colorsClass = [
    "primary",
    "info",
    "success",
    "warning",
    "secondary",
    "danger"
  ]

  const [currentColorPreset, setCurrentColorPreset] = useState(
    store.pageData[storeName]?.OrgChartDataProp?.color ??
      pageAttr?.crudOpt?.treeviewInitialColorPreset ??
      "default"
  )

  const [isFullscreen, setIsFullscreen] = useState(false)
  const [oldStyleBackgroundColor, setOldStyleBackgroundColor] = useState("")
  // const [selectedData, setSelectedData] = useState(null)
  const requestFullscreen = () => {
    const element = document.getElementById("org_chart_container")
    if (element.requestFullscreen) {
      element.requestFullscreen()
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen()
    } else if (element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen()
    } else if (element.msRequestFullscreen) {
      element.msRequestFullscreen()
    }

    setIsFullscreen(true)
    if (diagramRef?.current) {
      setTimeout(() => {
        const diagram = diagramRef?.current.getDiagram()
        diagram.commandHandler.zoomToFit()
      }, 200)
    }
  }

  const exitFullscreen = () => {
    if (
      document.fullscreenElement ||
      document.mozFullScreenElement ||
      document.webkitFullscreenElement ||
      document.msFullscreenElement
    ) {
      if (document.exitFullscreen) {
        document.exitFullscreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen()
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen()
      }
    }

    setIsFullscreen(false)
    if (diagramRef?.current) {
      const diagram = diagramRef?.current.getDiagram()
      // diagram.clearSelection()
      diagram.commandHandler.zoomToFit()
    }
  }

  const [currentDiagramZoom, setCurrentDiagramZoom] = useState(
    store.pageData[storeName]?.OrgChartDataProp?.zoom ??
      pageAttr?.crudOpt?.treeviewInitialZoom ??
      "100"
  )
  const zoomSliderChange = (value) => {
    setCurrentDiagramZoom(value)
    // console.log(value)
    if (value) {
      let zoomLevel = parseFloat(value) / 100 // Mengonversi nilai ke persentase
      let diagram = diagramRef?.current.getDiagram() // Mengganti dengan cara Anda mengakses diagram

      if (diagram) {
        diagram.scale = zoomLevel // Mengatur skala diagram sesuai dengan slider
      }

      dispatch(
        setOrgChartDataProp({
          data: { zoom: value },
          storeName: storeName
        })
      )
    }
  }

  const errorToast = (res) => {
    let errorFieldsMsg = ""
    if (res?.payload?.error?.errors) {
      const errorFields = res?.payload?.error?.errors
      for (let errorx in res?.payload?.error?.errors) {
        errorFieldsMsg = `${errorx} : ${errorFieldsMsg} - ${errorFields[errorx]}\n`
        // console.log(errorx, errorFields[errorx])
      }
    }
    return toast.error(
      `${res.payload?.error?.message} \n \n ${errorFieldsMsg}`,
      {
        style: {
          padding: "16px",
          color: colors.danger.main,
          border: `1px solid ${colors.danger.main}`
        },
        iconTheme: {
          primary: colors.danger.main
        }
      }
    )
  }

  const successToast = () => {
    const msg =
      pageType !== "add" ? t("Successfully Updated") : t("Successfully Created")
    return toast.success(`${msg} ${t(pageAttr?.pageTitle)}`, {
      style: {
        padding: "16px",
        color: colors.success.main,
        border: `1px solid ${colors.success.main}`
      },
      iconTheme: {
        primary: colors.success.main
      }
    })
  }

  const defaultPayload = {
    storeName: storeName,
    endpointName: pageAttr?.crudOpt?.endpointName,
    baseUrl: pageAttr?.crudOpt?.baseUrl,
    callback_page: window.location.href
  }

  const postDataToApi = async (data) => {
    data = { ...data, ...defaultPayload }
    data["isFromModal"] = true
    if (filtersCustomAdd) {
      data = { ...data, ...filtersCustomAdd }
    }
    data["isTemporary"] = pageAttr?.isFromAddPage
    if (!pageAttr?.isFromAddPage) {
      setLoadingText(`${t(`Creating a New ${pageAttr?.pageTitle}`)}`)
      setLoadingPostPut(true)
    }
    let result = {}
    await dispatch(postData(data))
      .then((res) => {
        // console.log(res)
        result = { ...(res?.payload?.data?.data ?? {}) }
        setLoadingPostPut(false)
        if (res.meta.requestStatus === "fulfilled") {
          successToast()
        } else if (res.meta.requestStatus === "rejected") {
          errorToast(res)
        }
      })
      .catch((error) => {
        console.error(error)
        errorToast(error)
      })

    return result
  }

  const updateDataToApi = (data, notReload = false) => {
    data["notReload"] = notReload
    data["storeName"] = storeName
    data["endpointName"] = pageAttr?.crudOpt?.endpointName
    setLoadingText(
      `${t("Updating")} ${t(pageAttr?.pageTitle)} ${t("Detail")} Data`
    )
    setLoadingPostPut(!loadingPostPut)
    dispatch(putDataTree(data))
      .then((res) => {
        setLoadingPostPut(!loadingPostPut)
        if (res.meta.requestStatus === "fulfilled") {
          setShowModal(false)
          toast.success(
            `${t("Successfully Updated")} ${t(pageAttr?.pageTitle)}`,
            {
              style: {
                padding: "16px",
                color: colors.success.main,
                border: `1px solid ${colors.success.main}`
              },
              iconTheme: {
                primary: colors.success.main
              }
            }
          )
        } else if (res.meta.requestStatus === "rejected") {
          errorToast(res)
        }
      })
      .catch((error) => {
        console.error(error)
        errorToast(error)
      })
  }

  const handleKeyDown = (event) => {
    if (event.keyCode === 27) {
      exitFullscreen()
    }
  }

  useEffect(() => {
    // console.log(orgChartData)
    if (
      (!store.pageData[storeName]?.data ||
        !orgChartData ||
        pageAttr?.crudOpt?.getAlwaysGrid) &&
      !store.pageData[storeName]?.isLoading &&
      store.pageData[storeName]?.status !== "failed"
    ) {
      setLoading(true)
      setLoadingText(`${t("Loading Data")} ${t(pageAttr.pageTitle)}`)

      dispatch(
        pageAttr?.crudOpt?.serverSideGrid
          ? getDataList({
              storeName,
              endpointName: pageAttr?.crudOpt?.endpointName,
              sort_dir,
              sort,
              search: searchTerm,
              page: currentPage,
              length: rowsPerPage,
              ...filtersCustom
            })
          : getDataList({
              storeName,
              endpointName: pageAttr?.crudOpt?.endpointName,
              ...filtersCustom
            })
      ).then((x) => {
        // console.log(x?.payload?.data)
        dispatch(
          setOrgChartData({ data: x?.payload?.data, storeName: storeName })
        ).then(() => {
          setLoading(false)
        })
        // checkAndTransormData(x?.payload?.data)
      })
    } else {
      //////CACHE ON RELATIONS CRUD
      if (relPageId || pageAttr?.isViewOnForm) {
        // console.log(store.pageData[storeName]?.params)
        // console.log(pageAttr?.crudOpt?.initialFiltersCondition)
        const lastParentData = storeParent?.selectedData
        let curPageLastParam = store.pageData[storeName]?.params
        let curPageAttrPageFilArray = pageAttr?.crudOpt?.initialFiltersCondition
        let reloadState = false

        if (curPageAttrPageFilArray) {
          if (curPageAttrPageFilArray?.length > 1 && lastParentData) {
            curPageAttrPageFilArray?.map((childPar) => {
              // console.log(childPar?.fieldNameCurrent)
              // console.log(lastParentData[childPar?.value_from_parentFieldName])
              reloadState =
                reloadState ||
                `${curPageLastParam[curPageAttrPageFilField]}` !==
                  `${lastParentData[childPar?.value_from_parentFieldName]}`
            })
          } else {
            const curPageAttrPageFilField =
              curPageAttrPageFilArray[0]?.fieldNameCurrent
            // console.log(
            //   curPageAttrPageFilField,
            //   `${curPageLastParam[curPageAttrPageFilField]}`,
            //   `${id}`
            // )
            reloadState =
              `${curPageLastParam?.[curPageAttrPageFilField]}` !== `${id}`
          }
        }
        if (reloadState) {
          if (!store.pageData[storeName]?.isLoading) {
            dispatch(
              pageAttr?.crudOpt?.serverSideGrid
                ? getDataList({
                    storeName,
                    endpointName: pageAttr?.crudOpt?.endpointName,
                    sort_dir,
                    sort,
                    search: searchTerm,
                    page: currentPage,
                    length: rowsPerPage,
                    ...filtersCustom
                  })
                : getDataList({
                    storeName,
                    endpointName: pageAttr?.crudOpt?.endpointName,
                    ...filtersCustom
                  })
            ).then((x) => {
              // console.log(x)
              dispatch(
                setOrgChartData({
                  data: x?.payload?.data,
                  storeName: storeName
                })
              ).then(() => {
                setLoading(false)
              })
            })
          }
        }
      }

      // checkAndTransormData(store.pageData[storeName]?.data)
      setLoading(false)

      document.addEventListener("keydown", handleKeyDown)
      return () => {
        document.removeEventListener("keydown", handleKeyDown)
      }
    }
  }, [store.pageData[storeName]?.data])

  const [viewOnly, setViewOnly] = useState(null)
  const toggleModalView = () => {
    setViewOnlyToStore(null)
    setViewOnly(null)
  }

  function addIsViewOnlyToAll(data) {
    function recursivelyAddField(item) {
      item["isViewOnly"] = true
      if (item.children && Array.isArray(item.children)) {
        item.children.forEach(recursivelyAddField)
      }
    }
    recursivelyAddField(data)
    return data
  }

  const setViewOnlyToStore = async (data) => {
    let newData = data
    if (data) {
      let newData = { ...data }
      newData[parentIdField] = null
      addIsViewOnlyToAll(newData)
      newData["isViewOnly"] = true
    }

    dispatch(setViewOnlyTree({ data: newData, storeName: storeName }))
      .then((res) => {
        // console.log(res)
        if (res.meta.requestStatus === "fulfilled") {
          dispatch(
            setOrgChartData({
              data: newData
                ? [newData]
                : store.pageDataTempFilterTree[storeName],
              storeName: storeName
            })
          )
        } else if (res.meta.requestStatus === "rejected") {
          errorToast(res)
        }
      })
      .catch((error) => {
        console.error(error)
        errorToast(error)
      })
  }

  // useEffect(() => {
  //   if (diagramRef.current) {
  //     const diagram = diagramRef.current.getDiagram()
  //     // console.log(diagram)
  //     // if (diagram instanceof go.Diagram) {
  //     //   diagram.addDiagramListener("ChangedSelection", handleDiagramEvent)
  //     // }

  //     // return () => {
  //     //   if (diagram instanceof go.Diagram) {
  //     //     diagram.removeDiagramListener("ChangedSelection", handleDiagramEvent)
  //     //     diagram.div = null
  //     //   }
  //     // }
  //     diagram.layout = go.GraphObject.make(go.TreeLayout, currentViewAngle)
  //     diagram.layoutDiagram()
  //     diagram.commandHandler.zoomToFit()
  //     // changeCurrentViewAngle(currentViewAngle)
  //   }
  // }, [diagramRef.current])

  useEffect(() => {
    // console.log(pageType)
    if (diagramRef.current && orgChartData && pageType !== "add") {
      const diagram = diagramRef.current.getDiagram()
      // diagram.skipAnimation = true
      if (diagram) {
        const model = diagram.model
        model.startTransaction("updateDiagram")

        const copiedNodeDataArray = JSON.parse(
          JSON.stringify(orgChartData.nodeDataArray)
        )
        const copiedLinkDataArray = JSON.parse(
          JSON.stringify(orgChartData.linkDataArray)
        )
        model.nodeDataArray = copiedNodeDataArray
        model.linkDataArray = copiedLinkDataArray

        model.commitTransaction("updateDiagram")

        if (currentColorPreset && currentColorPreset !== "default") {
          changeCurrentColorPreset(currentColorPreset)
        }
        diagram.delayInitialization(() => {
          diagram.layoutDiagram(true)
        })
      }
    }
  }, [orgChartData, diagramRef])

  const onModelChange = (e) => {
    // setSelectedDataTree(null)
    // Handle diagram event here
    console.log("Model Change:", e)
    // let obj = e
    // const insertedNodeKeys = obj.insertedNodeKeys
    // const modifiedNodeData = obj.modifiedNodeData
    // const removedNodeKeys = obj.removedNodeKeys
    // const insertedLinkKeys = obj.insertedLinkKeys
    // const modifiedLinkData = obj.modifiedLinkData
    // const removedLinkKeys = obj.removedLinkKeys
    // const modifiedModelData = obj.modelData

    // console.log(obj)
    // if (e?.model?.nodeDataArray) {
    //   setNewDataTree({
    //     nodeDataArray: e.model.nodeDataArray,
    //     linkDataArray: e.model.linkDataArray
    //   })
    // }

    // setShowModal(false)

    // const diagram = diagramRef.current.getDiagram()
    // if (diagram instanceof go.Diagram) {
    //   let node = diagram.selection.first()
    //   if (node instanceof go.Node) {
    //     setSelectedDataTree(node.data)
    //     setShowModal(true)
    //     // console.log(node.data)
    //     // updateProperties(node.data)
    //   } else {
    //     // dispatch(resetSelectedData(storeName))
    //     // updateProperties(null)
    //   }
    // }
  }

  const codeIncrement = (dataAll, rowData, new_parent_id) => {
    let dataAllParse = JSON.parse(dataAll)?.nodeDataArray
    ////CODE INCREMENT
    let result
    if (codeField) {
      // console.log(rowData, new_parent_id)
      let dataAllFilter = dataAllParse?.filter(
        (item) => item?.[parentIdField] === new_parent_id
      )
      // console.log(dataAllFilter)
      const oldValuesArr = dataAllFilter?.map((obj) => obj[codeField]) ?? []
      // console.log(oldValuesArr)
      let maxValue = 1
      if (oldValuesArr?.length > 0) {
        oldValuesArr.map((item) => {
          if (item?.includes(".")) {
            const newDataFieldArr = item.split(".")
            if (newDataFieldArr.length > 0) {
              const oldNumber =
                newDataFieldArr.pop() !== ""
                  ? newDataFieldArr.pop()
                  : newDataFieldArr[newDataFieldArr.length - 1]
              // console.log(oldNumber)
              const inc = parseInt(oldNumber ?? "0", 10)
              if (inc > maxValue) {
                maxValue = inc
              }
              const newInc = String(inc + 1).padStart(
                pageAttr.crudOpt?.treeviewAddSubIncrementLength ??
                  (oldNumber?.length > 0 ? oldNumber?.length : 2) ??
                  2,
                "0"
              )
              result = `${rowData[codeField]}.${newInc}.`.replace("..", ".")
            }
          }
        })
      } else {
        const newInc = String(maxValue).padStart(
          pageAttr.crudOpt?.treeviewAddSubIncrementLength ?? 2,
          "0"
        )
        result = `${rowData[codeField]}.${newInc}.`.replace("..", ".")
      }
    }
    return result
  }

  function expandAllNodes() {
    let newState = !expandedAll
    setExpandedAll(newState)
    setCurrentViewLevel(null)
    // console.log(newState)
    const diagram = diagramRef?.current.getDiagram()
    diagram.nodes.each(function (n) {
      // console.log(n.data)

      if (newState) {
        // n.isTreeExpanded = true
        n.expandTree()
      } else {
        // n.isTreeExpanded = false
        n.collapseTree()
      }
      // console.log(currentColorPreset)
      // if (currentColorPreset && currentColorPreset !== "default") {
      //   changeNodeColor(n, currentColorPreset)
      // }
    })

    if (currentColorPreset && currentColorPreset !== "default") {
      changeCurrentColorPreset(currentColorPreset)
    }

    // diagram.commandHandler.zoomToFit()
  }

  const [currentViewLevel, setCurrentViewLevel] = useState()
  function collapseExpandByLevel(levelSelect = 1) {
    setExpandedAll(false)
    setCurrentViewLevel(levelSelect)
    // console.log(levelSelect)
    const diagram = diagramRef?.current.getDiagram()
    diagram.findTreeRoots().each((root) => {
      Array.from({ length: levelSelect }, (_, index) => index + 1).forEach(
        (lvl) => {
          if (lvl < levelSelect) {
            root.expandTree(levelSelect)
          } else {
            root.collapseTree(levelSelect)
          }
        }
      )
    })

    if (currentColorPreset && currentColorPreset !== "default") {
      changeCurrentColorPreset(currentColorPreset)
    }

    // diagram.commandHandler.zoomToFit()
  }

  const [currentViewAngle, setCurrentViewAngle] = useState(
    store.pageData[storeName]?.OrgChartDataProp?.layout ??
      pageAttr?.crudOpt?.treeviewInitialLayoutTemplate ??
      "default"
  )

  function changeCurrentViewAngle(value) {
    setCurrentViewAngle(value)
    dispatch(
      setOrgChartDataProp({
        data: { layout: value },
        storeName: storeName
      })
    )
    localStorage.setItem("orgChartCurrentViewAngle", value)

    let layout = layoutsTemplate[value ?? "default"]
    const diagram = diagramRef?.current.getDiagram()
    // diagram.layout = go.GraphObject.make(go.TreeLayout, layout)
    diagram.layout = go.GraphObject.make(SideTreeLayout, layout)
    diagram.layoutDiagram()

    if (currentColorPreset && currentColorPreset !== "default") {
      changeCurrentColorPreset(currentColorPreset)
    }
    // diagram.commandHandler.zoomToFit()
  }

  const [currentLinkLineType, setCurrentLinkLineType] = useState(
    store.pageData[storeName]?.OrgChartDataProp?.linkLineType ??
      pageAttr?.crudOpt?.treeviewInitialLinkLineType ??
      "default"
  )

  const linkLineType = {
    default: {
      routing: go.Link.Orthogonal,
      corner: 5,
      curve: go.Link.None
    },
    normal: {
      routing: go.Link.Normal,
      curve: go.Link.None
    },
    bezier: {
      routing: go.Link.Normal,
      curve: go.Link.Bezier
    },
    bezier: {
      routing: go.Link.Normal,
      curve: go.Link.Bezier
    },
    jumpOver: {
      routing: go.Link.Orthogonal,
      curve: go.Link.JumpOver
    },
    avoidsNodes: {
      routing: go.Link.AvoidsNodes,
      curve: go.Link.Bezier
    },
    chain: {
      routing: go.Link.Chain,
      curve: go.Link.None
    }
  }
  function changeCurrentLinkLineType(e, selectedType) {
    if (e) {
      e.preventDefault()
    }
    setCurrentLinkLineType(selectedType)
    const diagram = diagramRef?.current.getDiagram()
    diagram.startTransaction("changeLinkType")
    diagram.links.each(function (link) {
      // return { ...link, ...linkLineType[selectedType] }
      link.routing = linkLineType[selectedType].routing
      link.curve = linkLineType[selectedType].curve
      // if (selectedType === "default") {
      //   link.routing = go.Link.Orthogonal
      //   link.corner = 5
      //   link.curve = go.Link.None
      // } else if (selectedType === "normal") {
      //   link.routing = go.Link.Normal
      //   link.curve = go.Link.None
      // } else if (selectedType === "bezier") {
      //   link.routing = go.Link.Normal
      //   link.curve = go.Link.Bezier
      // } else if (selectedType === "jumpOver") {
      //   link.routing = go.Link.Orthogonal
      //   link.curve = go.Link.JumpOver
      // } else if (selectedType === "avoidsNodes") {
      //   link.routing = go.Link.AvoidsNodes
      //   link.curve = go.Link.Bezier
      // }
      // else if (selectedType === "chain") {
      //   link.routing = go.Link.Chain
      //   // link.curve = go.Link.None
      // }
    })
    diagram.commitTransaction("changeLinkType")

    if (currentColorPreset && currentColorPreset !== "default") {
      changeCurrentColorPreset(currentColorPreset)
    }
    // diagram.commandHandler.zoomToFit()
    dispatch(
      setOrgChartDataProp({
        data: { linkLineType: selectedType },
        storeName: storeName
      })
    )
  }

  const [currentViewOverview, setCurrentViewOverview] = useState(
    store.pageData[storeName]?.OrgChartDataProp?.overview ??
      pageAttr?.crudOpt?.treeviewOverviewPosition
  )

  function changeCurrentViewOverview(value) {
    setCurrentViewOverview(value)

    // const diagram = diagramRef?.current.getDiagram()

    dispatch(
      setOrgChartDataProp({
        data: { overview: value },
        storeName: storeName
      })
    )
  }

  function onCloseModal(state) {
    setShowModal(false)
    dispatch(resetSelectedData(storeName))
  }

  function onSelectionChanged(e) {
    // console.log("onSelectionChanged")
    // dispatch(resetSelectedData(storeName))
    setShowModal(false)
    let node = e.diagram.selection.first()
    // console.log(node)
    if (node instanceof go.Node) {
      // console.log(node.data)
      updateProperties(node.data)
    } else {
      setShowModal(false)
      dispatch(resetSelectedData(storeName))
      updateProperties(null)
    }
  }

  function updateProperties(data) {
    if (data === null) {
    } else {
      if (!pageAttr?.crudOpt?.detailFromGrid) {
        dispatch(getDataById(data[idField], storeName)).then((res) => {
          setLoadingModal(false)
          setShowModal(true)
        })
      } else {
        dispatch(setSelectedData({ rowData: data, storeName: storeName })).then(
          (res) => {
            setLoadingModal(false)
            setShowModal(true)
          }
        )
      }
    }
  }

  const handleFormChangeFromChild = ({ dataFirst, refDiagram }) => {
    setPageType("edit")
    // console.log(document.getElementById("changedData").value)

    let data = dataFirst
    data["title"] = data?.[titleField]
    dispatch(updateOrgChartData({ data: data, storeName: storeName }))

    const inputElement = document.getElementById("changedData")
    inputElement.value = JSON.stringify(data)
    let event = new Event("input", { bubbles: true })
    inputElement.dispatchEvent(event)

    delete data.__gohashid
    updateDataToApi(data, true)

    // const diagram = refDiagram?.current.getDiagram()
    // const node = diagram.findNodeForKey(data.key)
    // console.log(node)
    // if (node) {
    //   const thisemp = node.data
    //   console.log(thisemp)
    //   console.log(data)
    //   diagram.startTransaction("UpdateNode")
    //   diagram.model.setDataProperty(thisemp, "name", data.name)
    //   diagram.model.setDataProperty(thisemp, "title", data.title)
    //   diagram.commandHandler.scrollToPart(node)
    //   diagram.commitTransaction("UpdateNode")
    // }
    // return true
  }

  let defaultColorSeries = [
    ["#10519d", "#2782ea"],
    ["#521bc6", "#7b51cf"],
    ["#174e54", "#2a9fac"],
    ["#bf4600", "#f98e4e"],
    ["#b22727", "#fc6767"],
    ["rgb(86, 60, 222)", "rgb(123, 81, 207)"],
    ["#e59d20", "#ffa100"],
    ["#183172", "#0332aa"]
  ]

  let presetColors = {
    default: [
      ["#10519d", "#2782ea"],
      ["#521bc6", "#7b51cf"],
      ["#174e54", "#2a9fac"],
      ["#bf4600", "#f98e4e"],
      ["#b22727", "#fc6767"],
      ["rgb(86, 60, 222)", "rgb(123, 81, 207)"],
      ["#e59d20", "#ffa100"],
      ["#183172", "#0332aa"]
    ],
    preset1: [
      ["#1565c0", "#0d47a1"], // Biru gelap ke lebih gelap
      ["#0d47a1", "#0c3c8e"], // Biru lebih gelap
      ["#0c3c8e", "#0b347b"], // Biru sangat gelap
      ["#0b347b", "#092664"], // Nyaris hitam biru
      ["#092664", "#081e53"], // Nyaris hitam biru lebih dalam
      ["#081e53", "#061b44"], // Nyaris hitam sangat dalam
      ["#061b44", "#051633"], // Nyaris hitam sangat sangat dalam
      ["#051633", "#041429"] // Biru paling dalam
    ],
    preset2: [
      ["#388e3c", "#2e7d32"], // Hijau gelap
      ["#2e7d32", "#276729"], // Hijau lebih gelap
      ["#276729", "#1e5620"], // Hijau sangat gelap
      ["#1e5620", "#154415"], // Hijau sangat sangat gelap
      ["#154415", "#0f3310"], // Nyaris hitam hijau
      ["#0f3310", "#0b290c"], // Nyaris hitam hijau lebih dalam
      ["#0b290c", "#072008"], // Nyaris hitam hijau sangat dalam
      ["#072008", "#051b06"] // Hijau paling dalam
    ],
    preset3: [
      ["#f57c00", "#ef6c00"], // Oranye gelap
      ["#ef6c00", "#e55a00"], // Oranye lebih gelap
      ["#e55a00", "#db4a00"], // Oranye sangat gelap
      ["#db4a00", "#c43b00"], // Oranye sangat sangat gelap
      ["#c43b00", "#af3300"], // Nyaris hitam oranye
      ["#af3300", "#9a2b00"], // Nyaris hitam oranye lebih dalam
      ["#9a2b00", "#852300"], // Nyaris hitam oranye sangat dalam
      ["#852300", "#701c00"] // Oranye paling dalam
    ],
    preset4: [
      ["#fafafa", "#f5f5f5"], // Abu-abu sangat terang
      ["#f5f5f5", "#eeeeee"], // Abu-abu terang
      ["#eeeeee", "#e0e0e0"], // Abu-abu terang ke sedang
      ["#e0e0e0", "#bdbdbd"], // Abu-abu sedang
      ["#bdbdbd", "#9e9e9e"], // Abu-abu
      ["#9e9e9e", "#757575"], // Abu-abu gelap
      ["#757575", "#616161"], // Abu-abu lebih gelap
      ["#616161", "#424242"] // Abu-abu paling gelap
    ],
    preset5: [
      ["#ffffff", "#ffffff"], // Putih solid
      ["#ffffff", "#ffffff"], // Putih solid
      ["#ffffff", "#ffffff"], // Putih solid
      ["#ffffff", "#ffffff"], // Putih solid
      ["#ffffff", "#ffffff"], // Putih solid
      ["#ffffff", "#ffffff"], // Putih solid
      ["#ffffff", "#ffffff"], // Putih solid
      ["#ffffff", "#ffffff"] // Putih solid
    ]
  }

  function changeNodeColor(node, presetName, isInit) {
    if (isInit) {
    } else {
    }
    const $ = go.GraphObject.make
    let level = node.data.__level__
    const shape = node.findObject("SHAPE")
    // console.log(shape)
    // console.log(presetName, level)
    if (shape) {
      const background = presetColors[presetName]?.[level]
      // console.log(background)
      if (presetName === "preset5") {
        shape.fill = background?.[0]
      } else {
        shape.fill = $(go.Brush, "Linear", {
          0: background?.[0],
          1: background?.[1] ?? go.Brush.lightenBy(background?.[0], 0.05),
          start: go.Spot.TopLeft,
          end: go.Spot.BottomRight
        })
      }

      shape.stroke = "#000000"
      if (["preset4", "preset5"].includes(presetName)) {
        shape.strokeWidth = 1
      } else {
        shape.stroke = "transparent"
        shape.strokeWidth = 1
      }
    }

    const textBlock = node.findObject("TITLE")
    if (textBlock) {
      if (["preset4", "preset5"].includes(presetName)) {
        const color = "black"
        textBlock.stroke = color
      } else {
        const color = "white"
        textBlock.stroke = color
      }
    }
  }

  function changeCurrentColorPreset(presetName, e) {
    if (e) {
      e.preventDefault()
    }

    if (presetName) {
      setCurrentColorPreset(presetName)
      const diagram = diagramRef?.current.getDiagram()
      diagram.startTransaction("change colors")

      diagram.nodes.each(function (node) {
        if (node) {
          changeNodeColor(node, presetName)
        }
      })
      diagram.commitTransaction("change colors")
      dispatch(
        setOrgChartDataProp({
          data: { color: presetName },
          storeName: storeName
        })
      )
    }
  }

  let stylesByDepth = [
    {
      background: defaultColorSeries[0],
      color: "#fff",
      font: "bold 12pt Roboto,sans-serif",
      minSize: [20]
    },
    {
      background: defaultColorSeries[1],
      color: "#fff",
      font: "bold 12pt Roboto,sans-serif",
      minSize: [20]
    },
    {
      background: defaultColorSeries[2],
      color: "#fff",
      font: "bold 10pt Roboto,sans-serif",
      minSize: [20]
    },
    {
      background: defaultColorSeries[3],
      color: "#fff",
      font: "10pt Roboto,sans-serif",
      minSize: [20]
    },
    {
      background: defaultColorSeries[4],
      color: "#fff",
      font: "10pt Roboto,sans-serif",
      minSize: [20]
    },
    {
      background: defaultColorSeries[5],
      color: "#fff",
      font: "10pt Roboto,sans-serif",
      minSize: [20]
    },
    {
      background: defaultColorSeries[6],
      color: "#fff",
      font: "10pt Roboto,sans-serif",
      minSize: [20]
    },
    {
      background: defaultColorSeries[7],
      color: "#fff",
      font: "10pt Roboto,sans-serif",
      minSize: [20]
    }
  ]

  function mergeArraysOfObjects(styles1, styles2) {
    const mergedStyles = []
    for (let i = 0; i < styles1.length; i++) {
      if (i < styles2.length) {
        mergedStyles.push(Object.assign({}, styles1[i]))
      } else {
        break
      }
    }
    for (let j = mergedStyles.length; j < styles2.length; j++) {
      mergedStyles.push(Object.assign({}, styles2[j]))
    }

    return mergedStyles
  }

  let defaultPictureSize = 40
  const blankImg =
    // "https://res.cloudinary.com/insaba/image/upload/v1706711448/users/avatar/10325_avatar.jpg"
    `https://placehold.co/${defaultPictureSize}?text=No%20Image`

  const contextMenuStyleAtr = {
    "ButtonBorder.figure": "RoundedRectangle",
    "ButtonBorder.fill": "lightgray",
    "ButtonBorder.stroke": "lightgray",
    "ButtonBorder.strokeWidth": 0,
    _buttonFillOver: "#4186E0",
    _buttonStrokeOver: "#4186E0",
    _buttonFillPressed: "#abccf4",
    shadowVisible: true
  }

  const initDiagram = () => {
    const $ = go.GraphObject.make
    const initialLayout = layoutsTemplate[currentViewAngle]
    let graygrad = $(go.Brush, "Linear", {
      0: "rgb(125, 125, 125)",
      0.5: "rgb(86, 86, 86)",
      1: "rgb(86, 86, 86)"
    })

    const mergedStyles = mergeArraysOfObjects(
      treeviewStylesByDepth ?? [],
      stylesByDepth
    )

    // const diagram = $(go.Diagram, {
    let diagram = $(go.Diagram, {
      validCycle: go.Diagram.CycleDestinationTree,
      // autoScale: go.Diagram.Uniform,
      // initialAutoScale: go.Diagram.Uniform,
      // ...(currentDiagramZoom === "100"
      //   ? { initialAutoScale: go.Diagram.Uniform }
      //   : {}),
      // initialScale: go.Diagram.Uniform,
      contentAlignment: go.Spot.Top,
      // initialDocumentSpot: go.Spot.Center,
      initialViewportSpot: go.Spot.Center,
      ...(currentDiagramZoom !== "100"
        ? { scale: parseInt(currentDiagramZoom ?? "100") / 100 }
        : {}),
      maxSelectionCount: 1,
      // model: $(go.TreeModel),
      allowCopy: false,
      allowDelete:
        !pageAttr?.crudOpt?.disabledDelete &&
        ability.can("delete", sessionStorage.getItem("current_page_perm")),
      allowMove:
        !pageAttr?.crudOpt?.treeviewDisabledDrag &&
        !pageAttr?.crudOpt?.treeviewDisabledDrop &&
        !pageAttr?.crudOpt?.disabledEdit &&
        ability.can("update", sessionStorage.getItem("current_page_perm")),
      allowInsert:
        !pageAttr?.crudOpt?.disabledAdd &&
        ability.can("post", sessionStorage.getItem("current_page_perm")),
      // layout: $(go.TreeLayout, initialLayout),
      layout: $(SideTreeLayout, initialLayout),
      ChangedSelection: onSelectionChanged,
      "undoManager.isEnabled": false
      // InitialLayoutCompleted: function (e) {
      //   console.log(e.diagram.nodes)
      //   if (e.diagram.nodes) {
      //     console.log(e.diagram.findTreeRoots())
      //   }
      //   // myDiagram.findTopLevelGroups().each(function(g) { console.log(g.data.text); });
      // }
      // "clickCreatingTool.archetypeNodeData": {
      //   // allow double-click in background to create a new node
      //   name: `New Data`,
      //   title: `New Data`
      // },
      // "clickCreatingTool.insertPart": function (loc) {
      //   // method override must be function, not =>
      //   const node = go.ClickCreatingTool.prototype.insertPart.call(this, loc)
      //   if (node !== null) {
      //     this.diagram.select(node)
      //     this.diagram.commandHandler.scrollToPart(node)
      //     this.diagram.commandHandler.editTextBlock(node.findObject(titleField))
      //   }
      //   return node
      // }
    })

    diagram.layout.commitNodes = function () {
      go.TreeLayout.prototype.commitNodes.call(this)

      let presetName = currentColorPreset
      diagram.layout.network.vertexes.each((v) => {
        if (v.node) {
          const level = v.level % stylesByDepth.length
          const stylesByDepthx =
            mergedStyles?.[level] ?? mergedStyles?.[mergedStyles?.length]
          const stylesByDepthxParent =
            mergedStyles?.[(level ?? 1) - 1] ??
            mergedStyles?.[mergedStyles?.length]
          const shape = v.node.findObject("SHAPE")
          // console.log(v.node.data)
          diagram.startTransaction("UpdateNode")
          diagram.model.setDataProperty(v.node.data, "__level__", level)
          // console.log(v.node.data)
          diagram.commitTransaction("UpdateNode")
          if (shape) {
            if (presetName && presetName !== "default") {
              const background = presetColors[presetName]?.[level]
              if (presetName === "preset5") {
                shape.fill = background?.[0]
              } else {
                shape.fill = $(go.Brush, "Linear", {
                  0: background?.[0],
                  1:
                    background?.[1] ??
                    go.Brush.lightenBy(background?.[0], 0.05),
                  start: go.Spot.TopLeft,
                  end: go.Spot.BottomRight
                })
              }

              shape.stroke = "#000000"
              if (["preset4", "preset5"].includes(presetName)) {
                shape.strokeWidth = 1
              } else {
                shape.stroke = "transparent"
                shape.strokeWidth = 1
              }
            } else {
              const background = Array.isArray(stylesByDepthx?.background)
                ? stylesByDepthx?.background
                : [stylesByDepthx?.background]
              const backgroundParent = Array.isArray(
                stylesByDepthxParent?.background
              )
                ? stylesByDepthxParent?.background
                : [stylesByDepthxParent?.background]
              shape.fill = v.node.data?.isAssistant
                ? $(go.Brush, "Linear", {
                    0: go.Brush.darkenBy(backgroundParent?.[0], 0.2),
                    1: go.Brush.darkenBy(backgroundParent?.[0], 0.6),
                    start: go.Spot.TopLeft,
                    end: go.Spot.BottomRight
                  })
                : $(go.Brush, "Linear", {
                    0: background?.[0],
                    1:
                      background?.[1] ??
                      go.Brush.lightenBy(background?.[0], 0.05),
                    start: go.Spot.TopLeft,
                    end: go.Spot.BottomRight
                  })
              shape.stroke = "transparent"
              shape.strokeWidth = 1
              // shape.stoke = $(go.Brush, "Linear", {
              //   0: background,
              //   1: go.Brush.lightenBy(background, 0.05),
              //   start: go.Spot.Left,
              //   end: go.Spot.Right
              // })
            }
          }

          const textBlock = v.node.findObject("TITLE")
          if (textBlock) {
            if (presetName && presetName !== "default") {
              if (["preset4", "preset5"].includes(presetName)) {
                const color = "black"
                textBlock.stroke = color
              } else {
                const color = "white"
                textBlock.stroke = color
              }
            } else {
              const color = stylesByDepthx?.color
              textBlock.stroke = color
            }
            const font = stylesByDepthx?.font
            textBlock.font = font
          }

          const table = v.node.findObject("TABLE")
          if (table) {
            if (pageAttr?.crudOpt?.treeviewIconField) {
              const width = stylesByDepthx?.width
              const height = stylesByDepthx?.height
              const minSize = stylesByDepthx?.minSize
              const maxSize = stylesByDepthx?.maxSize

              let fixMinWidth = width ?? minSize?.[0]
              let fixMaxWidth = width ?? maxSize?.[0]

              // if (pageAttr?.crudOpt?.treeviewIconField) {
              //   fixMinWidth = (fixMinWidth ?? 0) + defaultPictureSize
              //   fixMaxWidth = (fixMinWidth ?? 0) + defaultPictureSize
              // }

              // table.minSize = new go.Size(
              //   fixMinWidth ?? NaN,
              //   height ?? minSize?.[1] ?? NaN
              // )
              // table.maxSize = new go.Size(
              //   fixMaxWidth ?? NaN,
              //   height ?? maxSize?.[1] ?? NaN
              // )

              // const margin = stylesByDepthx?.margin
              // table.margin = new go.Margin(margin)
            }
          }

          if (pageAttr?.crudOpt?.treeviewIconField) {
            const picture = v.node.findObject("PICTURE")
            if (picture) {
              let rowData = { ...v.node.data }
              let sourceImg = ["https", "http"].includes(
                pageAttr?.crudOpt?.treeviewIconField
              )
                ? pageAttr?.crudOpt?.treeviewIconField
                : getDepthValue(rowData, pageAttr?.crudOpt?.treeviewIconField)
              // ?? blankImg
              if (sourceImg) {
                picture.source = sourceImg
                // picture.visible = true
              } else {
                picture.source = blankImg
                picture.visible = false
                picture.opacity = 0.2
              }
            }
          }
        }
      })

      // // iterasi melalui semua node untuk menemukan asisten
      // diagram.nodes.each(function (node) {
      //   if (node.data.isAssistant) {
      //     const boss = diagram.findNodeForKey(node.data.parent_id)
      //     if (boss !== null) {
      //       const bossLoc = boss.location
      //       const asstLoc = bossLoc.copy()
      //       asstLoc.x += boss.actualBounds.width + 20 // sesuaikan posisi asisten
      //       asstLoc.y = bossLoc.y // tempatkan sejajar dengan bos
      //       node.moveTo(asstLoc.x, asstLoc.y)
      //     }
      //   }
      // })
    }

    diagram.nodeTemplate = $(
      go.Node,
      "Spot",
      {
        isShadowed: true,
        shadowBlur: 1,
        shadowOffset: new go.Point(0, 1),
        shadowColor: "rgba(0, 0, 0, .14)",
        selectionObjectName: "BODY",
        // eslint-disable-next-line no-return-assign
        mouseEnter: (_e, node) => {
          if (
            !pageAttr?.crudOpt?.disabledAdd &&
            ability.can("post", sessionStorage.getItem("current_page_perm"))
          ) {
            node.findObject("BUTTON").opacity = 1
          }
          node.findObject("BUTTONX").opacity = 1
          node.findObject("BUTTON2").opacity =
            node?.data?.isViewOnly || !node?.data?.[parentIdField] ? 0 : 1
        },
        // eslint-disable-next-line no-return-assign
        mouseLeave: (_e, node) => {
          if (
            !pageAttr?.crudOpt?.disabledAdd &&
            ability.can("post", sessionStorage.getItem("current_page_perm"))
          ) {
            node.findObject("BUTTON").opacity = 0
          }
          node.findObject("BUTTONX").opacity = 0
          node.findObject("BUTTON2").opacity = 0
        },
        // handle dragging a Node onto a Node to (maybe) change the reporting relationship
        mouseDragEnter: (e, node, prev) => {
          const diagram = node.diagram
          const selnode = diagram.selection.first()
          if (!mayWorkFor(selnode, node)) return
          const shape = node.findObject("SHAPE")
          if (shape) {
            shape._prevFill = shape.fill // remember the original brush
            shape.fill = "darkred"
          }
        },
        mouseDragLeave: (e, node, next) => {
          const shape = node.findObject("SHAPE")
          if (shape && shape._prevFill) {
            shape.fill = shape._prevFill // restore the original brush
          }
        },
        mouseDrop: (e, node) => {
          const diagram = node.diagram
          const selnode = diagram.selection.first() // assume just one Node in selection
          if (mayWorkFor(selnode, node)) {
            // console.log(selnode.data, node.data)

            // console.log(currentId, newParentId)
            // find any existing link into the selected node
            const link = selnode.findTreeParentLink()
            if (link !== null) {
              // reconnect any existing link
              link.fromNode = node
            } else {
              // else create a new link
              diagram.toolManager.linkingTool.insertLink(
                node,
                node.port,
                selnode,
                selnode.port
              )
            }

            const currentId = selnode?.data?.[idField]
            const newParentId = node?.data?.[idField]
            let newData = {
              ...selnode?.data,
              [parentIdField]: newParentId,
              parent: newParentId
            }
            setPageType("add")
            dispatch(
              setOrgChartData2({
                data: diagram.model.toJson(),
                storeName: storeName
              })
            )

            delete newData.__gohashid
            updateDataToApi(newData, true)
          }
        }
      },
      new go.Binding("text", titleField),
      new go.Binding("layerName", "isSelected", (sel) =>
        sel ? "Foreground" : ""
      ).ofObject(),
      $(
        go.Panel,
        "Auto",
        { name: "BODY" },
        $(
          go.Shape,
          "RoundedRectangle",
          {
            name: "SHAPE",
            fill: graygrad,
            stroke: null,
            strokeWidth: 0,
            // strokeWidth: 0.5,
            // stroke: "#b9b8c1",
            portId: "",
            alignment: go.Spot.Center,
            stretch: go.GraphObject.Fill,
            // fromLinkable: true,
            // toLinkable: true,
            // cursor: "pointer",
            doubleClick: (e, node) => {
              e.handled = false
              // addEmployee(node)
              // Handle double click event, prevent further actions
              // const diagram = node.diagram
              // diagram.startTransaction("createNode")
              // const newTitle = "New Child"
              // const newData = { name: newTitle, key: Date.now().toString() }
              // const parentNode = node.data
              // console.log(node, parentNode)
              // if (parentNode) {
              //   if (!parentNode?.children) {
              //     diagram.model.setDataProperty(parentNode, "children", [])
              //   }
              //   parentNode.children.push(newData)
              // } else {
              //   diagram.model.addNodeData(newData)
              // }
              // diagram.commitTransaction("createNode")
            }
          },
          // new go.Binding("stroke", "isHighlighted", (h, shape) => {
          //   if (h) {
          //     return "#fff2b2"
          //   } else {
          //     // const c = shape.part
          //     return null
          //   }
          // }).ofObject(),
          new go.Binding("fill", "isHighlighted", (h, shape) => {
            if (h) {
              return "gold"
            } else {
              const level = shape.part.data.__level__ % stylesByDepth.length
              const stylesByDepthx =
                mergedStyles?.[level] ?? mergedStyles?.[mergedStyles?.length]
              const background = Array.isArray(stylesByDepthx?.background)
                ? stylesByDepthx?.background
                : [stylesByDepthx?.background]
              return $(go.Brush, "Linear", {
                0: background?.[0],
                1: background?.[1] ?? go.Brush.lightenBy(background?.[0], 0.05),
                start: go.Spot.TopLeft,
                end: go.Spot.BottomRight
              })
            }
          }).ofObject()
          // new go.Binding("strokeWidth", "isHighlighted", (h) => {
          //   if (h) {
          //     return 3.5
          //   } else {
          //     return 0
          //   }
          // }).ofObject()
        ),

        $(
          go.Panel,
          "Vertical",
          $(
            go.Panel,
            "Horizontal",
            pageAttr?.crudOpt?.treeviewIconField
              ? $(
                  go.Picture,
                  {
                    name: "PICTURE",
                    desiredSize: new go.Size(40, 40),
                    margin: 1,
                    source: blankImg // the default image,
                  }
                  // new go.Binding("visible", "nation", nat => nat !== undefined)
                  // new go.Binding(
                  //   "source",
                  //   pageAttr?.crudOpt?.treeviewIconField,
                  //   findHeadShot(pageAttr?.crudOpt?.treeviewIconField)
                  // )
                )
              : {},
            $(
              go.Panel,
              "Table",
              {
                name: "TABLE",
                defaultAlignment: go.Spot.Center,
                alignment: go.Spot.Center,
                // stretch: go.GraphObject.Fill,
                margin: new go.Margin(4),
                // minSize: new go.Size(100, 40),
                maxSize: new go.Size(
                  pageAttr?.crudOpt?.treeviewIconField ? 180 : 100,
                  NaN
                )
                // ...(pageAttr?.crudOpt?.treeviewIconField
                //   ? {
                //       minSize: new go.Size(130, NaN),
                //       maxSize: new go.Size(150, NaN),
                //       margin: new go.Margin(6)
                //     }
                //   : {})
              },
              // pageAttr?.crudOpt?.treeviewIconField
              //   ? $(go.RowColumnDefinition, { column: 2, width: 4 })
              //   : $(go.RowColumnDefinition, { column: 1, width: 4 }),
              $(
                go.TextBlock,
                {
                  name: "TITLE",
                  row: 0,
                  column: 0,
                  columnSpan: 5,
                  font: "12pt Roboto,sans-serif",
                  editable: true,
                  isMultiline: false,
                  stroke: "white",
                  textEdited: function (tb, oldstr, newstr) {
                    let data = { ...tb.part.data }
                    if (data && oldstr !== newstr) {
                      diagram.model.setDataProperty(data, "title", newstr)
                      diagram.model.setDataProperty(data, titleField, newstr)
                      data["title"] = newstr
                      data[titleField] = newstr
                      dispatch(
                        updateOrgChartData({ data: data, storeName: storeName })
                      )

                      delete data.__gohashid
                      updateDataToApi(data, true)
                    }
                  },
                  textAlign: "center",
                  wrap: go.TextBlock.WrapFit
                  // width: pageAttr?.crudOpt?.treeviewIconField ? 250 : 100
                  // alignment: go.Spot.Center,
                  // stretch: go.GraphObject.Fill
                },
                new go.Binding("stroke", "isHighlighted", (h, shape) => {
                  if (h) {
                    return "#111111"
                  } else {
                    const level =
                      shape.part.data.__level__ % stylesByDepth.length
                    const stylesByDepthx =
                      mergedStyles?.[level] ??
                      mergedStyles?.[mergedStyles?.length]
                    const color = stylesByDepthx?.color
                    return color
                  }
                }).ofObject(),
                new go.Binding("text", titleField).makeTwoWay()
              )
            ) // end Table Panel
          ), // end Horizontal Panel
          $(
            go.TextBlock,
            {
              name: "COUNTER",
              row: 0,
              column: 0,
              columnSpan: 5,
              font: "bold 10pt Roboto, sans-serif",
              isMultiline: false,
              stroke: "white",
              textAlign: "center",
              alignment: go.Spot.BottomCenter,
              margin: new go.Margin(2),
              visible: false
              // opacity: 0, // initially not visible
            },
            new go.Binding("text", "", function (data) {
              let suffix = ""
              if (data.__count__ && data.__count__ > 0) {
                suffix = `/ ${data.__count_all__}`
              }
              return `${data.__count__} ${suffix}`
            }),
            // new go.Binding("opacity", "__count__", function (count) {
            //   return count > 0 ? 1 : 0
            // }),
            new go.Binding("visible", "__count__", function (count) {
              return count !== null && count > 0
            })
          )
        )
      ), // end Auto Panel
      !pageAttr?.crudOpt?.disabledAdd &&
        ability.can("post", sessionStorage.getItem("current_page_perm"))
        ? $(
            "Button",
            $(go.Shape, "PlusLine", { width: 10, height: 10 }),
            {
              name: "BUTTON",
              alignment: go.Spot.Right,
              opacity: 0, // initially not visible
              click: (e, button) => addEmployee(button.part)
            },
            new go.Binding("opacity", "isSelected", (s) =>
              s ? 1 : 0
            ).ofObject()
          )
        : {},
      $(
        "TreeExpanderButton",
        // $(go.Shape, "LineDown", { width: 10, height: 10 }),
        {
          name: "BUTTON2",
          alignment: go.Spot.LeftCenter,
          opacity: 0,
          click: (e, button) => viewOnlyNode(button.part),
          _treeExpandedFigure: "LineDown",
          _treeCollapsedFigure: "LineDown"
        },
        new go.Binding("opacity", "isSelected", (s) => (s ? 1 : 0)).ofObject()
      ),
      // { isTreeExpanded: false },
      // { isTreeExpanded: store.pageData[storeName]?.OrgChartData_maxLevel < 4 },
      // new go.Binding("isTreeExpanded").makeTwoWay(),
      $(
        "TreeExpanderButton",
        {
          name: "BUTTONX",
          alignment: go.Spot.Bottom,
          opacity: 0,
          _treeExpandedFigure: "TriangleUp",
          _treeCollapsedFigure: "TriangleDown"
        },
        // button is visible either when node is selected or on mouse-over
        new go.Binding("opacity", "isSelected", (s) => (s ? 1 : 0)).ofObject()
      )
    )

    diagram.linkTemplate = $(
      go.Link,
      {
        routing: linkLineType[currentLinkLineType].routing,
        corner: linkLineType[currentLinkLineType].corner ?? 0,
        curve: linkLineType[currentLinkLineType].curve
      },
      $(go.Shape, {
        strokeWidth: 1,
        stroke: skin !== "dark" ? "#424242" : "#fff"
      })
    )

    const model = new go.GraphLinksModel()
    model.linkKeyProperty = idField
    diagram.model = model

    let myOverview = $(go.Overview, "myOverviewDiv", {
      observed: diagram,
      contentAlignment: go.Spot.Center
    })

    myOverview.box.elt(0).stroke = "#1877f1"

    // // make sure new data keys are unique positive integers
    // let lastkey = 1
    // diagram.model.makeUniqueKeyFunction = (model, data) => {
    //   let k = data.key || lastkey
    //   while (model.findNodeDataForKey(k)) k++
    //   data.key = lastkey = k
    //   return k
    // }

    function updateNodeData(node, data) {
      // console.log(node)
      if (!node) return
      // const oldValue = diagram.skipsUndoManager
      // diagram.skipsUndoManager = true // Matikan perekaman UndoManager
      // diagram.startTransaction("ephemeral") // Mulai transaksi sementara

      const thisemp = node.data
      // delete thisemp.__gohashid
      // console.log(thisemp)
      // delete thisemp.attributes
      // console.log(data)
      diagram.startTransaction("UpdateNode")
      diagram.model.setDataProperty(thisemp, "name", data.name)
      diagram.model.setDataProperty(thisemp, "title", data.title)

      // diagram.commandHandler.scrollToPart(node)
      diagram.commitTransaction("UpdateNode")

      // diagram.commitTransaction("ephemeral") // Selesaikan transaksi sementara
      // diagram.skipsUndoManager = oldValue // Kembalikan pengaturan sebelumnya
    }

    function findItemById(items, id) {
      if (!items || items.length === 0) return null

      for (const item of items) {
        if (item.id === id) {
          return item
        }

        const found = findItemById(item.children, id)
        if (found) return found
      }

      return null
    }

    async function viewOnlyNode(node) {
      // console.log(
      //   store.pageDataTempFilterTreeCurrent[storeName],
      //   store.pageDataTempFilterTree
      // )
      // console.log(viewOnly)
      // if (!store.pageDataTempFilterTreeCurrent[storeName]) {

      const currentNodeId = node.data[idField]
      const childData = findItemById(
        store?.pageData?.[storeName]?.data,
        currentNodeId
      )
      const childDataCopy = JSON.parse(JSON.stringify(childData))
      // console.log(childDataCopy)
      childDataCopy["isViewOnly"] = true
      setViewOnly(childDataCopy)
      await setViewOnlyToStore(childDataCopy)

      // }
    }

    async function addEmployee(node) {
      setPageType("add")
      if (!node) return
      const thisemp = { ...node.data }
      diagram.startTransaction("add node")
      // delete thisemp.__gohashid
      let newemp = {
        name: `New Data`,
        title: `New Data`,
        parent: thisemp[idField],
        [idField]: Date.now(),
        [parentIdField]: thisemp[idField],
        __level__: node.__level__ + 1
        // visible: true
      }

      let newPayload = {
        [titleField]: `New Data`,
        [parentIdField]: thisemp[idField]
      }

      if (codeField) {
        const newCode = codeIncrement(
          diagram.model.toJson(),
          thisemp,
          thisemp[idField]
        )
        newemp[codeField] = newCode
        newPayload[codeField] = newCode
      }

      const addData = await postDataToApi(newPayload)
      // console.log(addData)
      if (addData[idField]) {
        newemp[idField] = addData[idField]
      }
      // console.log(newemp)
      diagram.model.addNodeData(newemp)

      let newnode = diagram.findNodeForData(newemp)
      const link = newnode.findTreeParentLink()
      if (link !== null) {
        link.fromNode = node
      } else {
        diagram.toolManager.linkingTool.insertLink(
          node,
          node.port,
          newnode,
          newnode.port
        )
      }
      if (newnode) {
        newnode.location = node.location.copy()
      }
      diagram.commitTransaction("add node")
      dispatch(
        setOrgChartData2({ data: diagram.model.toJson(), storeName: storeName })
      )

      diagram.commandHandler.scrollToPart(newnode)
      // diagram.select(newnode)
      // setShowModal(true)
      // updateProperties(newnode)
      // diagram.commandHandler.editTextBlock(newnode.findObject("TITLE"))

      // console.log(diagram.model.toJson())
    }

    function mayWorkFor(node1, node2) {
      if (!(node1 instanceof go.Node)) return false // must be a Node
      if (node1 === node2) return false // cannot work for yourself
      if (node2.isInTreeOf(node1)) return false // cannot work for someone who works for you
      return true
    }

    diagram.nodeTemplate.contextMenu = $(
      "ContextMenu",
      !pageAttr?.crudOpt?.disabledAdd &&
        ability.can("post", sessionStorage.getItem("current_page_perm"))
        ? $(
            "ContextMenuButton",
            { ...contextMenuStyleAtr },
            $(
              go.TextBlock,
              {
                font: "bold 12pt Roboto,sans-serif",
                margin: 5
              },
              t("Add Node")
            ),
            {
              click: (e, button) => addEmployee(button.part.adornedPart)
            }
          )
        : {},
      !pageAttr?.crudOpt?.disabledDelete &&
        ability.can("delete", sessionStorage.getItem("current_page_perm"))
        ? $(
            "ContextMenuButton",
            {
              ...contextMenuStyleAtr,
              _buttonFillOver: "#cf2038",
              _buttonStrokeOver: "#cf2038"
            },
            $(
              go.TextBlock,
              {
                font: "bold 12pt Roboto,sans-serif",
                margin: 5
              },
              t("Delete Node")
            ),
            {
              click: (e, button) => {
                // remove the whole subtree, including the node itself
                const node = button.part.adornedPart
                if (node !== null) {
                  MySwal.fire({
                    title: `${t("Are you sure?")}`,
                    text: `${t("You won't be able to revert")} ${t(
                      pageAttr.pageTitle
                    )}!`,
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: `${t("Yes, Delete")} ${t(
                      pageAttr.pageTitle
                    )}!`,
                    cancelButtonText: `${t("Cancel")}`,
                    customClass: {
                      confirmButton: "btn btn-primary",
                      cancelButton: "btn btn-outline-danger ms-1"
                    },
                    buttonsStyling: false
                  }).then((result) => {
                    if (result.isConfirmed) {
                      const id = node.data[idField]
                      diagram.startTransaction("remove node")
                      diagram.removeParts(node.findTreeParts())
                      diagram.commitTransaction("remove node")

                      dispatch(
                        deleteData({
                          id: id,
                          storeName: storeName,
                          endpointName: pageAttr?.crudOpt?.endpointName
                        })
                      ).then((res) => {
                        // console.log(res)
                        if (res.meta.requestStatus === "fulfilled") {
                          setLoading(false)
                          toast.success(
                            `${t("Successfully deleted")} ${t(
                              pageAttr.pageTitle
                            )}`,
                            {
                              style: {
                                padding: "16px",
                                color: colors.success.main,
                                border: `1px solid ${colors.success.main}`
                              },
                              iconTheme: {
                                primary: colors.success.main
                              }
                            }
                          )
                        } else if (res.meta.requestStatus === "rejected") {
                          setLoading(false)
                          toast.error(res?.payload?.message ?? "", {
                            style: {
                              padding: "16px",
                              color: colors.danger.main,
                              border: `1px solid ${colors.danger.main}`
                            },
                            iconTheme: {
                              primary: colors.danger.main
                            }
                          })
                        }
                      })
                    }
                  })
                }
              }
            }
          )
        : {}
    )

    let inputElement = document.getElementById("changedData")
    let initialValue = "{}" // Replace this with the initial value you want to set
    inputElement.value = initialValue
    inputElement.addEventListener("input", (e) => {
      let newValue = e.target.value
      let data = JSON.parse(newValue)
      // console.log(data)
      const node = diagram.findNodeForKey(data.key)
      updateNodeData(node, data)
    })

    // Setup zoom to fit button
    document
      .getElementById("zoomToFit")
      .addEventListener("click", () => diagram.commandHandler.zoomToFit())

    document.getElementById("centerRoot").addEventListener("click", () => {
      diagram.scale = 1
      diagram.commandHandler.scrollToPart(diagram.findNodeForKey(1))
    })

    document.getElementById("blobButton").addEventListener("click", makeSVG)
    function myCallback(blob) {
      let url = window.URL.createObjectURL(blob)
      let filename = `${t(pageAttr?.pageTitle ?? "OrgChart")}.png`

      let a = document.createElement("a")
      a.style = "display: none"
      a.href = url
      a.download = filename

      // IE 11
      if (window.navigator.msSaveBlob !== undefined) {
        window.navigator.msSaveBlob(blob, filename)
        return
      }

      document.body.appendChild(a)
      requestAnimationFrame(() => {
        a.click()
        window.URL.revokeObjectURL(url)
        document.body.removeChild(a)
      })
    }

    function makeBlob() {
      const scale = calculateScale()
      diagram.commandHandler.zoomToFit()
      // adjustBoundsAndZoomToFit()
      let blob = diagram.makeImageData({
        scale: scale ?? 1,
        maxSize: new go.Size(10000, 10000),
        background: "transparent",
        returnType: "blob",
        callback: myCallback
      })
    }

    function makeSVG() {
      let svg = diagram.makeSvg({
        scale: 1,
        background: "transparent",
        maxSize: new go.Size(Infinity, Infinity)
      })
      let serializer = new XMLSerializer()
      let svgString = serializer.serializeToString(svg)
      // let blob = new Blob([svgString], { type: "image/svg+xml;charset=utf-8" })
      // let url = URL.createObjectURL(blob)
      // window.open(url)
      // downloadBlob(blob, "diagram.svg") // Call the download function

      let canvas = document.createElement("canvas")
      let ctx = canvas.getContext("2d")

      let img = new Image()
      img.onload = function () {
        canvas.width = img.width
        canvas.height = img.height
        ctx.drawImage(img, 0, 0)
        canvas.toBlob(function (blob) {
          downloadBlob(blob, "diagram.png")
        }, "image/png")
      }

      img.src = `data:image/svg+xml;base64,${btoa(
        unescape(encodeURIComponent(svgString))
      )}`
    }

    function downloadBlob(blob, filename) {
      let url = URL.createObjectURL(blob)

      let a = document.createElement("a")
      a.style.display = "none"
      a.href = url
      a.download = filename

      document.body.appendChild(a)
      a.click() // Trigger the download

      // Clean up
      setTimeout(() => {
        URL.revokeObjectURL(url)
        document.body.removeChild(a)
      }, 100)
    }

    // function downloadBlob(blob, filename) {
    //   let url = URL.createObjectURL(blob)

    //   let a = document.createElement("a")
    //   a.style.display = "none"
    //   a.href = url
    //   a.download = filename

    //   document.body.appendChild(a)
    //   a.click() // Trigger the download

    //   // Clean up
    //   setTimeout(() => {
    //     URL.revokeObjectURL(url)
    //     document.body.removeChild(a)
    //   }, 100)
    // }

    function adjustBoundsAndZoomToFit() {
      let visibleBounds = new go.Rect()
      diagram.nodes.each(function (node) {
        if (node.isVisible()) {
          let b = node.actualBounds
          visibleBounds = visibleBounds.isEmpty()
            ? b
            : visibleBounds.unionPoint(b.position)
        }
      })
      diagram.links.each(function (link) {
        if (link.isVisible()) {
          let b = link.bounds
          visibleBounds = visibleBounds.isEmpty()
            ? b
            : visibleBounds.unionRect(b)
        }
      })

      diagram.fixedBounds = visibleBounds
      diagram.zoomToFit()
    }

    function calculateScale() {
      const bounds = diagram.documentBounds
      const maxDimension = Math.max(bounds.width, bounds.height)
      const targetMaxSize = 2000 // Target maximum size for the longest dimension
      return targetMaxSize / maxDimension
    }

    // diagram.addDiagramListener("InitialLayoutCompleted", (e) => {
    //   // console.log(e)
    //   // setTimeout(() => {
    //   //   const diagram2 = diagramRef?.current.getDiagram()
    //   //   const treeRoots = diagram2.findTreeRoots()
    //   //   console.log(treeRoots)
    //   //   treeRoots.each((r) => {
    //   //     r.expandTree(2)
    //   //   })
    //   // }, 200) // Menunggu jeda sebentar sebelum mencari root
    //   // if (store.pageData[storeName]?.OrgChartData_maxLevel > 3) {
    //   //   // setCurrentViewLevel(3)
    //   //   // setTimeout(() => {
    //   //   //   diagram.commandHandler.zoomToFit()
    //   //   // }, 300)
    //   // }
    //   // e.diagram.layout = go.GraphObject.make(go.TreeLayout, initialLayout)
    //   // diagram.layoutDiagram()
    //   // diagram.commandHandler.zoomToFit()
    //   // changeCurrentViewAngle(currentViewAngle)
    // })
    // // diagram.layout = go.GraphObject.make(go.TreeLayout, initialLayout)
    // // console.log(currentDiagramZoom)
    // // if (currentDiagramZoom) {
    // //   diagram.scale = parseInt(currentDiagramZoom ?? "100") / 100
    // // }

    // // diagram.layout = go.GraphObject.make(SideTreeLayout, initialLayout)
    return diagram
  }

  function visibleAllChilds(parent) {
    parent["isTreeExpanded"] = true
    parent["visible"] = true
    parent.findTreeChildrenNodes().each((el) => {
      el.isTreeExpanded = true
      el.visible = true
    })

    let subParent = parent.findTreeParentNode()

    if (subParent) {
      visibleAllChilds(subParent)
    }
  }

  function searchDiagram(value) {
    setSearchTerm(value)
    const diagram = diagramRef?.current.getDiagram()
    // diagram.focus()

    diagram.startTransaction("highlight search")
    if (value) {
      // search four different data properties for the string, any of which may match for success
      // create a case insensitive RegExp from what the user typed
      let safe = value.replace(/[.*+?^${}()|[\]\\]/g, "\\$&")
      let regex = new RegExp(safe, "i")
      // let regex = new RegExp(`\\b${safe}\\b`, "i")
      // let regex = new RegExp(`\\b${safe}`, "i")

      // console.log(safe, regex)
      let results = diagram.findNodesByExample(
        { name: regex },
        { title: regex }
      )
      // Collapse all nodes first
      // diagram.findTreeRoots().each(function (node) {
      //   node.collapseTree()
      //   node["visible"] = false
      //   node["isTreeExpanded"] = false
      // })

      // diagram.findTreeRoots().each((r) => {
      //   r.expandTree()
      // })

      // diagram.highlightCollection(results)
      if (results.count > 0) {
        diagram.highlightCollection(results)
        diagram.centerRect(results.first().actualBounds)
        // diagram.highlighteds.each((e) => {
        //   let parent = e.findTreeParentNode()
        //   if (parent) {
        //     visibleAllChilds(parent)
        //   }
        //   e.expandTree()
        // })
      } else {
        // Clear highlighteds if no results found
        diagram.clearHighlighteds()
        if (currentColorPreset && currentColorPreset !== "default") {
          changeCurrentColorPreset(currentColorPreset)
          // changeNodeColor(node, presetName)
        }
      }
    } else {
      // empty string only clears highlighteds collection
      diagram.clearHighlighteds()
      // If the search term is empty, expand all nodes
      // diagram.nodes.each(function (node) {
      //   node.expandTree()
      // })
      if (currentColorPreset && currentColorPreset !== "default") {
        changeCurrentColorPreset(currentColorPreset)
        // changeNodeColor(node, presetName)
      }
    }

    diagram.commitTransaction("highlight search")
  }

  const handleDiagramEvent = (e) => {
    console.log("Diagram event:", e)
    const name = e?.[titleField]
    // switch (name) {
    //   case "ChangedSelection": {
    //     const sel = e.subject.first()
    //     if (sel) {
    //       this.setState({ selectedKey: sel.key })
    //     } else {
    //       this.setState({ selectedKey: null })
    //     }
    //     break
    //   }
    //   default:
    //     break
    // }
  }

  return (
    <UILoader
      blocking={
        loadingDelete || store?.pageData?.[storeName]?.isLoading || loading
      }
      loader={<Loader loadingText={loadingText} />}
    >
      <Card
        style={{
          minHeight: "60vh"
        }}
        id="org_chart_container"
        className="overflow-hidden"
      >
        {/* <CustomHeader
          pageAttr={pageAttr}
          searchTerm={searchTerm}
          storeName={storeName}
          handleFilter={searchDiagram}
          // expandAllRows={expandAllRows}
          // customHeaderSet={customHeaderSet}
        /> */}

        <div className="content-header-right text-md-end col-md-12 col-12 mb-0 pb-0 pb-md-2 mt-2 px-md-2 px-1">
          <Row className="align-items-center">
            <Col md={6} xs={12}>
              <div className="d-flex align-items-center mb-sm-0 mb-1">
                <label className="mb-0" htmlFor="search-invoice">
                  {t("Search")}:
                </label>
                <Input
                  id="search-invoice"
                  className="ms-50"
                  disabled={!orgChartData}
                  type="text"
                  value={searchTerm}
                  onChange={(e) => searchDiagram(e.target.value)}
                />
              </div>
            </Col>
            <Col className="d-block d-md-none" md={3} xs={12}>
              <div class="py-1">
                <Input
                  type="range"
                  min="1"
                  max="400"
                  onChange={(e) => zoomSliderChange(e.target.value)}
                  value={currentDiagramZoom}
                />
              </div>
            </Col>
            <Col className="p-0 p-md-1" md={6} xs={12}>
              <div className="d-md-flex align-items-center text-center text-md-right justify-content-end mb-sm-0 mb-0 mb-md-1">
                <div class="ms-50 me-1 pt-50 d-none d-md-block w-100">
                  <Input
                    type="range"
                    min="1"
                    max="400"
                    onChange={(e) => zoomSliderChange(e.target.value)}
                    value={currentDiagramZoom}
                  />
                </div>
                <Button
                  id="fullscreenBtn"
                  onClick={(e) =>
                    !isFullscreen ? requestFullscreen() : exitFullscreen()
                  }
                  disabled={!orgChartData}
                  outline
                  className="btn-icon ms-50 mb-75 mb-md-0"
                  color="secondary"
                >
                  {!isFullscreen ? <Fullscreen /> : <FullscreenExit />}
                </Button>
                <UncontrolledTooltip placement="bottom" target="fullscreenBtn">
                  {t("Full Screen Mode")}
                </UncontrolledTooltip>

                <Button
                  id="zoomToFit"
                  outline
                  className="btn-icon ms-50 mb-75 mb-md-0"
                  color="secondary"
                  disabled={!orgChartData}
                >
                  {renderIcon("material:fit_screen", "", 14)}
                </Button>
                <UncontrolledTooltip placement="bottom" target="zoomToFit">
                  {t("Zoom to Fit")}
                </UncontrolledTooltip>

                <Button
                  id="centerRoot"
                  outline
                  className="btn-icon ms-50 mb-75 mb-md-0"
                  color="secondary"
                  disabled={!orgChartData}
                >
                  {renderIcon("material:hub", "", 14)}
                </Button>
                <UncontrolledTooltip placement="bottom" target="centerRoot">
                  {t("Center on Root")}
                </UncontrolledTooltip>

                <Button
                  id="expandCollapseAll"
                  outline
                  className="btn-icon ms-50 mb-75 mb-md-0"
                  color="secondary"
                  disabled={!orgChartData}
                  onClick={(e) => expandAllNodes()}
                >
                  {expandedAll
                    ? renderIcon("material:unfold_less_double", "", 14)
                    : renderIcon("material:unfold_more_double", "", 14)}
                </Button>
                <UncontrolledTooltip
                  placement="bottom"
                  target="expandCollapseAll"
                >
                  {expandedAll
                    ? t("Collapse All Nodes")
                    : t("Expand All Nodes")}
                </UncontrolledTooltip>

                {store.pageData[storeName]?.OrgChartData_maxLevel && (
                  <Fragment>
                    <UncontrolledButtonDropdown>
                      <DropdownToggle
                        id="expandCollapseByLevel"
                        className="btn-icon ms-50 mb-75 mb-md-0"
                        outline
                        color={
                          currentViewLevel &&
                          currentViewLevel !==
                            store.pageData[storeName]?.OrgChartData_maxLevel
                            ? "primary"
                            : "secondary"
                        }
                        caret
                        disabled={!orgChartData}
                      >
                        {renderIcon("material:filter_alt", "", 14)}
                      </DropdownToggle>
                      <DropdownMenu>
                        {Array.from(
                          {
                            length:
                              store?.pageData[storeName]?.OrgChartData_maxLevel
                          },
                          (_, index) => index + 1
                        ).map((lvl) => {
                          if (
                            lvl > 1 &&
                            lvl <
                              store?.pageData[storeName]?.OrgChartData_maxLevel
                          ) {
                            return (
                              <DropdownItem
                                key={`dropdown_item_filter_${lvl}`}
                                href="javascript:void(0)"
                                disabled={currentViewLevel === lvl}
                                onClick={(e) => collapseExpandByLevel(lvl)}
                                tag="a"
                              >
                                {`${t(`level`)} < ${lvl + 1}`}
                              </DropdownItem>
                            )
                          } else if (
                            lvl ===
                            store?.pageData[storeName]?.OrgChartData_maxLevel
                          ) {
                            return (
                              <DropdownItem
                                key={`dropdown_item_filter_${lvl}`}
                                href="javascript:void(0)"
                                disabled={currentViewLevel === lvl}
                                onClick={(e) => collapseExpandByLevel(lvl)}
                                tag="a"
                              >
                                {t(`Expand All`)}
                              </DropdownItem>
                            )
                          }
                        })}
                      </DropdownMenu>
                    </UncontrolledButtonDropdown>
                    <UncontrolledTooltip
                      placement="bottom"
                      target="expandCollapseByLevel"
                    >
                      {t("Expand Node By Level")}
                    </UncontrolledTooltip>
                  </Fragment>
                )}

                <Fragment>
                  <UncontrolledButtonDropdown>
                    <DropdownToggle
                      id="presetColorBtn"
                      className="btn-icon ms-50 mb-75 mb-md-0"
                      outline
                      color={
                        currentColorPreset !== "default"
                          ? "primary"
                          : "secondary"
                      }
                      caret
                      disabled={!orgChartData}
                    >
                      {renderIcon("material:palette", "", 14)}
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem
                        href="#"
                        color={
                          currentColorPreset === "default"
                            ? "primary"
                            : "secondary"
                        }
                        disabled={currentColorPreset === "default"}
                        onClick={(e) => changeCurrentColorPreset("default", e)}
                        tag="a"
                      >
                        <div className="color-row">
                          {presetColors["default"].map((colorPair, index) => (
                            <div
                              key={index}
                              className="color-box"
                              style={{
                                background: `linear-gradient(${colorPair[0]}, ${colorPair[1]})`
                              }}
                              title={`Color ${index + 1}`}
                            ></div>
                          ))}
                        </div>
                        {/* {t("Default")} */}
                      </DropdownItem>
                      <DropdownItem
                        href="#"
                        color={
                          currentColorPreset === "preset1"
                            ? "primary"
                            : "secondary"
                        }
                        disabled={currentColorPreset === "preset1"}
                        onClick={(e) => changeCurrentColorPreset("preset1", e)}
                        tag="a"
                      >
                        <div className="color-row">
                          {presetColors["preset1"].map((colorPair, index) => (
                            <div
                              key={index}
                              className="color-box"
                              style={{
                                background: `linear-gradient(${colorPair[0]}, ${colorPair[1]})`
                              }}
                              title={`Color ${index + 1}`}
                            ></div>
                          ))}
                        </div>
                        {/* {t("Biru")} */}
                      </DropdownItem>
                      <DropdownItem
                        href="#"
                        color={
                          currentColorPreset === "preset2"
                            ? "primary"
                            : "secondary"
                        }
                        disabled={currentColorPreset === "preset2"}
                        onClick={(e) => changeCurrentColorPreset("preset2", e)}
                        tag="a"
                      >
                        <div className="color-row">
                          {presetColors["preset2"].map((colorPair, index) => (
                            <div
                              key={index}
                              className="color-box"
                              style={{
                                background: `linear-gradient(${colorPair[0]}, ${colorPair[1]})`
                              }}
                              title={`Color ${index + 1}`}
                            ></div>
                          ))}
                        </div>
                        {/* {t("Hijau")} */}
                      </DropdownItem>
                      <DropdownItem
                        href="#"
                        color={
                          currentColorPreset === "preset3"
                            ? "primary"
                            : "secondary"
                        }
                        disabled={currentColorPreset === "preset3"}
                        onClick={(e) => changeCurrentColorPreset("preset3", e)}
                        tag="a"
                      >
                        <div className="color-row">
                          {presetColors["preset3"].map((colorPair, index) => (
                            <div
                              key={index}
                              className="color-box"
                              style={{
                                background: `linear-gradient(${colorPair[0]}, ${colorPair[1]})`
                              }}
                              title={`Color ${index + 1}`}
                            ></div>
                          ))}
                        </div>
                        {/* {t("Hijau")} */}
                      </DropdownItem>
                      <DropdownItem
                        href="#"
                        color={
                          currentColorPreset === "preset4"
                            ? "primary"
                            : "secondary"
                        }
                        disabled={currentColorPreset === "preset4"}
                        onClick={(e) => changeCurrentColorPreset("preset4", e)}
                        tag="a"
                      >
                        <div className="color-row">
                          {presetColors["preset4"].map((colorPair, index) => (
                            <div
                              key={index}
                              className="color-box"
                              style={{
                                background: `linear-gradient(${colorPair[0]}, ${colorPair[1]})`
                              }}
                              title={`Color ${index + 1}`}
                            ></div>
                          ))}
                        </div>
                      </DropdownItem>
                      <DropdownItem
                        href="#"
                        color={
                          currentColorPreset === "preset5"
                            ? "primary"
                            : "secondary"
                        }
                        disabled={currentColorPreset === "preset5"}
                        onClick={(e) => changeCurrentColorPreset("preset5", e)}
                        tag="a"
                      >
                        <div className="color-row">
                          {presetColors["preset5"].map((colorPair, index) => (
                            <div
                              key={index}
                              className="color-box"
                              style={{
                                background: `linear-gradient(${colorPair[0]}, ${colorPair[1]})`
                              }}
                              title={`Color ${index + 1}`}
                            ></div>
                          ))}
                        </div>
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledButtonDropdown>
                  <UncontrolledTooltip
                    placement="bottom"
                    target="presetColorBtn"
                  >
                    {t("Color Preset")}
                  </UncontrolledTooltip>
                </Fragment>

                <Fragment>
                  <UncontrolledButtonDropdown>
                    <DropdownToggle
                      id="layoutTemplate"
                      className="btn-icon ms-50 mb-75 mb-md-0"
                      outline
                      color={
                        currentViewAngle !== "default" ? "primary" : "secondary"
                      }
                      caret
                      disabled={!orgChartData}
                    >
                      {renderIcon("material:rotate_90_degrees_cw", "", 14)}
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem
                        href="javascript:void(0)"
                        color={
                          currentViewAngle === "default"
                            ? "primary"
                            : "secondary"
                        }
                        disabled={currentViewAngle === "default"}
                        onClick={(e) => changeCurrentViewAngle("default")}
                        tag="a"
                      >
                        {t("Default")}
                      </DropdownItem>
                      <DropdownItem
                        href="javascript:void(0)"
                        color={
                          currentViewAngle === "default2"
                            ? "primary"
                            : "secondary"
                        }
                        disabled={currentViewAngle === "default2"}
                        onClick={(e) => changeCurrentViewAngle("default2")}
                        tag="a"
                      >
                        {t("Default 2")}
                      </DropdownItem>
                      <DropdownItem
                        href="javascript:void(0)"
                        color={
                          currentViewAngle === "default3"
                            ? "primary"
                            : "secondary"
                        }
                        disabled={currentViewAngle === "default3"}
                        onClick={(e) => changeCurrentViewAngle("default3")}
                        tag="a"
                      >
                        {t("Default 3")}
                      </DropdownItem>
                      <DropdownItem
                        href="javascript:void(0)"
                        color={
                          currentViewAngle === "left" ? "primary" : "secondary"
                        }
                        disabled={currentViewAngle === "left"}
                        onClick={(e) => changeCurrentViewAngle("left")}
                        tag="a"
                      >
                        {t("Left")}
                      </DropdownItem>
                      <DropdownItem
                        href="javascript:void(0)"
                        color={
                          currentViewAngle === "right" ? "primary" : "secondary"
                        }
                        disabled={currentViewAngle === "right"}
                        onClick={(e) => changeCurrentViewAngle("right")}
                        tag="a"
                      >
                        {t("Right")}
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledButtonDropdown>
                  <UncontrolledTooltip
                    placement="bottom"
                    target="layoutTemplate"
                  >
                    {t("Layout Template")}
                  </UncontrolledTooltip>
                </Fragment>

                <Fragment>
                  <UncontrolledButtonDropdown>
                    <DropdownToggle
                      id="linkLineType"
                      className="btn-icon ms-50 mb-75 mb-md-0"
                      outline
                      color={
                        currentLinkLineType !== "default"
                          ? "primary"
                          : "secondary"
                      }
                      caret
                      disabled={!orgChartData}
                    >
                      {renderIcon("material:ssid_chart", "", 14)}
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem
                        href="#"
                        color={
                          currentLinkLineType === "default"
                            ? "primary"
                            : "secondary"
                        }
                        disabled={currentLinkLineType === "default"}
                        onClick={(e) => changeCurrentLinkLineType(e, "default")}
                        tag="a"
                      >
                        {t("Orthogonal")}
                      </DropdownItem>
                      <DropdownItem
                        href="#"
                        color={
                          currentLinkLineType === "normal"
                            ? "primary"
                            : "secondary"
                        }
                        disabled={currentLinkLineType === "normal"}
                        onClick={(e) => changeCurrentLinkLineType(e, "normal")}
                        tag="a"
                      >
                        {t("Normal")}
                      </DropdownItem>
                      <DropdownItem
                        href="#"
                        color={
                          currentLinkLineType === "bezier"
                            ? "primary"
                            : "secondary"
                        }
                        disabled={currentLinkLineType === "bezier"}
                        onClick={(e) => changeCurrentLinkLineType(e, "bezier")}
                        tag="a"
                      >
                        {t("Bezier")}
                      </DropdownItem>
                      <DropdownItem
                        href="#"
                        color={
                          currentLinkLineType === "jumpOver"
                            ? "primary"
                            : "secondary"
                        }
                        disabled={currentLinkLineType === "jumpOver"}
                        onClick={(e) =>
                          changeCurrentLinkLineType(e, "jumpOver")
                        }
                        tag="a"
                      >
                        {t("Jump Over")}
                      </DropdownItem>
                      <DropdownItem
                        href="#"
                        color={
                          currentLinkLineType === "avoidsNodes"
                            ? "primary"
                            : "secondary"
                        }
                        disabled={currentLinkLineType === "avoidsNodes"}
                        onClick={(e) =>
                          changeCurrentLinkLineType(e, "avoidsNodes")
                        }
                        tag="a"
                      >
                        {t("Avoids Nodes")}
                      </DropdownItem>
                      {/* <DropdownItem
                        href="#"
                        color={
                          currentLinkLineType === "chain"
                            ? "primary"
                            : "secondary"
                        }
                        disabled={currentLinkLineType === "chain"}
                        onClick={(e) => changeCurrentLinkLineType(e, "chain")}
                        tag="a"
                      >
                        {t("Chain")}
                      </DropdownItem> */}
                    </DropdownMenu>
                  </UncontrolledButtonDropdown>
                  <UncontrolledTooltip placement="bottom" target="linkLineType">
                    {t("Link Line Type")}
                  </UncontrolledTooltip>
                </Fragment>

                <Fragment>
                  <UncontrolledButtonDropdown>
                    <DropdownToggle
                      id="overviewToggle"
                      className="btn-icon ms-50 mb-75 mb-md-0"
                      outline
                      color={currentViewOverview ? "primary" : "secondary"}
                      caret
                      disabled={!orgChartData}
                    >
                      {renderIcon("material:picture_in_picture_alt", "", 14)}
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem
                        className="d-md-block d-none"
                        href="javascript:void(0)"
                        color={
                          currentViewAngle === "top-left"
                            ? "primary"
                            : "secondary"
                        }
                        disabled={currentViewAngle === "top-left"}
                        onClick={(e) => changeCurrentViewOverview("top-left")}
                        tag="a"
                      >
                        {t("Top Left")}
                      </DropdownItem>
                      <DropdownItem
                        href="javascript:void(0)"
                        color={
                          currentViewAngle === "bottom-left"
                            ? "primary"
                            : "secondary"
                        }
                        disabled={currentViewAngle === "bottom-left"}
                        onClick={(e) =>
                          changeCurrentViewOverview("bottom-left")
                        }
                        tag="a"
                      >
                        {t("Bottom Left")}
                      </DropdownItem>
                      <DropdownItem
                        className="d-md-block d-none"
                        href="javascript:void(0)"
                        color={
                          currentViewAngle === "top-right"
                            ? "primary"
                            : "secondary"
                        }
                        disabled={currentViewAngle === "top-right"}
                        onClick={(e) => changeCurrentViewOverview("top-right")}
                        tag="a"
                      >
                        {t("Top Right")}
                      </DropdownItem>
                      <DropdownItem
                        href="javascript:void(0)"
                        color={
                          currentViewAngle === "bottom-right"
                            ? "primary"
                            : "secondary"
                        }
                        disabled={currentViewAngle === "bottom-right"}
                        onClick={(e) =>
                          changeCurrentViewOverview("bottom-right")
                        }
                        tag="a"
                      >
                        {t("Bottom Right")}
                      </DropdownItem>
                      <DropdownItem
                        href="javascript:void(0)"
                        disabled={!currentViewAngle}
                        onClick={(e) => changeCurrentViewOverview(null)}
                        tag="a"
                      >
                        {t("Hide")}
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledButtonDropdown>
                  <UncontrolledTooltip
                    placement="bottom"
                    target="overviewToggle"
                  >
                    {t("Diagram Overview")}
                  </UncontrolledTooltip>
                </Fragment>

                <Button
                  id="blobButton"
                  outline
                  className="btn-icon ms-50 mb-75 mb-md-0"
                  color="secondary"
                  disabled={!orgChartData}
                >
                  {renderIcon("material:file_download", "", 14)}
                </Button>
                <UncontrolledTooltip placement="bottom" target="blobButton">
                  {t("Export to Image")}
                </UncontrolledTooltip>
              </div>
            </Col>
          </Row>
        </div>

        <Row className="mb-2 align-items-start">
          <Col className="mx-0 col-md-12 mx-md-1" md={12}>
            <div className="mt-1 mt-md-0 mb-1 mb-md-2 px-1">
              <div className="text-secondary">
                <Info size={13} className="ms-0" />
                <small className="ms-25 align-middle">
                  {!pageAttr?.crudOpt?.disabledEdit &&
                    ability.can(
                      "update",
                      sessionStorage.getItem("current_page_perm")
                    ) &&
                    t("Drag a node onto another to change relationships.")}{" "}
                  {((!pageAttr?.crudOpt?.disabledAdd &&
                    ability.can(
                      "post",
                      sessionStorage.getItem("current_page_perm")
                    )) ||
                    (!pageAttr?.crudOpt?.disabledDelete &&
                      ability.can(
                        "delete",
                        sessionStorage.getItem("current_page_perm")
                      ))) &&
                    t(
                      "Right-click or tap-hold a Node to bring up a context menu."
                    )}{" "}
                  {!pageAttr?.crudOpt?.disabledEdit &&
                    ability.can(
                      "update",
                      sessionStorage.getItem("current_page_perm")
                    ) &&
                    t("Click a node to view or edit detail data.")}{" "}
                  {t("ctrl + scroll wheel to zoom.")}
                </small>
              </div>
            </div>
          </Col>

          <Col className="mx-1" md={12}>
            {store.pageDataTempFilterTreeCurrent[storeName] && (
              <div
                style={{
                  // position: "absolute",
                  left: "12px",
                  top: "110px"
                }}
                className="d-flex align-items-center align-middle mb-1"
              >
                <Button
                  id="btn-back-crud"
                  className="me-50 btn-sm"
                  color="flat-secondary"
                  onClick={(e) => {
                    e.preventDefault()
                    toggleModalView()
                  }}
                >
                  {renderIcon("material:arrow_back", "", 15)}
                </Button>
                <h5 className="m-0">
                  <span style={{ fontWeight: 300 }}>
                    {`${t("View Only Childrens Of")}`}{" "}
                  </span>
                  <span className="fw-bolder">
                    {
                      store.pageDataTempFilterTreeCurrent[storeName]?.[
                        titleField
                      ]
                    }
                  </span>
                </h5>
              </div>
            )}
          </Col>
          <Col className="mx-0" md={12}>
            {orgChartData && (
              <Fragment>
                <ReactDiagram
                  ref={diagramRef}
                  divClassName={
                    isFullscreen
                      ? "diagram-component-fullscreen"
                      : "diagram-component"
                  }
                  initDiagram={initDiagram}
                  nodeDataArray={orgChartData?.nodeDataArray}
                  linkDataArray={orgChartData?.linkDataArray}
                  // modelData={{
                  //   nodeDataArray: orgChartData.nodeDataArray,
                  //   linkDataArray: orgChartData.linkDataArray
                  // }}
                  // onDiagramEvent={handleDiagramEvent}
                  // onModelChange={(e) => onModelChange(e)} // If you want to handle model changes within this component
                  // skipsDiagramUpdate={true} // Change this according to your needs
                />

                <div
                  className={`myOverviewDiv orgchart-overview-${currentViewOverview}`}
                  style={{
                    display: currentViewOverview ? "block" : "none",
                    // top:
                    //   currentViewOverview === "top-left" ||
                    //   currentViewOverview === "top-right"
                    //     ? "110px"
                    //     : null,
                    // left:
                    //   currentViewOverview === "top-left" ||
                    //   currentViewOverview === "bottom-left"
                    //     ? "20px"
                    //     : null,
                    // right:
                    //   currentViewOverview === "top-right" ||
                    //   currentViewOverview === "bottom-right"
                    //     ? "20px"
                    //     : null,
                    bottom:
                      currentViewOverview === "bottom-left" ||
                      currentViewOverview === "bottom-right"
                        ? showModal
                          ? null
                          : "13px"
                        : null,
                    top:
                      currentViewOverview === "bottom-left" ||
                      currentViewOverview === "bottom-right"
                        ? showModal
                          ? "158px"
                          : null
                        : null
                  }}
                  id="myOverviewDiv"
                ></div>
              </Fragment>
            )}
          </Col>

          <input type="text" className="d-none" id="changedData"></input>

          {showModal ? (
            <Col
              md={12}
              id="propertiesPanel"
              className="mt-2 mx-1"
              style={{
                // display: "none",
                width: "98%",
                position: isFullscreen ? "fixed" : "inherit",
                left: isFullscreen ? "0" : "inherit",
                bottom: isFullscreen ? "0" : "inherit",
                zIndex: isFullscreen ? 3 : "inherit"
                // backgroundColor: "aliceblue",
                // border: "solid 1px black"
              }}
            >
              <Button.Ripple
                style={{
                  position: "absolute",
                  right: "10px",
                  zIndex: 1,
                  marginTop: "-15px"
                }}
                onClick={(e) => onCloseModal(false)}
                className="btn-icon btn-sm"
                color="flat-secondary"
              >
                <X size={16} />
              </Button.Ripple>

              {store.pageData[storeName]?.selectedData && (
                <Card className="card-on-treeview mb-50">
                  <CardBody>
                    <DynamicForm
                      dynamicForm={
                        store.pageAttributes[storeName]?.crudOpt?.data
                          ?.dynamicForm
                      }
                      pageAttr={store.pageAttributes[storeName]}
                      storeName={storeName}
                      defaultFormValues={JSON.parse(
                        JSON.stringify(store.pageData[storeName]?.selectedData)
                      )}
                      hideAllFotterButton={true}
                      editForm={true}
                      pageType={
                        !pageAttr?.crudOpt?.disabledEdit &&
                        ability.can(
                          "update",
                          sessionStorage.getItem("current_page_perm")
                        )
                          ? "edit"
                          : "view"
                      }
                      //   pageType={pageType}
                      // pageType={"view"}
                      handleFormChangeToParent={(data) =>
                        handleFormChangeFromChild({
                          dataFirst: data,
                          refDiagram: diagramRef
                        })
                      }
                    />
                  </CardBody>
                </Card>
              )}
            </Col>
          ) : null}
        </Row>
      </Card>
    </UILoader>
  )
}

export default OrgChart3
