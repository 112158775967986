// ** React Imports
import { useContext, useEffect, useState } from "react"
import {
  useLocation,
  useNavigate,
  useSearchParams,
  Link
} from "react-router-dom"
// ** Store & Actions
import {
  handleContentWidth,
  handleMenuCollapsed,
  handleMenuHidden,
  handleLastMenuRemember,
  handleWidgetVisiblity
} from "@store/layout"
import { useDispatch, useSelector } from "react-redux"
import { handleLogout } from "@store/authentication"

// ** Third Party Components
import classnames from "classnames"
import { ArrowUp, Info, X } from "react-feather"

// ** Reactstrap Imports
import { Button, Col, Navbar, Row } from "reactstrap"

// ** Configs
import themeConfig from "@configs/themeConfig"
import { isAdminApp, getUserData } from "@utils"
// ** Custom Components

import Customizer from "@components/customizer"
import ScrollToTop from "@components/scrolltop"
import FooterComponent from "./components/footer"
import SidebarComponent from "./components/menu/vertical-menu"
import NavbarComponent from "./components/navbar"

import FloatingChat from "@components/floating-chat"
// ** Custom Hooks
import { useFooterType } from "@hooks/useFooterType"
import { useLayout } from "@hooks/useLayout"
import { useNavbarColor } from "@hooks/useNavbarColor"
import { useNavbarType } from "@hooks/useNavbarType"
import { useRTL } from "@hooks/useRTL"
import { useSkin } from "@hooks/useSkin"
import { updateCurrentdata as updateCurrentdataDynamic } from "@src/shared/dynamic_pages/store"
import { badgeChatIncrement, setNewNotification } from "@store/notifications"
import { useTranslation } from "react-i18next"

import chatSound from "@src/assets/sound/facebook_chat_2016.mp3"
import useSound from "use-sound"

// ** Styles
import "@styles/base/core/menu/menu-types/vertical-menu.scss"
import "@styles/base/core/menu/menu-types/vertical-overlay-menu.scss"

// ** Context
import { ThemeColors } from "@src/utility/context/ThemeColors"

// ** Third Party Components
import toast from "react-hot-toast"

// ** React Hot Toast Styles
import "@styles/react/libs/react-hot-toasts/react-hot-toasts.scss"

// ** FCM
import Swal from "sweetalert2"
import {
  onMessageListener,
  requestForToken,
  requestNotificationPermission
} from "../../firebase"

import Avatar from "@components/avatar"
import defaultAvatar from "@src/assets/images/avatars/avatar-blank.png"
import { renderIcon } from "@src/views/apps/menu/store/my_menu"
import { chatDelivered, chatRead, newIncomingChat } from "@src/views/chat/store"
// import { useLastMenuRemember } from "@hooks/useLastMenuRemember"

const VerticalLayout = (props) => {
  const { t } = useTranslation()
  // ** Props
  const { menu, navbar, footer, children, menuData } = props

  // ** Hooks
  const [isRtl, setIsRtl] = useRTL()
  const { skin, setSkin } = useSkin()
  const { navbarType, setNavbarType } = useNavbarType()
  const { footerType, setFooterType } = useFooterType()
  const { navbarColor, setNavbarColor } = useNavbarColor()
  // const { lastMenuRememberState, setLastMenuRememberState } =
  //   useLastMenuRemember()
  const { layout, setLayout, setLastLayout } = useLayout()

  // ** States
  const [isMounted, setIsMounted] = useState(false)
  const [menuVisibility, setMenuVisibility] = useState(false)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  // ** Vars
  const layoutStore = useSelector((state) => state.layout)

  const [searchParams, setSearchParams] = useSearchParams()
  // ** Update Window Width
  const handleWindowWidth = () => {
    setWindowWidth(window.innerWidth)
  }

  const setLastLogout = () => {
    const currentTime = new Date().getTime()
    localStorage.setItem("lastLogout", currentTime)
    if (
      location.pathname !== "/login" ||
      location.pathname !== "/error" ||
      location.pathname !== "/forgot-password" ||
      location.pathname !== "/reset-password" ||
      location.pathname !== "/register" ||
      location.pathname !== "/logout" ||
      location.pathname !== "/dashboard"
    ) {
      localStorage.setItem("lastLogoutUrl", location.pathname)
      const last_current_page_perm = sessionStorage.getItem("current_page_perm")
      localStorage.setItem("lastLogoutSubject", last_current_page_perm)
    }
  }

  const userData = getUserData()

  // useEffect(() => {
  //   // console.log("Current pathname:", location.pathname)
  //   // console.log(`/dyn_pages/_admin_/profile/view/${userData?.id}`)
  // }, [location])

  // ** Vars
  const location = useLocation()
  const isHidden = layoutStore.menuHidden
  const contentWidth = layoutStore.contentWidth
  const menuCollapsed = layoutStore.menuCollapsed
  const widgetVisibility = layoutStore.widgetVisibility
  // ** Toggles Menu Collapsed
  const setMenuCollapsed = (val) => dispatch(handleMenuCollapsed(val))

  const setLastMenuRemember = (val) => dispatch(handleLastMenuRemember(val))

  const setWidgetVisibility = (val) => dispatch(handleWidgetVisiblity(val))

  // ** Handles Content Width
  const setContentWidth = (val) => dispatch(handleContentWidth(val))

  // ** Handles Content Width
  const setIsHidden = (val) => dispatch(handleMenuHidden(val))

  const dispatch = useDispatch()

  const navigate = useNavigate()
  //** This function will detect the Route Change and will hide the menu on menu item click
  useEffect(() => {
    if (menuVisibility && windowWidth < 1200) {
      setMenuVisibility(false)
    }
  }, [location])

  const toggleMenu = (e) => {
    e.preventDefault()
    setMenuVisibility(!menuVisibility)
  }

  //** Sets Window Size & Layout Props
  useEffect(() => {
    if (window !== undefined) {
      window.addEventListener("resize", handleWindowWidth)
    }
  }, [windowWidth])

  // const storeChat = useSelector((state) => state.chat)

  // //** Sets MEMBER-OF
  // const dispatch = useDispatch()
  // const storeUnit = useSelector((state) => state.units)
  // useEffect(() => {
  //   if (storeUnit.data?.length === 0 && !storeUnit.isLoading) {
  //     dispatch(getUnits({}))
  //   }
  // }, [])

  //** ComponentDidMount
  useEffect(() => {
    setIsMounted(true)
    return () => setIsMounted(false)
  }, [])

  // ** Vars
  const footerClasses = {
    static: "footer-static",
    sticky: "footer-fixed",
    hidden: "footer-hidden"
  }

  const navbarWrapperClasses = {
    floating: "navbar-floating",
    sticky: "navbar-sticky",
    static: "navbar-static",
    hidden: "navbar-hidden"
  }

  const navbarClasses = {
    floating:
      contentWidth === "boxed" ? "floating-nav container-xxl" : "floating-nav",
    sticky: "fixed-top",
    static: "navbar-static-top",
    hidden: "d-none"
  }

  const bgColorCondition =
    navbarColor !== "" && navbarColor !== "light" && navbarColor !== "white"

  // ** Theme Colors
  const { colors } = useContext(ThemeColors)

  // FCM NOTIFICATIONS
  const [notification, setNotification] = useState({
    title: "",
    body: "",
    color: "",
    image: null,
    avatar: null,
    clickUrl: null,
    duration: 6000
  })

  const toastClicked = (toastId, url) => {
    toast.dismiss(toastId)
    // dispatch(badgeChatIncrement({}))
    return navigate(url)
  }

  const notify = () => {
    let extraStyle = {}
    if (notification?.borderRadius) {
      extraStyle["borderRadius"] = notification?.borderRadius
    }

    if (notification?.background) {
      extraStyle["background"] = notification?.background
    }

    return toast(
      (t) => (
        <div className="w-100 d-flex align-items-center justify-content-between">
          <div
            style={{
              cursor: notification?.clickUrl ? "pointer" : "inherit"
            }}
            onClick={() => {
              if (notification?.sender) {
                // setTimeout(() => {
                //   searchParams.delete("refresh")
                //   searchParams.delete("current")
                //   searchParams.delete("fromSideBar")
                // }, 100)
                sessionStorage.setItem("current", notification?.sender)
                sessionStorage.setItem("fromSideBar", false)
                sessionStorage.setItem("refresh", true)
                sessionStorage.setItem("counterUpdate", true)
              }

              return notification?.clickUrl
                ? toastClicked(t.id, notification.clickUrl)
                : () => false
            }}
            className="d-flex align-items-center"
          >
            {!notification.image && !notification.avatar && (
              <Info size="25" className="me-2" />
            )}
            {!notification.image && notification.avatar && (
              <Avatar
                imgHeight="35"
                imgWidth="35"
                className="me-2"
                img={notification.avatar}
              />
            )}
            {notification.image && !notification.avatar && (
              <Avatar
                imgHeight="35"
                imgWidth="35"
                className="me-2"
                img={notification.image}
              />
            )}
            <div>
              <p className="mb-0">{notification?.title}</p>
              <small>{notification?.body}</small>
            </div>
          </div>
          <X
            style={{
              cursor: "pointer"
            }}
            size="14"
            onClick={() => toast.dismiss(t.id)}
          />
        </div>
      ),
      {
        duration: notification?.duration ?? 6000,
        style: {
          minWidth: "300px",
          padding: "16px",
          color: notification?.color ?? colors.primary.main,
          border: `1px solid ${notification?.color ?? colors.primary.main}`,
          ...extraStyle
        },
        iconTheme: {
          primary: notification?.color ?? colors.primary.main
        }
      }
    )
  }

  useEffect(() => {
    if (notification?.title) {
      notify()
    }
  }, [notification])

  // requestForToken()

  const [playSound] = useSound(chatSound)

  onMessageListener()
    .then(async (payload) => {
      // console.log("notification foreground")

      const type = payload?.data?.type
      // console.log(payload)

      if (type === "assets_uploading_success" || type === "assets_uploading") {
        setNotification({
          title: payload?.notification?.title,
          body: payload?.notification?.body,
          color: "#28C76F"
        })
        if (payload.data?.storeName) {
          dispatch(updateCurrentdataDynamic(payload.data))
        }
      } else if (type === "assets_uploading_failed") {
        setNotification({
          title: payload?.notification?.title,
          body: payload?.notification?.body,
          color: "#EA5455"
        })
      } else if (type === "chat_incoming") {
        playSound()
        await dispatch(newIncomingChat(payload?.data)).then((x) => {
          // console.log(x)
        })
        // dispatch(selectChat(payload.data?.receiver))
        // console.log(window.location.pathname)
        if (window.location.pathname !== "/chat") {
          // sessionStorage.setItem("current", payload?.data?.sender)
          // sessionStorage.setItem("fromSideBar", false)
          sessionStorage.setItem("refresh", true)
          setNotification({
            title: payload?.notification?.title,
            body: payload?.notification?.body,
            color: "#FFFFFF",
            avatar: payload?.data?.icon ?? defaultAvatar,
            duration: 20000,
            clickUrl: `/chat`,
            sender: payload?.data?.sender,
            borderRadius: "20px",
            background: "rgb(0 120 54)"
          })
          await dispatch(badgeChatIncrement({}))
        } else {
          sessionStorage.setItem("refresh", true)
          setNotification({
            title: payload?.notification?.title,
            body: payload?.notification?.body,
            color: "#FFFFFF",
            avatar: payload?.data?.icon ?? defaultAvatar,
            duration: 1000,
            clickUrl: `/chat`,
            sender: payload?.data?.sender,
            borderRadius: "20px",
            background: "rgb(0 120 54)"
          })
          // const fromSideBar = searchParams.get("fromSideBar")
          // let url = `/chat?current=${payload?.data?.sender}&refresh=true`
          // if (!fromSideBar) {
          //   url = `${url}&fromSideBar=true`
          // }
          // navigate(url)
          // let element = document.getElementById(
          //   `chat_list_${payload?.data?.sender}`
          // )
          // if (element) {
          //   setTimeout(() => {
          //     element.click()
          //   }, 200)
          // }
        }
      } else if (type === "chat_delivered") {
        await dispatch(chatDelivered(payload.data))
        if (window.location.pathname === "/chat") {
          // let url = `/chat?current=${payload?.data?.receiver}&refresh=true`
          // let url = `/chat`
          // navigate(url)
          sessionStorage.setItem("current", payload?.data?.receiver)
          sessionStorage.setItem("fromSideBar", false)
          sessionStorage.setItem("refresh", true)
          // let element = document.getElementById(
          //   `chat_list_${payload?.data?.receiver}`
          // )
          // if (element) {
          //   setTimeout(() => {
          //     element.click()
          //   }, 200)
          // }
        }
      } else if (type === "chat_read") {
        await dispatch(chatRead(payload.data))
        if (window.location.pathname === "/chat") {
          // let url = `/chat?current=${payload?.data?.receiver}&refresh=true`
          // navigate(url)
          // let url = `/chat?current=${payload?.data?.receiver}&refresh=true`
          // let url = `/chat`
          // navigate(url)

          sessionStorage.setItem("current", payload?.data?.receiver)
          sessionStorage.setItem("fromSideBar", false)
          sessionStorage.setItem("refresh", true)

          // let element = document.getElementById(
          //   `chat_list_${payload?.data?.receiver}`
          // )
          // if (element) {
          //   setTimeout(() => {
          //     element.click()
          //   }, 200)
          // }
        }
      } else {
        setNotification({
          title: payload?.notification?.title,
          body: payload?.notification?.body
        })

        dispatch(setNewNotification({ data: payload })).then((res) => {
          // console.log(res)
          if (payload?.data?.type === "session_exp") {
            setLastLogout()
            dispatch(handleLogout())
          }
        })
      }
    })
    .catch((err) => console.log("failed: ", err))

  // ** Prompt Notifications
  requestForToken()
  // Set interval dalam satuan menit
  const intervalInMinutes = 10

  // Cek waktu terakhir prompt notifikasi ditampilkan
  const lastPromptTime = localStorage.getItem("lastPromptTime")
  const currentTime = new Date().getTime()

  if (
    !lastPromptTime ||
    currentTime - lastPromptTime > intervalInMinutes * 60 * 1000
  ) {
    requestNotificationPermission()
      .then(() => {
        requestForToken()
        // Swal.fire({
        //   icon: "success",
        //   title: t("Notification permission granted"),
        //   text: t("You can now receive notifications from this website."),
        //   confirmButtonText: "OK",
        //   customClass: {
        //     confirmButton: "btn btn-primary"
        //   }
        // })
        // // User has granted permission for notifications
        // console.log("User has granted permission for notifications")
      })
      .catch(() => {
        Swal.fire({
          icon: "error",
          title: t("Notification permission denied"),
          text: t(
            "Please allow notifications for this website to receive updates on your account. To enable notifications on Chrome, click on the lock icon on the left side of the address bar, select 'Site settings', then set 'Notifications' to 'Allow'."
          ),
          confirmButtonText: "OK",
          customClass: {
            confirmButton: "btn btn-primary"
          }
        })
        // User has denied permission for notifications
        console.log("User has denied permission for notifications")
      })
    localStorage.setItem("lastPromptTime", currentTime)
  }

  if (!isMounted) {
    return null
  }

  return (
    <div
      className={classnames(
        `wrapper vertical-layout ${
          navbarWrapperClasses[navbarType] || "navbar-floating"
        } ${footerClasses[footerType] || "footer-static"}`,
        {
          // Modern Menu
          "vertical-menu-modern": windowWidth >= 1200,
          "menu-collapsed": menuCollapsed && windowWidth >= 1200,
          "menu-expanded": !menuCollapsed && windowWidth > 1200,

          // Overlay Menu
          "vertical-overlay-menu": windowWidth < 1200,
          "menu-hide": !menuVisibility && windowWidth < 1200,
          "menu-open": menuVisibility && windowWidth < 1200
        }
      )}
      {...(isHidden ? { "data-col": "1-column" } : {})}
    >
      {!isHidden ? (
        <SidebarComponent
          skin={skin}
          menu={menu}
          menuData={menuData}
          menuCollapsed={menuCollapsed}
          menuVisibility={menuVisibility}
          setMenuCollapsed={setMenuCollapsed}
          setMenuVisibility={setMenuVisibility}
        />
      ) : null}

      <Navbar
        expand="lg"
        container={false}
        light={skin !== "dark"}
        dark={skin === "dark" || bgColorCondition}
        style={
          navbarColor?.indexOf("#") !== -1
            ? {
                background: navbarColor
              }
            : {}
        }
        color={bgColorCondition ? navbarColor : undefined}
        className={classnames(
          `header-navbar navbar align-items-center ${
            navbarClasses[navbarType] || "floating-nav"
          } navbar-shadow ${
            location.pathname === "/chat" ? "d-none d-md-block" : ""
          }`
        )}
      >
        <div className="navbar-container d-flex content">
          {navbar ? (
            navbar({ skin, setSkin, setMenuVisibility })
          ) : (
            <NavbarComponent
              setMenuVisibility={setMenuVisibility}
              skin={skin}
              setSkin={setSkin}
            />
          )}
        </div>
      </Navbar>

      {children}

      {/* Vertical Nav Menu Overlay */}
      <div
        className={classnames("sidenav-overlay", {
          show: menuVisibility
        })}
        onClick={() => setMenuVisibility(false)}
      ></div>
      {/* Vertical Nav Menu Overlay */}

      {!isAdminApp() && <FloatingChat />}
      {themeConfig.layout.customizer === true && !isAdminApp() ? (
        <Customizer
          skin={skin}
          isRtl={isRtl}
          layout={layout}
          setSkin={setSkin}
          setIsRtl={setIsRtl}
          isHidden={isHidden}
          setLayout={setLayout}
          footerType={footerType}
          navbarType={navbarType}
          setIsHidden={setIsHidden}
          themeConfig={themeConfig}
          navbarColor={navbarColor}
          contentWidth={contentWidth}
          setFooterType={setFooterType}
          setNavbarType={setNavbarType}
          setLastLayout={setLastLayout}
          menuCollapsed={menuCollapsed}
          setNavbarColor={setNavbarColor}
          setContentWidth={setContentWidth}
          setMenuCollapsed={setMenuCollapsed}
          setLastMenuRemember={setLastMenuRemember}
          setWidgetVisibility={setWidgetVisibility}
          widgetVisibility={widgetVisibility}
        />
      ) : null}
      <footer
        className={classnames(
          `footer footer-light ${footerClasses[footerType] || "footer-static"}`,
          {
            "d-none": footerType === "hidden",
            "d-none d-md-block": location.pathname === "/chat"
          }
        )}
      >
        {footer ? (
          footer
        ) : (
          <FooterComponent
            footerType={footerType}
            footerClasses={footerClasses}
          />
        )}
      </footer>

      {themeConfig.layout.scrollTop === true ? (
        <div className="scroll-to-top">
          <ScrollToTop showOffset={300} className="scroll-top d-block">
            <Button className="btn-icon" color="primary">
              <ArrowUp size={14} />
            </Button>
          </ScrollToTop>
        </div>
      ) : null}

      <div
        className="d-md-none d-block"
        style={{
          minHeight: "50px"
        }}
      ></div>
      <Row className="d-md-none d-flex align-items-center bottom-nav-mobile w-100 px-0 py-0">
        <Col className="px-0 h-100" xs={3}>
          <Button.Ripple
            tag={Link}
            to="/dashboard"
            className={
              location.pathname === "/dashboard" ? "active h-100" : "h-100"
            }
            block
            color={
              location.pathname === "/dashboard"
                ? "flat-primary"
                : "flat-secondary"
            }
          >
            {location.pathname === "/dashboard"
              ? renderIcon("fontAwesome:house", "", 25)
              : renderIcon("fontAwesome:house", "", 25)}
          </Button.Ripple>
        </Col>
        <Col className="px-0 h-100" xs={3}>
          <Button.Ripple
            tag={Link}
            to={`/chat`}
            className={location.pathname === "/chat" ? "active h-100" : "h-100"}
            block
            color={
              location.pathname === "/chat" ? "flat-primary" : "flat-secondary"
            }
          >
            {location.pathname === `/chat`
              ? renderIcon("fontAwesome:comment", "", 25, { marginTop: "3px" })
              : renderIcon("fontAwesome:comment", "", 25, { marginTop: "3px" })}
          </Button.Ripple>
        </Col>
        <Col className="px-0 h-100" xs={3}>
          <Button.Ripple
            tag={Link}
            to={`/dyn_pages/_admin_/profile/view/${userData?.id}`}
            className={
              location.pathname ===
              `/dyn_pages/_admin_/profile/view/${userData?.id}`
                ? "active h-100"
                : "h-100"
            }
            block
            color={
              location.pathname ===
              `/dyn_pages/_admin_/profile/view/${userData?.id}`
                ? "flat-primary"
                : "flat-secondary"
            }
          >
            {location.pathname ===
            `/dyn_pages/_admin_/profile/view/${userData?.id}`
              ? renderIcon("fontAwesome:user", "", 25)
              : renderIcon("fontAwesome:user", "", 25)}
          </Button.Ripple>
        </Col>
        <Col className="px-0 h-100" xs={3}>
          <Button.Ripple
            tag={Link}
            to={`/`}
            onClick={(e) => toggleMenu(e)}
            className="h-100"
            block
            color="flat-secondary"
          >
            {renderIcon("material:menu", "", 30)}
          </Button.Ripple>
        </Col>
      </Row>
    </div>
  )
}

export default VerticalLayout
