// ** Configs
import themeConfig from "@configs/themeConfig"
import { useSelector } from "react-redux"
import { BUILD_DATE } from "@src/buildDate.js"
import { useTranslation } from "react-i18next"
const Footer = () => {
  const storeDomainClaims = useSelector((state) => state.domain_claims)
  const { t } = useTranslation()

  return (
    <div className="clearfix mb-0">
      <span className="float-md-start d-block d-md-inline-block">
        {t("COPYRIGHT")} © {new Date().getFullYear()}{" "}
        <a href="#" target="_blank" rel="noopener noreferrer">
          {storeDomainClaims.data.unit.name}
        </a>
        <span className="d-none d-sm-inline-block">
          , {t("All rights Reserved")}
        </span>
      </span>
      <span style={{ fontSize: 10 }} className="float-md-end mt-25">
        <b>Saba Framework </b>
        <span style={{ fontSize: 9 }}>
          {t("version")} {BUILD_DATE}
        </span>
        {/* {themeConfig.app.appCompany} */}
        {/* <Heart size={14} /> */}
      </span>
    </div>
  )
}

export default Footer
