/* eslint-disable multiline-ternary */
import UILoader from "@components/ui-loader"
import { Loader } from "@src/shared/CommonComponents"
import { ThemeColors } from "@src/utility/context/ThemeColors"
import "@styles/react/libs/flatpickr/flatpickr.scss"
import { getDepthValue, parseStrAndField, restrictedMenuLink } from "@utils"
import "moment/locale/id"
import { useContext, useState, useEffect } from "react"
import toast from "react-hot-toast"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { Alert, Modal, ModalBody, ModalHeader } from "reactstrap"
import {
  postData,
  postDataMulti,
  // dynamicForm
  putData,
  putDataTree,
  // storeName,
  // pageAttr?.pageTitle,
  resetData,
  resetSelectedData,
  getDataById,
  getDataList,
  addDataById,
  setDataById,
  putDataMulti,
  setSelectedData
} from "../store"

import DynamicForm from "@src/utility/generateForm/dynamicForm"
import DynamicFormLoading from "@src/utility/generateForm/dynamicFormLoading"

const ModalAddEdit = ({
  pageAttr,
  storeName,
  show,
  setShow,
  detailPage,
  pageType,
  setPageType,
  loading,
  detailPageModalSize,
  filtersCustomAdd,
  showDeleteButton = false,
  handleDelete,
  handleFormSubmitPrompt,
  isMultipleEdit,
  setSelectedRows,
  setToggleCleared
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const store = useSelector((state) => state.dynamicStore)
  const { colors } = useContext(ThemeColors)
  const [loadingPostPut, setLoadingPostPut] = useState(false)
  const [loadingText, setLoadingText] = useState("Please Wait")
  const [active, setActive] = useState("1")
  const toggleTab = (tab) => {
    if (active !== tab) {
      setActive(tab)
    }
  }
  const { moduleId, pageId, id, relPageId, relPageType, relId } = useParams()
  const [storeNameParent, setStoreNameParent] = useState(
    moduleId ? `${moduleId}_${pageId}` : `${pageId}`
  )

  const dynamicTempState = useSelector((state) => state.dynamic_temp_state)

  const getinitialFiltersCondition = () => {
    const initialFiltersCondition = pageAttr?.crudOpt?.initialFiltersCondition
    let getData = pageAttr?.isViewOnRow
      ? store.pageData[storeNameParent]?.currentExpandedData
      : store.pageData[storeNameParent]?.selectedData
    if (initialFiltersCondition) {
      let filters = {}
      const isFormRepeater = pageAttr?.crudOpt?.data?.dynamicForm?.find(
        (x) => x.type === "form-repeater"
      )
      for (let x in initialFiltersCondition) {
        const value_from_parentFieldName =
          initialFiltersCondition[x]?.value_from_parentFieldName
        const value =
          dynamicTempState.parent_data_relation[value_from_parentFieldName] ??
          getData?.[value_from_parentFieldName] ??
          initialFiltersCondition[x]?.value

        if (!isFormRepeater) {
          filters[initialFiltersCondition[x]?.fieldNameCurrent] = value
        } else {
          ;[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((fake) => {
            const targetFieldNameSuffix = `${initialFiltersCondition[x]?.fieldNameCurrent}__${fake}`
            filters[targetFieldNameSuffix] = value
          })
        }
      }
      let fixFilter = !pageAttr?.isViewOnRow
        ? filters
        : { ...getData, ...filters }
      // console.log(fixFilter)
      fixFilter = { ...fixFilter, ...filtersCustomAdd }
      return fixFilter
    }
  }

  const getDefaultValuesAdd = () => {
    if (
      pageType !== "edit" &&
      pageType !== "view"
      // &&
      // pageType !== "add-child"
    ) {
      let defValues = store.pageData[storeName]?.selectedData
        ? JSON.parse(
            JSON.stringify(store.pageData[storeName]?.selectedData)?.replace(
              /\:null/gi,
              ':""'
            )
          )
        : store.pageData[storeName]?.currentExpandedData
        ? JSON.parse(
            JSON.stringify(
              store.pageData[storeName]?.currentExpandedData
            )?.replace(/\:null/gi, ':""')
          )
        : {}

      // FILL FORM WITH LAST GRID PARAMS IF PAGETYPE === ADD
      let formFillWithFilter = {}
      if (store.pageData[storeName]?.params) {
        const lastParamsKeys = Object.keys(store.pageData[storeName]?.params)
          .length
          ? Object.keys(store.pageData[storeName]?.params)
          : null

        pageAttr?.crudOpt?.data?.dynamicForm?.map((item, index) => {
          // console.log(
          //   item?.fieldName,
          //   store.pageData[storeName]?.params[item?.fieldName]
          // )
          if (item?.children) {
            item?.children?.map((item_det, index_det) => {
              if (
                lastParamsKeys &&
                store.pageData[storeName]?.params[item_det?.fieldName]
              ) {
                formFillWithFilter[item_det?.fieldName] =
                  store.pageData[storeName]?.params[item_det?.fieldName]
              }
            })
          } else {
            if (
              lastParamsKeys &&
              store.pageData[storeName]?.params[item?.fieldName]
            ) {
              formFillWithFilter[item?.fieldName] =
                store.pageData[storeName]?.params[item?.fieldName]
            }
          }
        })
      }

      // console.log({
      //   ...defValues,
      //   ...formFillWithFilter,
      //   ...getinitialFiltersCondition()
      // })
      // setDefValuesAdd({ ...defValues, ...formFillWithFilter })
      return {
        ...defValues,
        ...formFillWithFilter,
        ...getinitialFiltersCondition()
      }
    } else {
      return {}
    }
  }

  const successToast = () => {
    const msg =
      pageType !== "add" ? t("Successfully Updated") : t("Successfully Created")
    return toast.success(`${msg} ${t(pageAttr?.pageTitle)}`, {
      style: {
        padding: "16px",
        color: colors.success.main,
        border: `1px solid ${colors.success.main}`
      },
      iconTheme: {
        primary: colors.success.main
      }
    })
  }

  const errorToast = (res) => {
    let errorFieldsMsg = ""
    if (res?.payload?.error?.errors) {
      const errorFields = res?.payload?.error?.errors
      for (let errorx in res?.payload?.error?.errors) {
        errorFieldsMsg = `${errorx} : ${errorFieldsMsg} - ${errorFields[errorx]}\n`
        // console.log(errorx, errorFields[errorx])
      }
    }
    return toast.error(
      `${res.payload?.error?.message} \n \n ${errorFieldsMsg}`,
      {
        style: {
          padding: "16px",
          color: colors.danger.main,
          border: `1px solid ${colors.danger.main}`
        },
        iconTheme: {
          primary: colors.danger.main
        }
      }
    )
  }

  const reloadOtherPage = (type) => {
    // console.log(type)
    if (type === "edit") {
      if (pageAttr?.crudOpt?.afterEditReloadOtherPage) {
        setTimeout(() => {
          pageAttr?.crudOpt?.afterEditReloadOtherPage?.map((url) => {
            const store_to_reset = url.replaceAll("/", "_")
            if (store.pageData[store_to_reset]?.data) {
              dispatch(resetData(store_to_reset))
              dispatch(getDataList(store?.pageData[store_to_reset]?.params))
            }
          })
        }, 1000)
      }

      if (pageAttr?.crudOpt?.afterEditReloadOtherDetailPage) {
        pageAttr?.crudOpt?.afterEditReloadOtherDetailPage?.map((url) => {
          const store_to_reset = url.replaceAll("/", "_")
          if (store.pageData[store_to_reset]?.selectedData) {
            const data_idField =
              store.pageAttributes[store_to_reset]?.crudOpt?.data_idField
            const current_id = data_idField
              ? store.pageData[store_to_reset].selectedData?.[data_idField]
              : store.pageData[store_to_reset].selectedData?.id
            dispatch(
              getDataById({
                id: current_id,
                storeName: store_to_reset,
                endpointName:
                  store.pageAttributes[store_to_reset]?.crudOpt?.endpointName
              })
            )
          }
        })
      }
    } else if (type === "add") {
      if (pageAttr?.crudOpt?.afterAddReloadOtherPage) {
        setTimeout(() => {
          pageAttr?.crudOpt?.afterAddReloadOtherPage?.map((url) => {
            const store_to_reset = url.replaceAll("/", "_")
            if (store.pageData[store_to_reset]?.data) {
              dispatch(resetData(store_to_reset))
              dispatch(getDataList(store?.pageData[store_to_reset]?.params))
            }
          })
        }, 1000)
      }

      if (pageAttr?.crudOpt?.afterAddReloadOtherDetailPage) {
        pageAttr?.crudOpt?.afterAddReloadOtherDetailPage?.map((url) => {
          const store_to_reset = url.replaceAll("/", "_")
          console.log("afterAddReloadOtherDetailPage", store_to_reset)
          const data_idField =
            store.pageAttributes[store_to_reset]?.crudOpt?.data_idField
          if (store.pageData[store_to_reset]?.selectedData) {
            const current_id = data_idField
              ? store.pageData[store_to_reset].selectedData?.[data_idField]
              : store.pageData[store_to_reset].selectedData?.id
            dispatch(
              getDataById({
                id: current_id,
                storeName: store_to_reset,
                endpointName:
                  store.pageAttributes[store_to_reset]?.crudOpt?.endpointName
              })
            )
          }
        })
      }
    }
  }

  const redirectOtherPage = (type, payload, defaultUrl) => {
    let result = false
    let resultCount = 0
    let operator = false

    let redirectPageCondition =
      type === "edit"
        ? pageAttr?.crudOpt?.afterEditRedirectPageCondition
        : pageAttr?.crudOpt?.afterAddRedirectPageCondition
    if (redirectPageCondition) {
      redirectPageCondition?.map((it) => {
        ////fungsi ini seharusnya sama dengan check condition di dynamicForm
        let fieldName = it?.fieldName
        let fieldValue = fieldName.includes(".")
          ? getDepthValue(payload, fieldName, null)
          : payload[fieldName]

        if (it?.operator && it?.value) {
          operator = true
          if (it?.valueType === "datetime" || it?.valueType === "date") {
            const newfieldValue = new Date(fieldValue)

            let newValue = null
            if (it?.value === "today") {
              newValue = new Date()
            } else {
              newValue = new Date(it?.value)
            }

            if (it?.operator === ">") {
              result = newfieldValue > newValue
            } else if (it?.operator === ">=") {
              result = newfieldValue >= newValue
            } else if (it?.operator === "<") {
              result = newfieldValue < newValue
            } else if (it?.operator === "<=") {
              result = newfieldValue <= newValue
            } else {
              result = newfieldValue === newValue
            }
          } else if (it?.valueType === "boolean") {
            if (it?.operator === "===") {
              result = fieldValue === it?.value
            } else if (it?.operator === "!==") {
              result = fieldValue !== it?.value
            }
          } else {
            result = eval(`"${fieldValue}"${it?.operator}"${it?.value}"`)
          }
        } else {
          resultCount++
          result =
            result +
            (fieldValue !== null &&
              fieldValue !== undefined &&
              fieldValue !== "undefined" &&
              fieldValue !== "")
        }
      })
    }

    let redirectPageAttr =
      type === "edit"
        ? pageAttr?.crudOpt?.afterEditRedirectPage
        : pageAttr?.crudOpt?.afterAddRedirectPage

    if (!result) {
      if (defaultUrl) {
        return navigate(defaultUrl)
      }
    } else {
      if (redirectPageAttr) {
        return navigate(`/dyn_pages/${redirectPageAttr}`)
      }
    }
  }

  const defaultPayload = {
    storeName: storeName,
    endpointName: pageAttr?.crudOpt?.endpointName,
    baseUrl: pageAttr?.crudOpt?.baseUrl,
    callback_page: window.location.href
  }

  const updateDataToApi = (data) => {
    const bulkRequestMode =
      store.pageAttributes[storeName]?.crudOpt?.bulkRequestMode

    data =
      isMultipleEdit && !bulkRequestMode
        ? { data: data, ...defaultPayload }
        : { ...data, ...defaultPayload }
    setLoadingText(
      `${t("Updating")} ${t(pageAttr?.pageTitle)} ${t("Detail")} Data`
    )
    setLoadingPostPut(!loadingPostPut)

    if (pageAttr?.crudOpt?.gridTheme === "treeview") {
      dispatch(putDataTree(data))
        .then((res) => {
          setLoadingPostPut(!loadingPostPut)
          if (res.meta.requestStatus === "fulfilled") {
            setShow(false)
            successToast()
            reloadOtherPage("edit")
            redirectOtherPage("edit", data)
          } else if (res.meta.requestStatus === "rejected") {
            errorToast(res)
          }
        })
        .catch((error) => {
          console.error(error)
          errorToast(error)
        })
    } else {
      if (pageAttr.crudOpt?.importButton && !pageAttr.crudOpt?.endpointName) {
        // console.log(data)
        delete data["baseUrl"]
        delete data["callback_page"]
        delete data["endpointName"]
        delete data["isTemporary"]

        dispatch(
          setDataById({
            rowData: data,
            storeName: storeName
          })
        )
          .then((res) => {
            // console.log(res)
            setLoadingPostPut(!loadingPostPut)
            if (res.meta.requestStatus === "fulfilled") {
              setShow(!show)
              successToast()
              reloadOtherPage("edit")
              redirectOtherPage("edit", data)
            } else if (res.meta.requestStatus === "rejected") {
              errorToast(res)
            }
          })
          .catch((error) => {
            console.error(error)
            errorToast(error)
          })
      } else {
        dispatch(
          isMultipleEdit && !bulkRequestMode
            ? putDataMulti(data)
            : putData(data)
        )
          .then((res) => {
            // console.log(res)
            setLoadingPostPut(!loadingPostPut)
            if (res.meta.requestStatus === "fulfilled") {
              setShow(!show)
              if (isMultipleEdit) {
                setSelectedRows([])
                setToggleCleared(false)
              }
              successToast()
              reloadOtherPage("edit")
              redirectOtherPage("edit", data)
            } else if (res.meta.requestStatus === "rejected") {
              errorToast(res)
            }
          })
          .catch((error) => {
            setLoadingPostPut(!loadingPostPut)
            if (isMultipleEdit) {
              setSelectedRows([])
              setToggleCleared(false)
            }
            console.error(error)
            errorToast(error)
          })
      }
    }
  }

  const postDataToApi = (data) => {
    data = { ...data, ...defaultPayload }
    data["isFromModal"] = true
    if (filtersCustomAdd) {
      data = { ...data, ...filtersCustomAdd }
    }
    data["isTemporary"] = pageAttr?.isFromAddPage
    if (!pageAttr?.isFromAddPage) {
      setLoadingText(`${t(`Creating a New ${pageAttr?.pageTitle}`)}`)
      setLoadingPostPut(true)
    }
    // console.log(pageAttr)
    if (pageAttr.crudOpt?.importButton && !pageAttr.crudOpt?.endpointName) {
      const currentTimestamp = Date.now()
      data["id"] = currentTimestamp
      // console.log(data)
      delete data["baseUrl"]
      delete data["callback_page"]
      delete data["endpointName"]
      delete data["isTemporary"]

      dispatch(
        addDataById({
          rowData: data,
          storeName: storeName
        })
      )
        .then((res) => {
          // console.log(res)
          setLoadingPostPut(false)
          if (res.meta.requestStatus === "fulfilled") {
            setShow(!show)
            if (!pageAttr?.isFromAddPage) {
              successToast()
              reloadOtherPage("add")
            }
          } else if (res.meta.requestStatus === "rejected") {
            errorToast(res)
          }
        })
        .catch((error) => {
          console.error(error)
          errorToast(error)
        })
    } else {
      dispatch(postData(data))
        .then((res) => {
          // console.log(res)
          setLoadingPostPut(false)
          if (res.meta.requestStatus === "fulfilled") {
            setShow(!show)
            if (!pageAttr?.isFromAddPage) {
              successToast()
              reloadOtherPage("add")
            }
          } else if (res.meta.requestStatus === "rejected") {
            errorToast(res)
          }
        })
        .catch((error) => {
          console.error(error)
          errorToast(error)
        })
    }
  }

  const postDataToApiMulti = (data) => {
    data = { ...data, ...defaultPayload }
    data["isFromModal"] = true
    if (filtersCustomAdd) {
      data = { ...data, ...filtersCustomAdd }
    }

    data["isTemporary"] = pageAttr?.isFromAddPage
    if (pageAttr?.isFromAddPage) {
      let columnsAttr = {}
      pageAttr?.crudOpt?.data?.dynamicColumns?.map((element) => {
        if (
          element?.type === "number" ||
          element?.editableInputOpt?.type === "number" ||
          element?.editableInputOpt?.valueType === "int"
        ) {
          columnsAttr[element?.fieldName] = 1
        } else if (
          element?.type === "currency" ||
          element?.editableInputOpt?.type === "currency"
        ) {
          columnsAttr[element?.fieldName] = 0.0
        } else {
          columnsAttr[element?.fieldName] = `temporary_${element?.fieldName}`
        }
      })

      let newData = []
      data?.repeater?.map((repeater) => {
        newData.push({ ...columnsAttr, ...repeater })
      })
      data.repeater = newData
    }
    // console.log(data)

    if (!pageAttr?.isFromAddPage) {
      setLoadingText(`${t(`Creating a New ${pageAttr?.pageTitle}`)}`)
      setLoadingPostPut(true)
    }
    // console.log(pageAttr)
    dispatch(postDataMulti(data))
      .then((res) => {
        setLoadingPostPut(false)
        if (res.meta.requestStatus === "fulfilled") {
          setShow(!show)
          if (!pageAttr?.isFromAddPage) {
            successToast()
            reloadOtherPage("add")
          }
        } else if (res.meta.requestStatus === "rejected") {
          errorToast(res)
        }
      })
      .catch((error) => {
        console.error(error)
        errorToast(error)
      })
  }

  function setRequiredFalse(data, crudOptions) {
    const updateSelectionOnly = crudOptions?.updateSelectionOnly
    const updateSelectionOthersHidden = crudOptions?.updateSelectionOthersHidden

    function updateRequired(item) {
      if (item.required !== undefined) {
        item.required = false
      }

      if (item.rules && item.rules.required !== undefined) {
        item.rules.required = false
      }

      if (
        updateSelectionOnly &&
        item?.fieldName &&
        !item?.fieldName?.includes(updateSelectionOnly)
      ) {
        if (!updateSelectionOthersHidden) {
          item.isDisabled = true
        } else {
          item.isHidden = true
        }
      }

      if (item.children && Array.isArray(item.children)) {
        item.children.forEach(updateRequired)
      }
    }

    data.forEach(updateRequired)

    return data
  }

  const handleFormSubmit = (data) => {
    // console.log(storeName, pageAttr)
    // console.log("modal : handleFormSubmit")
    // pageType === "edit" ? updateDataToApi(data) : postDataToApi(data)
    const data_idField = store.pageAttributes[storeName]?.crudOpt?.data_idField
    const allowNullPayload =
      store.pageAttributes[storeName]?.crudOpt?.allowNullPayload
    const allowEmptyStrPayload =
      store.pageAttributes[storeName]?.crudOpt?.allowEmptyStrPayload
    const bulkRequestMode =
      store.pageAttributes[storeName]?.crudOpt?.bulkRequestMode

    if (isMultipleEdit) {
      let dataMulti = []
      isMultipleEdit.map((item) => {
        // if (bulkRequestMode) {
        //   dispatch(
        //     setSelectedData({ rowData: item, storeName: storeName })
        //   ).then((res) => console.log(res))
        // }

        let cleanedObject = Object.fromEntries(
          Object.entries(data).filter(([_, v]) => v !== undefined)
        )

        if (allowNullPayload !== true || allowEmptyStrPayload === undefined) {
          cleanedObject = Object.fromEntries(
            Object.entries(cleanedObject).filter(([_, v]) => v !== null)
          )
        }

        if (
          allowEmptyStrPayload !== true ||
          allowEmptyStrPayload === undefined
        ) {
          cleanedObject = Object.fromEntries(
            Object.entries(cleanedObject).filter(([_, v]) => v !== "")
          )
        }

        let mixedData = {
          ...cleanedObject,
          ...(bulkRequestMode ? { dataSelected: item } : {}),
          [data_idField ?? "id"]: item[data_idField ?? "id"]
        }
        // console.log(mixedData)
        dataMulti.push(mixedData)
      })
      if (bulkRequestMode) {
        dataMulti?.map((item, i) => {
          updateDataToApi(item)
        })
      } else {
        // console.log(dataMulti)
        updateDataToApi(dataMulti)
      }
    } else {
      if (pageType === "edit" || pageType === "view") {
        updateDataToApi(data)
      } else {
        if (pageType === "prompt") {
          // console.log(pageType)
          setShow(false)
          return handleFormSubmitPrompt(data)
        } else {
          if (data?.repeater) {
            postDataToApiMulti(data)
          } else {
            postDataToApi(data)
          }
        }
      }
    }
  }

  function handleReset() {
    dispatch(resetSelectedData(storeName))
    // setPageType("add")
    setShow(false)
  }

  const getFieldTitle = () => {
    if (store.pageAttributes[storeName]?.crudOpt?.detailPageFieldTitle) {
      const detailPageFieldTitle = pageAttr?.crudOpt?.detailPageFieldTitle
      let result = ""
      if (Array.isArray(detailPageFieldTitle)) {
        let titleFixArr = []
        detailPageFieldTitle.map((it, i) => {
          let paramValue = parseStrAndField(
            store.pageData[storeName]?.selectedData,
            it
          )
          titleFixArr.push(paramValue)
          result = titleFixArr.join(
            pageAttr?.crudOpt?.detailPageFieldTitleSeparator ?? " "
          )
        })
      } else if (
        store.pageData[storeName]?.selectedData?.[detailPageFieldTitle]
      ) {
        result = store.pageData[storeName]?.selectedData?.[detailPageFieldTitle]
      }
      return result
    }
  }

  const getHeaderTitle = (value) => {
    let result = ""
    if (Array.isArray(value)) {
      let values = []
      value.map((it, i) => {
        let paramValue = parseStrAndField(
          store.pageData[storeName]?.selectedData,
          it
        )
        values.push(paramValue)
      })
      result = values.join(" ")
    } else if (store.pageData[storeName]?.selectedData?.[value]) {
      result = store.pageData[storeName]?.selectedData?.[value]
    }
    return result
  }

  useEffect(() => {
    document.body.style.paddingRight = "0"
    return () => {
      document.body.style.paddingRight = ""
    }
  }, [])

  return (
    <Modal
      isOpen={show}
      toggle={() => {
        handleReset()
        setShow(!show)
      }}
      className={`modal-dialog-centered modal-${detailPageModalSize ?? "lg"}`}
    >
      <UILoader
        blocking={loadingPostPut}
        overlayColor="rgba(115, 103, 240, .1)"
        loader={<Loader loadingText={loadingText} />}
      >
        <ModalHeader
          className="bg-transparent"
          toggle={() => {
            handleReset()
            setShow(!show)
          }}
        ></ModalHeader>
        <ModalBody className="px-sm-2 pt-50 pb-5">
          <div className="text-center mb-2">
            <h2 className="saba-detail-header mb-0">
              {pageAttr?.crudOpt?.detailPageFieldTitleHeader &&
              (pageType === "edit" || pageType === "view")
                ? getHeaderTitle(pageAttr?.crudOpt?.detailPageFieldTitleHeader)
                : pageType === "edit"
                ? pageAttr?.crudOpt?.detailPageTitleHeaderEdit ??
                  pageAttr?.crudOpt?.detailPageTitleHeader ??
                  `${t("Edit")} ${t(pageAttr.pageTitle)}`
                : pageType === "add-child"
                ? pageAttr?.crudOpt?.detailPageTitleHeaderAddChild ??
                  pageAttr?.crudOpt?.detailPageTitleHeaderAdd ??
                  pageAttr?.crudOpt?.detailPageTitleHeader ??
                  `${t("Add Child")} ${t(pageAttr.pageTitle)}`
                : pageType === "view"
                ? pageAttr?.crudOpt?.detailPageTitleHeaderView ??
                  pageAttr?.crudOpt?.detailPageTitleHeader ??
                  `${t("Detail")} ${t(pageAttr.pageTitle)}`
                : pageType === "prompt"
                ? `${t(pageAttr.pageTitle)}`
                : pageAttr?.crudOpt?.detailPageTitleHeaderAdd ??
                  pageAttr?.crudOpt?.detailPageTitleHeader ??
                  `${t("Add")} ${t(pageAttr.pageTitle)}`}
            </h2>
            {pageAttr?.crudOpt?.detailPageFieldSubHeader &&
              (pageType === "edit" || pageType === "view") && (
                <small className="saba-detail-sub-header">
                  {getHeaderTitle(pageAttr?.crudOpt?.detailPageFieldSubHeader)}
                </small>
              )}

            {pageAttr?.crudOpt?.detailPageStaticSubHeader &&
              pageType === "prompt" && (
                <small className="saba-detail-sub-header">
                  {pageAttr?.crudOpt?.detailPageStaticSubHeader}
                </small>
              )}

            {store.pageData[storeName]?.selectedData &&
              getFieldTitle() &&
              getFieldTitle() !== "" && (
                <p className="saba-detail-header-title font-small-3 fw-bolder mb-0">
                  {getFieldTitle()}
                </p>
              )}
          </div>
          {!loading ? (
            pageType === "edit" ? (
              store.pageData[storeName]?.selectedData ||
              store.pageData[storeName]?.currentExpandedData ? (
                <DynamicForm
                  dynamicForm={
                    store.pageAttributes[storeName]?.crudOpt?.data?.dynamicForm
                  }
                  pageAttr={store.pageAttributes[storeName]}
                  storeName={storeName}
                  handleReset={handleReset}
                  handleFormSubmit={handleFormSubmit}
                  defaultFormValues={JSON.parse(
                    JSON.stringify(
                      store.pageData[storeName]?.selectedData ??
                        store.pageData[storeName]?.currentExpandedData
                    ).replace(/\:null/gi, ':""')
                  )}
                  editForm={true}
                  showDeleteButton={showDeleteButton}
                  handleDelete={handleDelete}
                  pageType={pageType}
                />
              ) : (
                <Alert color="danger">
                  <h4 className="alert-heading">
                    {t(pageAttr?.pageTitle)} {t("not found")}
                  </h4>
                  <div className="alert-body">
                    {t(`${pageAttr?.pageTitle} with id`)}:{" "}
                    {store.pageData[storeName]?.selectedData?.id}{" "}
                    {t("doesn't exist.")}:{" "}
                    {/* <Link to="/apps/user/list">{t("Users List")}</Link> */}
                  </div>
                </Alert>
              )
            ) : pageType === "add-child" ? (
              <DynamicForm
                dynamicForm={
                  store.pageAttributes[storeName]?.crudOpt?.data?.dynamicForm
                }
                pageAttr={store.pageAttributes[storeName]}
                storeName={storeName}
                handleReset={handleReset}
                handleFormSubmit={handleFormSubmit}
                pageType={pageType}
                // defaultFormValues={JSON.parse(
                //   JSON.stringify(
                //     store.pageData[storeName]?.selectedData
                //   )?.replace(/\:null/gi, ':""')
                // )}
                defaultFormValues={getDefaultValuesAdd()}
                editForm={false}
              />
            ) : pageType === "view" ? (
              <DynamicForm
                dynamicForm={
                  store.pageAttributes[storeName]?.crudOpt?.data?.dynamicForm
                }
                pageAttr={store.pageAttributes[storeName]}
                storeName={storeName}
                pageType={pageType}
                handleReset={handleReset}
                handleFormSubmit={handleFormSubmit}
                editForm={false}
                defaultFormValues={JSON.parse(
                  JSON.stringify(
                    store.pageData[storeName]?.selectedData ??
                      store.pageData[storeName]?.currentExpandedData
                  ).replace(/\:null/gi, ':""')
                )}
              />
            ) : (
              <DynamicForm
                dynamicForm={
                  isMultipleEdit
                    ? setRequiredFalse(
                        JSON.parse(
                          JSON.stringify(
                            store.pageAttributes[storeName]?.crudOpt?.data
                              ?.dynamicForm ?? []
                          ).replace(/\:null/gi, ':""')
                        ),
                        store.pageAttributes[storeName]?.crudOpt
                      )
                    : store.pageAttributes[storeName]?.crudOpt?.data
                        ?.dynamicForm
                }
                pageAttr={store.pageAttributes[storeName]}
                storeName={storeName}
                pageType={pageType}
                handleReset={handleReset}
                handleFormSubmit={handleFormSubmit}
                editForm={isMultipleEdit ?? false}
                defaultFormValues={getDefaultValuesAdd()}
              />
            )
          ) : (
            <DynamicFormLoading
              dynamicForm={
                store.pageAttributes[storeName]?.crudOpt?.data?.dynamicForm
              }
              storeName={storeName}
            />
          )}
        </ModalBody>
      </UILoader>
    </Modal>
  )
}

export default ModalAddEdit
