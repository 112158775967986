import Avatar from "@components/avatar"
import { Card, CardHeader, CardTitle, CardText, Col } from "reactstrap"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { getDataList } from "@src/shared/dynamic_pages/store"
import { useEffect, useContext } from "react"
import illustration from "@src/assets/images/illustration/email.svg"

const LeaderBoard = ({ item, storeName, endpointName, colSizeFix }) => {
  const { t } = useTranslation()
  const store = useSelector((state) => state.dynamic_temp_state)
  const dispatch = useDispatch()

  const title = item?.title ?? item?.cardTitle
  const subtitle = item?.subtitle ?? item?.cardSubTitle
  const image = item?.image ?? item?.cardImage
  const hideRankNumber = item?.hideRankNumber
  const fieldNames = item?.dataFrom?.fieldNames

  // useEffect(() => {
  //   // console.log(store, storeName)
  //   // if (
  //   //   !store.pageData[storeName] ||
  //   //   (store.pageData[storeName]?.data?.length === 0 &&
  //   //     !store.pageData[storeName]?.isLoading)
  //   // ) {
  //   //   dispatch(
  //   //     getDataList({
  //   //       storeName: storeName,
  //   //       endpointName: endpointName
  //   //     })
  //   //   )
  //   // }
  // }, [])

  return (
    store?.data?.[storeName]?.length > 4 && (
      <Col
        key={`card_leaderboard_${storeName}`}
        id={`widget_${storeName}`}
        className="mb-2"
        style={{ ...item?.cardStyle }}
        {...colSizeFix}
      >
        <Card
          style={{ ...item?.cardStyle }}
          className="card-browser-states mb-0"
        >
          <div
            style={{
              backgroundColor: "rgba(115, 103, 240, .1)",
              ...item?.cardStyle
            }}
            className="meetup-img-wrapper rounded-top text-center"
          >
            <img
              style={{
                marginTop: "38px"
              }}
              src={!image ? illustration : image}
              height="170"
            />
          </div>
          <CardHeader
            style={{
              position: "absolute",
              width: "100%"
              // , padding: "10px"
            }}
            className="justify-content-around"
          >
            <div className="text-center">
              <CardTitle
                style={{ fontSize: "1.4rem", ...item?.cardTitleStyle }}
                tag="h3"
              >
                {item?.cardTitlePrefix} {t(title)} {item?.cardTitleSuffix}
              </CardTitle>
              <CardText
                style={{ fontSize: "0.8rem", ...item?.cardSubTitleStyle }}
                className=""
              >
                {t(subtitle)}
              </CardText>
            </div>
            {/* <UncontrolledDropdown className="chart-dropdown">
          <DropdownToggle
            color=""
            className="bg-transparent btn-sm border-0 p-50"
          >
            <MoreVertical size={18} className="cursor-pointer" />
          </DropdownToggle>
          <DropdownMenu end>
            <DropdownItem className="w-100">Last 28 Days</DropdownItem>
            <DropdownItem className="w-100">Last Month</DropdownItem>
            <DropdownItem className="w-100">Last Year</DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown> */}
          </CardHeader>
          <div className="leader-board-widget">
            {/* {renderStates()} */}

            <div style={item?.ribbonStyle ?? {}} class="ribbon"></div>
            <table>
              {store.data[storeName]?.map((row, index) => (
                <tr>
                  {!hideRankNumber && (
                    <td
                      style={{
                        paddingRight: "0.5rem",
                        ...item?.rankNumberStyle
                      }}
                      class="number"
                    >
                      {index + 1}
                    </td>
                  )}
                  <td
                    style={{
                      paddingLeft: !hideRankNumber ? "0.5rem" : "2rem"
                    }}
                    class="name"
                  >
                    <div className="d-flex">
                      {fieldNames?.avatar && (
                        <Avatar
                          className="me-50"
                          img={row?.[fieldNames?.avatar ?? "avatar"]}
                          imgHeight={37}
                          style={{ width: "37px" }}
                          contentStyles={{ width: "37px", height: "37px" }}
                          imgWidth={37}
                          content={row?.[fieldNames?.title ?? "title"]}
                          initials={!row[fieldNames?.avatar ?? "avatar"]}
                          randomColorLight={true}
                        />
                      )}
                      {fieldNames?.subtitle ? (
                        <div className="">
                          <h6
                            style={{
                              color: "inherit",
                              fontSize: "inherit",
                              fontWeight: "inherit"
                            }}
                            className="align-self-center mb-0"
                          >
                            {row?.[fieldNames?.title ?? "title"]}
                          </h6>
                          {fieldNames?.subtitle && (
                            <div className="font-small-1">
                              {row?.[fieldNames?.subtitle]}
                            </div>
                          )}
                        </div>
                      ) : (
                        <h6
                          style={{
                            color: "inherit",
                            fontSize: "inherit",
                            fontWeight: "inherit"
                          }}
                          className="align-self-center mb-0"
                        >
                          {row?.[fieldNames?.title ?? "title"]}
                        </h6>
                      )}
                    </div>
                  </td>
                  <td style={{ lineHeight: "normal" }} class="points">
                    {row?.[fieldNames?.count ?? "count"] ?? ""}

                    {index === 0 && !item?.hideFirstBadge && (
                      <img
                        class="gold-medal"
                        src="https://github.com/malunaridev/Challenges-iCodeThis/blob/master/4-leaderboard/assets/gold-medal.png?raw=true"
                        alt="gold medal"
                      />
                    )}
                  </td>
                </tr>
              ))}
            </table>
          </div>
        </Card>
      </Col>
    )
  )
}

export default LeaderBoard
