export const BUILD_DATE = "20240823-1557"
export const CHANGES_LOG = `- 2024-08-23 15:57:05 | commit
- 2024-08-23 15:28:22 | comit
- 2024-08-23 11:28:28 | perubahan master data item dan inventory
- 2024-08-22 14:25:53 | commit
- 2024-08-19 15:36:54 | re-add serverside fetch button on treeview
- 2024-08-19 11:43:04 | treeview fixed structured on view
- 2024-08-15 14:37:28 | ketinggalan
- 2024-08-15 14:37:13 | perubahan sikit, penyesuaian
- 2024-08-15 12:16:08 | add treeview minHeight & fixed form-repeater payload issue
- 2024-08-14 15:00:11 | BulkUpdate on table fix issues on regular required field
- 2024-08-13 16:03:03 | Merge branch 'mixed_all_branch' of https://github.com/amry666/insaba_web_react_starter into mixed_all_branch
- 2024-08-13 16:03:00 | perubahan PR
- 2024-08-13 13:47:16 | add new feature: BulkUpdate on table, chat input focus after send
- 2024-08-13 11:07:56 | Merge branch 'mixed_all_branch' of https://github.com/amry666/insaba_web_react_starter into mixed_all_branch
- 2024-08-13 11:07:53 | update ajuan lembur dan reimburse
- 2024-08-09 21:12:02 | orgchart: keep isAssistant layout on switch template, treeview: counter with badge & add optional property to hiding counter & fixed back button on view only node, table: add new feature updateSelection still on progress...
- 2024-08-08 14:27:15 | Commit 882024
- 2024-08-06 15:50:10 | add feature: treeview with default counter count_child / count_all_child
- 2024-08-06 14:16:33 | add feature: orgchart with default counter count_child / count_all_childs
- 2024-08-06 13:47:14 | Filter affected to summary params fixed`
